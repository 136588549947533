import { useState, useContext} from "react";
import { Button, IconButton } from "@material-ui/core";
import styles from "./memeOptions.module.css";

import {
  CommentIcon,
  SaveIcon,
  ShareIcon,
  LikeIcon,
  DislikeIcon,
  FilledSaveIcon,
  FilledLikeIcon,
  FilledDislikeIcon,
} from "../../SVG/Icons";
import { FixMeLater } from "../../models/Temp";
import { ThemeContext } from "../../store/theme-context.js";
import MediaQuery from "react-responsive";
import MemeUserInfoFollow from "../MemeUserInfoFollow";
import { UserEntity } from "../../models/Entities";

const MemeOptions: React.FC<{
  user: UserEntity;
  meme: FixMeLater;
  currentUser: UserEntity;
  location: FixMeLater;
  updateSaves: FixMeLater;
  updateSuscribers: FixMeLater;
  handleMessages: FixMeLater;
  isSaved: boolean;
  isLiked: boolean;
  isDisliked: boolean;
  isFollowed: boolean;
  handleSave: () => {};
  handleLike: () => {};
  handleDislike: () => {};
  handleFollow: () => void;
  commentCount: FixMeLater;
}> = ({
  user,
  meme,
  currentUser,
  location,
  updateSaves,
  handleMessages,
  updateSuscribers,
  isFollowed,
  isSaved,
  isLiked,
  isDisliked,
  handleSave,
  handleLike,
  handleDislike,
  handleFollow,
  commentCount,
}) => {
  const [likesCount, setLikeCount] = useState(meme.likes.length);
  const [dislikesCount, setDislikesCount] = useState(meme.dislikes.length);
  const { theme } = useContext(ThemeContext);


  const onClickDislike = () => {
    isDisliked
      ? setDislikesCount(dislikesCount - 1)
      : setDislikesCount(dislikesCount + 1);
    handleDislike();
  };

  const onClickLike = () => {
    isLiked ? setLikeCount(likesCount - 1) : setLikeCount(likesCount + 1);
    handleLike();
  };

  const handleShare = () => {
    var shareData = {
      title: "Rincón del Meme",
      text: meme.description,
      url: `${process.env.REACT_APP_FE_URL}meme/${meme.id}`,
    };
    try {
      navigator.share(shareData);
    } catch (error) {
      handleMessages("Error", error);
    }
  };

  const handleClickComment = async () => {
    if (!localStorage.getItem("token")) {
      handleMessages(
        "error",
        <p>
          Debe de iniciar sesión para poder poder comentar.
          <a href="/login">Iniciar sesión</a>
        </p>
      );
    }
  };
  return (
    <div className={styles.container}>
      {location === "meme" && (
        <div className={styles.options} id={meme.id}>
          <div className={styles.optionsLeft}>
            {location === "dashboard" && (
              <div className={styles.commentBtn}>
                <IconButton onClick={handleClickComment}>
                  <MediaQuery minWidth={700}>
                    <CommentIcon color="#ff3651" width={25} height={25} />{" "}
                  </MediaQuery>
                  <MediaQuery maxWidth={699}>
                    <CommentIcon color="#fff" width={25} height={25} />
                  </MediaQuery>
                </IconButton>
                <p>20</p>
              </div>
            )}
            <div className={styles.likesOptions}>
              <IconButton className={styles.likesBtns} onClick={onClickLike}>
                {isLiked ? (
                  <FilledLikeIcon
                    color={theme === "dark" ? "#000" : "#fff"}
                    color2={theme === "dark" ? "#fff" : "#ff3651"}
                    width={25}
                    height={25}
                  />
                ) : (
                  <LikeIcon color="white" width={25} height={25} />
                )}
              </IconButton>
              <p
                className={
                  isLiked && theme === "dark"
                    ? styles.likesTextDark
                    : styles.likesText
                }
              >
                {likesCount}
              </p>
            </div>
            <div className={styles.likesOptions}>
              <IconButton className={styles.likesBtns} onClick={onClickDislike}>
                {isDisliked ? (
                  <FilledDislikeIcon
                    color={theme === "dark" ? "#000" : "#fff"}
                    color2={theme === "dark" ? "#fff" : "#ff3651"}
                    width={25}
                    height={25}
                  />
                ) : (
                  <DislikeIcon color="white" width={25} height={25} />
                )}
              </IconButton>
              <p
                className={
                  isDisliked && theme === "dark"
                    ? styles.likesTextDark
                    : styles.likesText
                }
              >
                {dislikesCount}
              </p>
            </div>
            <MediaQuery minWidth={700}>
              <Button className={styles.rightBtns} onClick={handleShare}>
                <ShareIcon color="#ff3651" width={25} height={25} />
              </Button>
              <Button
                className={
                  isSaved ? styles.rightBtnsSelected : styles.rightBtns
                }
                onClick={handleSave}
              >
                {isSaved ? (
                  <FilledSaveIcon color="#ff3651" width={25} height={25} />
                ) : (
                  <SaveIcon color="#ff3651" width={25} height={25} />
                )}
              </Button>
            </MediaQuery>

            {user &&
              meme &&
              user.email !== meme.owner.email &&
              location === "meme" && (
                <div className={styles.follow}>
                  <MemeUserInfoFollow
                    isFollowed={isFollowed}
                    handleFollow={handleFollow}
                  />
                </div>
              )}
          </div>
          <div className={styles.optionsRight}>
            <MediaQuery maxWidth={699}>
              <Button
                className={
                  isSaved ? styles.rightBtnsSelected : styles.rightBtns
                }
                onClick={handleSave}
              >
                {isSaved ? (
                  <FilledSaveIcon color="#ff3651" width={25} height={25} />
                ) : (
                  <SaveIcon color="#ff3651" width={25} height={25} />
                )}
              </Button>
              <Button className={styles.rightBtns} onClick={handleShare}>
                <ShareIcon color="#ff3651" width={25} height={25} />
              </Button>
            </MediaQuery>
          </div>
        </div>
      )}
      {location === "dashboard" && (
        <div className={styles.optionsDashboard} id={meme.id}>
          <div className={styles.optionsLeft}>
            <div className={styles.commentBtn}>
              <IconButton onClick={handleClickComment}>
                <MediaQuery minWidth={700}>
                  <CommentIcon color="#ff3651" width={25} height={25} />{" "}
                </MediaQuery>
                <MediaQuery maxWidth={699}>
                  <CommentIcon color="#fff" width={25} height={25} />
                </MediaQuery>
              </IconButton>
              <p>{commentCount}</p>
            </div>
            <div className={styles.likesOptions}>
              <IconButton className={styles.likesBtns} onClick={onClickLike}>
                {isLiked ? (
                  <FilledLikeIcon
                    color={theme === "dark" ? "#000" : "#fff"}
                    color2={theme === "dark" ? "#fff" : "#ff3651"}
                    width={25}
                    height={25}
                  />
                ) : (
                  <LikeIcon color="white" width={25} height={25} />
                )}
              </IconButton>
              <p
                className={
                  isLiked && theme === "dark"
                    ? styles.likesTextDark
                    : styles.likesText
                }
              >
                {likesCount}
              </p>
              <IconButton className={styles.likesBtns} onClick={onClickDislike}>
                {isDisliked ? (
                  <FilledDislikeIcon
                    color={theme === "dark" ? "#000" : "#fff"}
                    color2={theme === "dark" ? "#fff" : "#ff3651"}
                    width={25}
                    height={25}
                  />
                ) : (
                  <DislikeIcon color="white" width={25} height={25} />
                )}
              </IconButton>
              <p
                className={
                  isDisliked && theme === "dark"
                    ? styles.likesTextDark
                    : styles.likesText
                }
              >
                {dislikesCount}
              </p>
            </div>
          </div>
          <div className={styles.optionsRight}>
            <Button
              className={isSaved ? styles.rightBtnsSelected : styles.rightBtns}
              onClick={handleSave}
            >
              {isSaved ? (
                <FilledSaveIcon color="#ff3651" width={25} height={25} />
              ) : (
                <SaveIcon color="#ff3651" width={25} height={25} />
              )}
            </Button>
            <Button className={styles.rightBtns} onClick={handleShare}>
              <ShareIcon color="#ff3651" width={25} height={25} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemeOptions;
