import styles from "./circularPicture.module.css";

const CircularPicture: React.FC<{ path: string }> = ({ path }) => {
  return (
    <img
      className={styles.profilePicture}
      src={process.env.REACT_APP_API_URL + path}
      alt="Profile"
    />
  );
};

export default CircularPicture;
