const FilledSaveIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M128.180 69.701 C 118.341 72.379,111.888 77.484,107.043 86.421 L 103.791 92.417 103.532 208.057 C 103.328 298.600,103.526 324.177,104.446 325.910 C 106.688 330.137,113.411 332.065,117.771 329.732 C 119.556 328.777,184.448 270.446,195.265 260.074 L 199.535 255.979 240.289 292.919 C 282.804 331.455,283.701 332.131,289.702 330.131 C 296.749 327.782,296.209 337.823,296.209 209.152 L 296.209 94.272 293.128 88.015 C 289.476 80.597,283.527 74.773,275.995 71.242 L 270.616 68.720 201.896 68.531 C 144.717 68.373,132.336 68.569,128.180 69.701 "
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default FilledSaveIcon;
