const GeekIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M99.32,50.19c0,27.34-22.1,49.44-49.5,49.35C23.05,99.46,0.69,78.08,0.63,50.23
	C0.57,22.48,23.08,0.51,50.55,0.83C77.9,1.14,99.4,23.43,99.32,50.19z M47.95,32.9c-3.33,0.2-6.51,0.33-9.69,0.6
	c-7.24,0.61-12.37,4.15-14.43,11.26c-1.23,4.27-2.03,8.68-2.74,13.07c-0.62,3.8-1.05,7.66-1.18,11.51
	c-0.15,4.39,2.43,7.49,6.38,8.16c2.99,0.51,5.51-0.71,7.66-2.62c2.38-2.12,4.57-4.45,6.89-6.63c0.38-0.36,1.01-0.65,1.52-0.65
	c5.19-0.05,10.37-0.05,15.56,0c0.52,0,1.15,0.29,1.53,0.65c1.36,1.29,2.61,2.69,3.95,4c1.88,1.84,3.75,3.72,6.26,4.72
	c4.65,1.86,9.46-0.58,10.46-5.38c0.25-1.2,0.4-2.47,0.28-3.68c-0.38-3.8-0.75-7.62-1.43-11.38c-0.77-4.26-1.62-8.54-2.87-12.67
	c-1.47-4.84-4.86-8.14-9.77-9.51c-2.36-0.65-4.86-0.84-7.31-1.08c-2.24-0.22-4.51-0.22-6.74-0.31c-0.53-3.64,0.66-6.18,3.71-7.95
	c1-0.58,2.08-1,3.09-1.57c1.97-1.11,4.01-2.14,5.85-3.44c1.74-1.23,2.72-3.1,3.26-5.16c0.31-1.19-0.27-2.23-1.35-2.58
	c-1-0.32-2.18,0.08-2.46,1.12c-0.87,3.18-3.32,4.6-6.03,5.85c-1.66,0.77-3.31,1.57-4.89,2.49c-2.96,1.73-4.75,4.33-5.27,7.73
	C48.01,30.58,48.02,31.74,47.95,32.9z"
      fill={color}
    />
    <path
      d="M47.95,32.9c0.07-1.16,0.06-2.32,0.24-3.45c0.53-3.4,2.31-6,5.27-7.73c1.58-0.92,3.23-1.73,4.89-2.49
	c2.71-1.25,5.16-2.67,6.03-5.85c0.28-1.03,1.46-1.44,2.46-1.12c1.08,0.35,1.66,1.39,1.35,2.58c-0.54,2.06-1.52,3.93-3.26,5.16
	c-1.84,1.3-3.88,2.33-5.85,3.44C58.08,24.01,57,24.43,56,25.01c-3.05,1.76-4.24,4.3-3.71,7.95c2.23,0.09,4.5,0.09,6.74,0.31
	c2.45,0.24,4.95,0.42,7.31,1.08c4.92,1.37,8.3,4.66,9.77,9.51c1.25,4.14,2.11,8.41,2.87,12.67c0.68,3.76,1.05,7.57,1.43,11.38
	c0.12,1.21-0.03,2.48-0.28,3.68c-1.01,4.8-5.81,7.25-10.46,5.38c-2.51-1.01-4.37-2.89-6.26-4.72c-1.34-1.31-2.59-2.71-3.95-4
	c-0.38-0.36-1.01-0.65-1.53-0.65c-5.19-0.05-10.37-0.05-15.56,0c-0.52,0-1.14,0.29-1.52,0.65c-2.32,2.19-4.51,4.51-6.89,6.63
	c-2.14,1.91-4.66,3.13-7.66,2.62c-3.95-0.67-6.53-3.77-6.38-8.16c0.13-3.85,0.57-7.71,1.18-11.51c0.71-4.39,1.51-8.8,2.74-13.07
	c2.05-7.11,7.18-10.65,14.43-11.26C41.44,33.23,44.62,33.1,47.95,32.9z M23.91,68c0.11,0.88,0.16,1.72,0.32,2.54
	c0.47,2.38,2.57,3.54,4.78,2.53c1.07-0.49,2.1-1.19,2.95-1.99c2.34-2.23,4.55-4.58,6.87-6.83c0.43-0.42,1.14-0.77,1.73-0.77
	c6.38-0.05,12.77-0.05,19.15,0c0.58,0,1.29,0.35,1.72,0.77c1.95,1.89,3.75,3.92,5.73,5.77c1.21,1.13,2.53,2.23,3.99,2.99
	c2.24,1.17,4.49-0.09,4.92-2.58c0.15-0.9,0.31-1.85,0.18-2.73c-0.99-6.53-1.77-13.12-3.18-19.56c-1.53-7.01-5.02-9.93-11.4-10.48
	c-7.68-0.66-15.37-0.64-23.06-0.01c-5.65,0.47-9.45,3.3-11.01,8.86C25.63,53.55,24.79,60.79,23.91,68z"
      fill={color2}
    />
    <path
      d="M23.91,68c0.88-7.21,1.72-14.45,3.69-21.5c1.56-5.56,5.35-8.39,11.01-8.86c7.68-0.64,15.38-0.65,23.06,0.01
	c6.38,0.55,9.87,3.47,11.4,10.48c1.41,6.44,2.19,13.03,3.18,19.56c0.13,0.88-0.02,1.84-0.18,2.73c-0.43,2.49-2.68,3.75-4.92,2.58
	c-1.46-0.76-2.78-1.86-3.99-2.99c-1.98-1.85-3.78-3.88-5.73-5.77c-0.43-0.42-1.14-0.76-1.72-0.77c-6.38-0.05-12.77-0.05-19.15,0
	c-0.58,0-1.29,0.35-1.73,0.77c-2.32,2.24-4.53,4.6-6.87,6.83c-0.85,0.81-1.88,1.51-2.95,1.99c-2.21,1.01-4.32-0.15-4.78-2.53
	C24.07,69.71,24.02,68.88,23.91,68z M68.49,50.97c0-4.11-3.34-7.43-7.46-7.41c-4.03,0.02-7.37,3.37-7.36,7.41
	c0,4.04,3.32,7.38,7.36,7.42C65.13,58.42,68.49,55.07,68.49,50.97z M40.24,48.88c0-1.21,0.01-2.24,0-3.27
	c-0.01-1.49-0.8-2.43-2.04-2.43c-1.24,0-2.06,0.93-2.08,2.41c-0.02,1.06,0,2.12,0,3.12c-0.24,0.08-0.32,0.13-0.39,0.13
	c-1,0.02-1.99,0.01-2.99,0.04c-1.44,0.04-2.39,0.94-2.33,2.16c0.05,1.2,0.94,1.94,2.38,1.96c1.09,0.01,2.19,0,3.33,0
	c0,1.3-0.02,2.37,0,3.45c0.03,1.41,0.84,2.28,2.07,2.28c1.23,0,2.03-0.87,2.05-2.28c0.02-1.1,0-2.21,0-3.44c1.22,0,2.28,0.01,3.35,0
	c1.49-0.02,2.41-0.83,2.39-2.07c-0.02-1.19-0.93-2.01-2.33-2.05C42.58,48.86,41.51,48.88,40.24,48.88z"
      fill={color}
    />
    <path
      d="M68.49,50.97c0,4.11-3.36,7.45-7.46,7.41c-4.04-0.03-7.36-3.38-7.36-7.42c0-4.04,3.33-7.39,7.36-7.41
	C65.15,43.54,68.49,46.86,68.49,50.97z M64.36,50.93c-0.02-1.77-1.54-3.26-3.31-3.24c-1.74,0.01-3.28,1.59-3.25,3.31
	c0.03,1.75,1.6,3.28,3.32,3.24C62.9,54.21,64.39,52.69,64.36,50.93z"
      fill={color2}
    />
    <path
      d="M40.24,48.88c1.27,0,2.34-0.02,3.41,0.01c1.4,0.04,2.31,0.86,2.33,2.05c0.02,1.24-0.9,2.05-2.39,2.07
	c-1.07,0.01-2.14,0-3.35,0c0,1.24,0.01,2.34,0,3.44c-0.02,1.41-0.82,2.28-2.05,2.28c-1.23,0-2.04-0.87-2.07-2.28
	c-0.02-1.07,0-2.15,0-3.45c-1.14,0-2.24,0.01-3.33,0c-1.44-0.02-2.33-0.76-2.38-1.96c-0.05-1.23,0.9-2.12,2.33-2.16
	c1-0.03,1.99-0.02,2.99-0.04c0.07,0,0.15-0.05,0.39-0.13c0-1-0.01-2.06,0-3.12c0.02-1.48,0.84-2.41,2.08-2.41
	c1.24,0,2.03,0.94,2.04,2.43C40.25,46.64,40.24,47.67,40.24,48.88z"
      fill={color2}
    />
    <path
      d="M64.36,50.93c0.02,1.76-1.46,3.28-3.23,3.32c-1.72,0.04-3.29-1.49-3.32-3.24c-0.03-1.72,1.51-3.3,3.25-3.31
	C62.82,47.68,64.34,49.16,64.36,50.93z"
      fill={color}
    />
  </svg>
);

export default GeekIcon;
