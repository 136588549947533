import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import MemeUserInfo from "../../components/MemeUserInfo";
import MemeOptions from "../../components/MemeOptions";
import MemeContent from "../../components/MemeContent";
import MemeComments from "../../components/MemeComments/MemeComments";
import styles from "./memes.module.css";
import SavesStyles from "../../styles/saves.module.css";
import MyMemesStyles from "../../styles/mymemes.module.css";
import MediaQuery from "react-responsive";

const Memes = ({
  user,
  meme,
  location,
  updateSuscribers,
  updateSaves,
  handleMessages,
  isLoading,
  CircularProgress,
  deleteMeme,
  updateFollowing,
}) => {
  const history = useHistory();
  const { meme_id } = useParams();
  const [currentMeme, setCurrentMeme] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const [commentCount, setCommentCount] = useState(0);

  useEffect(() => {
    if (user) {
      const option = location === "dashboard" ? meme : currentMeme;
      setIsSaved(
        user.saved_memes?.filter((item) => item.id === option?.id).length > 0
      );
      setIsLiked(
        user.liked_memes?.filter((item) => item.id === option?.id).length > 0
      );
      setIsDisliked(
        user.disliked_memes?.filter((item) => item.id === option?.id).length > 0
      );
      setIsFollowed(
        user.following?.filter(
          (follow) => follow.email === option?.owner?.email
        ).length > 0
      );
    }
  }, [user, meme, currentMeme, location]);

  useEffect(() => {
    async function getMemeData() {
      try {
        let response = await axios.get(`/api/meme`, {
          params: { id: meme_id },
        });
        if (response.data.error) {
          this.handleMessages("error", response.data.error);
        } else {
          if (!response.data.memes) {
            history.push("/");
          } else {
            setCurrentMeme(response.data.memes);
          }
        }
      } catch (error) {
        this.handleMessages("error", error);
      }
    }
    if (meme_id) getMemeData();
  }, [meme_id, history]);

  const handleFollow = async () => {
    if (!localStorage.getItem("token")) {
      handleMessages(
        "error",
        <p>
          Debe de iniciar sesión para poder seguir.
          <a href="/login">Iniciar sesión</a>
        </p>
      );
    } else {
      let data = {
        email: meme ? meme.owner.email : currentMeme.owner.email,
      };
      const config = {
        headers: {
          token: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };
      setIsFollowed(!isFollowed);
      try {
        updateFollowing(meme ? meme.owner : currentMeme.owner); // update state (user) on App.js so it can be shared between pages
        const response = await axios.post(
          `/api/people/subscribe`,
          data,
          config
        );
        if (response.data.success) {
          handleMessages("success", response.data.message);
        } else {
          handleMessages("error", response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSave = async () => {
    if (!localStorage.getItem("token")) {
      handleMessages(
        "error",
        <p>
          Debe de iniciar sesión para poder poder guardar.
          <a href="/login">Iniciar sesión</a>
        </p>
      );
    } else {
      let data = {
        meme_id: meme ? meme.id : currentMeme.id,
      };
      const config = {
        headers: {
          token: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };
      setIsSaved(!isSaved);
      try {
        updateSaves(meme ?? currentMeme);
        await axios.post(`/api/people/save`, data, config);
      } catch (error) {
        handleMessages("error", error);
      }
    }
  };
  const handleLike = async () => {
    if (!localStorage.getItem("token")) {
      handleMessages(
        "error",
        <p>
          Debe de iniciar sesión para poder dar 'me gusta'.
          <a href="/login">Iniciar sesión</a>
        </p>
      );
    } else {
      setIsLiked(!isLiked);
      try {
        const response = await axios.post(
          `api/meme/like`,
          {
            meme_id: meme ? meme.id : currentMeme.id,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.code !== 200) {
          handleMessages("error", response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleDislike = async () => {
    if (!localStorage.getItem("token")) {
      handleMessages(
        "error",
        <p>
          Debe de iniciar sesión para poder dar 'no me gusta'.
          <a href="/login">Iniciar sesión</a>
        </p>
      );
    } else {
      setIsDisliked(!isDisliked);
      try {
        const response = await axios.put(
          `api/meme/dislike/${meme ? meme.id : currentMeme.id}`,
          {
            dislike: !isDisliked,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.code !== 200) {
          handleMessages("error", response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <MediaQuery minWidth={700}>
        <Container>
          {location === "dashboard" && (
            <div className={styles.container}>
              <MemeUserInfo
                user={user}
                date={meme.created_at}
                location={location}
                categories={meme.categories}
                meme={meme}
                deleteMeme={deleteMeme}
                handleMessages={handleMessages}
                updateSuscribers={updateSuscribers}
                isSaved={isSaved}
                handleSave={handleSave}
                isFollowed={isFollowed}
                handleFollow={handleFollow}
              />
              <MemeContent meme={meme} history={history} location={location} />
              <MemeComments
                meme={meme}
                user={user}
                currentUser={user}
                location={location}
                updateSaves={updateSaves}
                handleMessages={handleMessages}
                updateSuscribers={updateSuscribers}
                isSaved={isSaved}
                isLiked={isLiked}
                isDisliked={isDisliked}
                handleSave={handleSave}
                handleLike={handleLike}
                handleDislike={handleDislike}
                isFollowed={isFollowed}
                handleFollow={handleFollow}
                commentCount={commentCount}
                setCommentCount={setCommentCount}
              />
            </div>
          )}
          {location === "userMemes" && (
            <Grid className={MyMemesStyles.container} item xs={12}>
              <MemeContent meme={meme} history={history} location={location} />
            </Grid>
          )}
          {location === "saves" && (
            <div className={SavesStyles.container}>
              <div className={SavesStyles.memeuserinfo}>
                <MemeUserInfo
                  user={user}
                  meme={meme}
                  isSaved={isSaved}
                  handleSave={handleSave}
                  isFollowed={isFollowed}
                  handleFollow={handleFollow}
                />
              </div>
              <MemeContent meme={meme} history={history} />
            </div>
          )}

          {location === "meme" && (
            <Grid className={styles.meme_container} item xs={12}>
              {currentMeme !== null && (
                <div className={styles.rightPartContainer}>
                  <div className={styles.contentCenter}>
                    <div>
                      <MemeUserInfo
                        user={user}
                        date={currentMeme.created_at}
                        location={location}
                        categories={currentMeme.categories}
                        handleMessages={handleMessages}
                        meme={currentMeme}
                        deleteMeme={deleteMeme}
                        isSaved={isSaved}
                        handleSave={handleSave}
                        isFollowed={isFollowed}
                        handleFollow={handleFollow}
                      />
                      <MemeOptions
                        meme={currentMeme}
                        user={user}
                        currentUser={user}
                        location={location}
                        updateSuscribers={updateSuscribers}
                        handleMessages={handleMessages}
                        updateSaves={updateSaves}
                        isSaved={isSaved}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        handleSave={handleSave}
                        handleLike={handleLike}
                        handleDislike={handleDislike}
                        isFollowed={isFollowed}
                        handleFollow={handleFollow}
                      />
                      <MemeContent
                        meme={currentMeme}
                        currentUser={user}
                        location={location}
                        updateSuscribers={updateSuscribers}
                        updateSaves={updateSaves}
                        handleMessages={handleMessages}
                        isSaved={isSaved}
                        handleSave={handleSave}
                      />
                      <MemeComments
                        meme={currentMeme}
                        location={location}
                        currentUser={user}
                        handleMessages={handleMessages}
                      />
                    </div>
                  </div>
                  <div className={styles.contentRight}>
                    {isLoading && (
                      <Grid className={"main-content flex-center"} item md={12}>
                        <CircularProgress />
                      </Grid>
                    )}
                    <h1 className={styles.advertisements}>Anuncios</h1>
                  </div>
                </div>
              )}
            </Grid>
          )}
        </Container>
      </MediaQuery>
      <MediaQuery maxWidth={699}>
        {location === "userMemes" && (
          <Grid className={MyMemesStyles.container} item xs={12}>
            <MemeContent meme={meme} history={history} />
          </Grid>
        )}
        {location === "dashboard" && (
          <div className={styles.mobileContainer}>
            <MemeUserInfo
              user={user}
              date={meme.created_at}
              categories={meme.categories}
              meme={meme}
              deleteMeme={deleteMeme}
              handleMessages={handleMessages}
              updateSuscribers={updateSuscribers}
              isSaved={isSaved}
              handleSave={handleSave}
              isFollowed={isFollowed}
              handleFollow={handleFollow}
            />
            <MemeContent meme={meme} />
            <MemeComments
                meme={meme}
                user={user}
                currentUser={user}
                location={location}
                updateSaves={updateSaves}
                handleMessages={handleMessages}
                updateSuscribers={updateSuscribers}
                isSaved={isSaved}
                isLiked={isLiked}
                isDisliked={isDisliked}
                handleSave={handleSave}
                handleLike={handleLike}
                handleDislike={handleDislike}
                isFollowed={isFollowed}
                handleFollow={handleFollow}
                commentCount={commentCount}
                setCommentCount={setCommentCount}
              />
          </div>
        )}
        {location === "meme" && (
          <div className={styles.mobileContainer}>
            {currentMeme !== null && (
              <div className={styles.mobileContainer}>
                <MemeUserInfo
                  user={user}
                  date={currentMeme.created_at}
                  categories={currentMeme.categories}
                  meme={currentMeme}
                  deleteMeme={deleteMeme}
                  location={location}
                  isSaved={isSaved}
                  handleSave={handleSave}
                  isFollowed={isFollowed}
                  handleFollow={handleFollow}
                />
                <MemeContent meme={currentMeme} />
                <MemeOptions
                  meme={currentMeme}
                  currentUser={user}
                  location={location}
                  updateSuscribers={updateSuscribers}
                  updateSaves={updateSaves}
                  handleMessages={handleMessages}
                  isSaved={isSaved}
                  isLiked={isLiked}
                  isDisliked={isDisliked}
                  handleSave={handleSave}
                  handleLike={handleLike}
                  handleDislike={handleDislike}
                  isFollowed={isFollowed}
                  handleFollow={handleFollow}
                />
                <MemeComments
                  meme={currentMeme}
                  location={location}
                  currentUser={user}
                  handleMessages={handleMessages}
                />
              </div>
            )}
          </div>
        )}
      </MediaQuery>
    </>
  );
};
export default Memes;
