const RandomIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M49.98,0.97c27.3,0,49.41,22.07,49.32,49.38c-0.09,28.42-23.6,49.92-50.55,49.28
	C22.88,99.02,0.21,77.78,0.59,49.55C0.97,21.86,23.79,0.7,49.98,0.97z M82.17,50.33c0-17.89-14.31-32.36-32.16-32.32
	C31.62,18.06,17.91,32.76,17.7,49.87c-0.22,17.5,13.64,32.64,32.2,32.66C69,82.54,82.38,66.86,82.17,50.33z"
      fill={color}
    />
    <path
      d="M82.17,50.33C82.38,66.86,69,82.54,49.9,82.53c-18.56-0.02-32.42-15.16-32.2-32.66
	C17.91,32.76,31.62,18.06,50,18.02C67.85,17.97,82.17,32.45,82.17,50.33z M63.53,41.72c1.36-0.06,1.96-0.6,2.06-1.75
	c0.11-1.39-0.79-2.71-2.28-3.3c-2.45-0.98-5.29,0.51-6.26,3.27c-1.21,3.48,1.2,7.65,4.99,8.61c4.2,1.07,8.93-1.6,10.49-5.92
	c1.5-4.16-0.19-9.99-3.85-13.27c-10.29-9.22-25.74-9.58-36.44-0.85c-2.15,1.75-4.01,3.76-5.68,6.31c1.06-0.58,1.83-1.07,2.66-1.45
	c6.81-3.19,14.51-0.19,16.68,6.56c1.23,3.81,0.67,7.37-2.13,10.35c-2.56,2.72-5.74,3.47-9.3,2.4c-2.79-0.83-4.53-2.74-5.28-5.53
	c-0.67-2.5,0.01-4.66,1.87-6.42c2.55-2.42,6.47-1.99,8.01,0.84c0.7,1.3,0.66,2.74-0.16,3.71c-0.63,0.74-1.41,1.28-2.41,0.9
	c-1-0.38-1.14-1.22-0.87-2.19c0.2-0.75-0.32-1.07-0.84-0.82c-0.65,0.32-1.39,0.83-1.68,1.45c-0.54,1.15-0.03,2.28,0.78,3.18
	c1.17,1.29,2.62,1.74,4.33,1.28c2.15-0.59,3.56-1.98,4.08-4.12c1.05-4.32-2.21-8.66-6.64-8.78c-5.33-0.15-9.19,2.65-12.36,6.6
	c-0.49,0.62-0.78,1.48-0.93,2.28c-0.61,3.23-0.64,6.48-0.11,9.75c2.82,17.53,21.45,28.26,37.88,21.57
	c10.74-4.37,16.64-12.61,17.83-24.14c0.34-3.25-0.05-6.49-1.11-9.91c-0.2,0.59-0.31,0.91-0.42,1.23c-0.92,2.68-2.52,4.87-4.78,6.58
	c-6.24,4.72-14.7,2.88-17.89-3.87c-2.78-5.91,0.26-12.38,6.41-13.63c4.71-0.96,9.23,2.31,9.38,6.78c0.12,3.46-2.6,6.44-5.77,6.34
	c-2.69-0.09-4.82-2.78-3.89-4.94c0.38-0.88,0.99-1.63,2.05-1.53c1.07,0.1,1.38,0.95,1.47,1.89C63.43,41.34,63.48,41.49,63.53,41.72z
	"
      fill={color2}
    />
    <path
      d="M63.53,41.72c-0.05-0.23-0.09-0.38-0.11-0.53c-0.09-0.94-0.4-1.79-1.47-1.89c-1.06-0.1-1.67,0.65-2.05,1.53
	c-0.93,2.16,1.2,4.85,3.89,4.94c3.17,0.11,5.88-2.88,5.77-6.34c-0.15-4.48-4.67-7.74-9.38-6.78c-6.15,1.25-9.19,7.72-6.41,13.63
	c3.18,6.75,11.64,8.58,17.89,3.87c2.26-1.7,3.85-3.9,4.78-6.58c0.11-0.32,0.22-0.64,0.42-1.23c1.06,3.42,1.45,6.65,1.11,9.91
	c-1.19,11.53-7.08,19.77-17.83,24.14c-16.43,6.69-35.06-4.04-37.88-21.57c-0.53-3.27-0.49-6.51,0.11-9.75
	c0.15-0.8,0.43-1.66,0.93-2.28c3.17-3.95,7.03-6.75,12.36-6.6c4.43,0.12,7.69,4.47,6.64,8.78c-0.52,2.13-1.93,3.53-4.08,4.12
	c-1.71,0.47-3.16,0.02-4.33-1.28c-0.81-0.9-1.32-2.02-0.78-3.18c0.29-0.62,1.02-1.13,1.68-1.45c0.52-0.25,1.04,0.07,0.84,0.82
	c-0.26,0.96-0.13,1.81,0.87,2.19c1,0.38,1.78-0.16,2.41-0.9c0.82-0.97,0.87-2.41,0.16-3.71c-1.54-2.83-5.45-3.26-8.01-0.84
	c-1.86,1.76-2.54,3.92-1.87,6.42c0.75,2.79,2.49,4.7,5.28,5.53c3.56,1.06,6.75,0.31,9.3-2.4c2.8-2.98,3.36-6.54,2.13-10.35
	c-2.18-6.75-9.87-9.75-16.68-6.56c-0.82,0.39-1.6,0.87-2.66,1.45c1.67-2.55,3.54-4.56,5.68-6.31c10.7-8.74,26.15-8.37,36.44,0.85
	c3.66,3.28,5.35,9.11,3.85,13.27c-1.56,4.32-6.29,6.99-10.49,5.92c-3.79-0.96-6.2-5.14-4.99-8.61c0.96-2.76,3.81-4.25,6.26-3.27
	c1.49,0.59,2.39,1.9,2.28,3.3C65.49,41.12,64.89,41.66,63.53,41.72z M57.47,67.57c3.35,0.05,6.04-1.22,8.46-3.17
	c0.95-0.77,1.07-1.98,0.39-2.88c-0.67-0.89-1.88-1.07-2.88-0.41c-0.4,0.26-0.77,0.57-1.16,0.84c-1.9,1.27-4,1.88-6.24,1.33
	c-1.61-0.4-3.15-1.07-4.7-1.69c-2.11-0.83-4.13-1.91-6.29-2.55c-4.32-1.29-7.9,0.22-10.86,3.42c-0.84,0.91-0.79,2.11,0.02,2.9
	c0.79,0.78,2.01,0.78,2.9-0.01c0.33-0.29,0.64-0.59,0.97-0.89c1.84-1.69,3.97-2.2,6.31-1.32c1.93,0.72,3.8,1.62,5.69,2.46
	C52.48,66.67,54.92,67.63,57.47,67.57z"
      fill={color}
    />
    <path
      d="M57.47,67.57c-2.55,0.06-4.99-0.9-7.4-1.97c-1.89-0.84-3.76-1.74-5.69-2.46c-2.33-0.87-4.47-0.36-6.31,1.32
	c-0.32,0.3-0.64,0.6-0.97,0.89c-0.9,0.79-2.11,0.79-2.9,0.01c-0.8-0.79-0.86-2-0.02-2.9c2.96-3.2,6.54-4.71,10.86-3.42
	c2.16,0.64,4.19,1.72,6.29,2.55c1.55,0.61,3.1,1.29,4.7,1.69c2.24,0.55,4.34-0.06,6.24-1.33c0.4-0.27,0.76-0.58,1.16-0.84
	c1-0.66,2.21-0.47,2.88,0.41c0.68,0.9,0.56,2.11-0.39,2.88C63.51,66.35,60.82,67.62,57.47,67.57z"
      fill={color2}
    />
  </svg>
);

export default RandomIcon;
