const CelebritiesIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M99.34,50.48c0,27.36-22.13,49.4-49.71,49.27c-26.82-0.13-49.03-21.68-49-49.39
	C0.65,22.38,23.24,0.94,50.29,0.99C77.32,1.04,99.34,23.23,99.34,50.48z M55.74,63.9c3.8,1.99,7.36,3.85,10.92,5.73
	c0.81,0.43,1.61,0.48,2.36-0.09c0.73-0.56,0.93-1.3,0.77-2.23c-1-5.77-1.96-11.55-2.97-17.32c-0.13-0.75,0.01-1.23,0.57-1.76
	c4.19-4.04,8.35-8.13,12.52-12.18c0.69-0.67,1.05-1.43,0.75-2.36c-0.3-0.93-1.02-1.32-1.99-1.46c-5.8-0.83-11.59-1.71-17.39-2.52
	c-0.75-0.1-1.08-0.43-1.39-1.06c-2.5-5.12-5.04-10.22-7.54-15.34c-0.46-0.95-1.01-1.78-2.16-1.8c-1.22-0.02-1.78,0.86-2.26,1.84
	c-2.5,5.12-5.03,10.23-7.52,15.35c-0.31,0.64-0.69,0.91-1.43,1.02c-5.76,0.8-11.51,1.68-17.27,2.51c-0.99,0.14-1.79,0.49-2.07,1.51
	c-0.28,0.99,0.15,1.74,0.87,2.44c4.11,3.96,8.17,7.96,12.28,11.92c0.61,0.59,0.85,1.14,0.69,1.98c-0.48,2.5-0.88,5.02-1.31,7.53
	c-0.55,3.22-1.16,6.43-1.6,9.67c-0.1,0.71,0.25,1.65,0.72,2.21c0.62,0.74,1.58,0.58,2.42,0.12c0.56-0.31,1.13-0.59,1.69-0.89
	c2.99-1.57,5.98-3.14,9.15-4.81c0.16,2.48,0.32,4.79,0.47,7.09c-4.86,0.63-8.17,4.28-7.85,8.46c0.37,4.8,3.73,7.82,8.69,7.83
	c3.07,0,6.15,0.04,9.22-0.01c4.09-0.07,7.5-3.08,7.93-6.91c0.49-4.37-1.91-7.96-6.05-9.06c-0.52-0.14-1.06-0.24-1.68-0.37
	C55.43,68.61,55.58,66.35,55.74,63.9z"
      fill={color}
    />
    <path
      d="M55.74,63.9c-0.16,2.45-0.31,4.71-0.46,7.02c0.62,0.14,1.16,0.23,1.68,0.37c4.15,1.09,6.54,4.68,6.05,9.06
	c-0.43,3.84-3.84,6.85-7.93,6.91c-3.07,0.05-6.14,0.01-9.22,0.01c-4.97-0.01-8.32-3.03-8.69-7.83c-0.32-4.17,2.99-7.83,7.85-8.46
	c-0.15-2.29-0.3-4.61-0.47-7.09c-3.17,1.66-6.16,3.24-9.15,4.81c-0.56,0.3-1.13,0.58-1.69,0.89c-0.84,0.46-1.8,0.62-2.42-0.12
	c-0.47-0.56-0.81-1.51-0.72-2.21c0.44-3.24,1.05-6.45,1.6-9.67c0.43-2.51,0.84-5.03,1.31-7.53c0.16-0.84-0.09-1.4-0.69-1.98
	c-4.11-3.95-8.18-7.96-12.28-11.92c-0.72-0.7-1.15-1.45-0.87-2.44c0.28-1.02,1.08-1.37,2.07-1.51c5.76-0.83,11.51-1.7,17.27-2.51
	c0.73-0.1,1.12-0.38,1.43-1.02c2.49-5.12,5.02-10.23,7.52-15.35c0.48-0.98,1.04-1.86,2.26-1.84c1.15,0.02,1.7,0.85,2.16,1.8
	c2.5,5.12,5.04,10.22,7.54,15.34c0.31,0.63,0.64,0.96,1.39,1.06c5.8,0.81,11.59,1.69,17.39,2.52c0.96,0.14,1.69,0.53,1.99,1.46
	c0.3,0.93-0.06,1.69-0.75,2.36c-4.18,4.06-8.33,8.14-12.52,12.18c-0.55,0.53-0.7,1.01-0.57,1.76c1.02,5.77,1.97,11.55,2.97,17.32
	c0.16,0.93-0.04,1.67-0.77,2.23c-0.75,0.57-1.54,0.51-2.36,0.09C63.1,67.76,59.54,65.89,55.74,63.9z M44.34,59.33
	c-2.25-1.22-2.58-1.39-2.71-2.58c-0.37-3.2-0.81-6.42-0.87-9.64c-0.06-3.24,1.89-5.4,5.11-5.72c2.68-0.27,5.43-0.34,8.11-0.08
	c3.88,0.37,5.69,2.6,5.59,6.52c-0.06,2.31-0.43,4.6-0.59,6.91c-0.14,2.04-0.37,3.97-3.32,4.42c3.34,1.76,6.27,3.29,9.26,4.87
	c0.02-0.26,0.04-0.34,0.02-0.42c-0.79-4.64-1.55-9.28-2.38-13.9c-0.26-1.45-0.08-2.58,1.08-3.65c2.72-2.52,5.33-5.16,7.99-7.75
	c0.82-0.8,1.62-1.61,2.57-2.54c-5.16-0.75-10.11-1.5-15.07-2.19c-1.01-0.14-1.7-0.57-2.14-1.48c-0.69-1.44-1.41-2.86-2.12-4.29
	c-1.55-3.13-3.09-6.25-4.7-9.51c-0.25,0.5-0.44,0.85-0.61,1.21c-2.05,4.14-4.12,8.28-6.15,12.43c-0.42,0.85-0.97,1.45-1.93,1.59
	c-2.29,0.33-4.57,0.65-6.86,0.98c-2.77,0.4-5.55,0.82-8.48,1.25c0.38,0.41,0.61,0.67,0.86,0.92c2.91,2.84,5.7,5.83,8.79,8.46
	c2.02,1.72,2.26,3.5,1.76,5.9c-0.89,4.24-1.51,8.53-2.28,13.05C38.39,62.45,41.32,60.91,44.34,59.33z M49.19,70.9
	c1.94,0.31,1.93,0.29,2.04-1.46c0.22-3.58,0.47-7.16,0.74-10.74c0.13-1.67,0.6-3.15,2.5-3.67c0.17-0.05,0.34-0.4,0.36-0.63
	c0.23-2.26,0.49-4.52,0.61-6.79c0.08-1.57-0.42-2.15-1.99-2.23c-2.15-0.11-4.31-0.12-6.46-0.02c-1.73,0.08-2.23,0.7-2.15,2.47
	c0.06,1.43,0.25,2.86,0.4,4.29c0.15,1.43-0.08,2.87,1.84,3.64c1.33,0.54,1.21,2.29,1.31,3.64C48.67,63.22,48.92,67.03,49.19,70.9z
	 M50.07,83.09c1.67,0,3.35,0.02,5.02-0.01c2.16-0.04,3.83-1.77,3.83-3.93c0-2.14-1.67-3.9-3.84-3.93c-3.27-0.04-6.54-0.03-9.81,0
	c-2.2,0.02-3.86,1.72-3.88,3.87c-0.02,2.22,1.64,3.94,3.89,3.98C46.88,83.11,48.47,83.09,50.07,83.09z"
      fill={color2}
    />
    <path
      d="M44.34,59.33c-3.01,1.58-5.94,3.13-9.07,4.77c0.77-4.52,1.39-8.81,2.28-13.05c0.5-2.4,0.26-4.17-1.76-5.9
	c-3.09-2.63-5.88-5.62-8.79-8.46c-0.25-0.24-0.48-0.51-0.86-0.92c2.93-0.43,5.71-0.84,8.48-1.25c2.29-0.33,4.57-0.65,6.86-0.98
	c0.96-0.14,1.51-0.74,1.93-1.59c2.03-4.15,4.1-8.29,6.15-12.43c0.18-0.35,0.36-0.71,0.61-1.21c1.61,3.25,3.15,6.38,4.7,9.51
	c0.71,1.43,1.43,2.85,2.12,4.29c0.44,0.91,1.13,1.34,2.14,1.48c4.96,0.69,9.91,1.43,15.07,2.19c-0.95,0.94-1.75,1.75-2.57,2.54
	c-2.65,2.59-5.26,5.24-7.99,7.75c-1.16,1.07-1.34,2.2-1.08,3.65c0.84,4.63,1.6,9.27,2.38,13.9c0.01,0.07-0.01,0.15-0.02,0.42
	c-3-1.58-5.92-3.12-9.26-4.87c2.95-0.45,3.18-2.38,3.32-4.42c0.16-2.31,0.53-4.6,0.59-6.91c0.1-3.93-1.71-6.15-5.59-6.52
	c-2.68-0.26-5.43-0.19-8.11,0.08c-3.22,0.32-5.17,2.48-5.11,5.72c0.06,3.22,0.5,6.43,0.87,9.64C41.76,57.94,42.08,58.11,44.34,59.33
	z M54.22,35.11c0.01-2.29-1.79-4.16-4.04-4.17c-2.23-0.01-4.12,1.86-4.16,4.1c-0.03,2.24,1.81,4.09,4.1,4.11
	C52.46,39.18,54.2,37.46,54.22,35.11z"
      fill={color}
    />
    <path
      d="M49.19,70.9c-0.27-3.86-0.53-7.68-0.81-11.49c-0.1-1.35,0.02-3.1-1.31-3.64c-1.92-0.77-1.69-2.21-1.84-3.64
	c-0.15-1.43-0.33-2.85-0.4-4.29c-0.07-1.77,0.43-2.4,2.15-2.47c2.15-0.1,4.31-0.09,6.46,0.02c1.57,0.08,2.07,0.65,1.99,2.23
	c-0.12,2.27-0.38,4.53-0.61,6.79c-0.02,0.23-0.19,0.59-0.36,0.63c-1.89,0.52-2.37,2-2.5,3.67c-0.28,3.58-0.52,7.16-0.74,10.74
	C51.12,71.19,51.13,71.21,49.19,70.9z"
      fill={color}
    />
    <path
      d="M50.07,83.09c-1.59,0-3.19,0.02-4.78-0.01c-2.25-0.04-3.91-1.76-3.89-3.98c0.02-2.16,1.68-3.85,3.88-3.87
	c3.27-0.03,6.54-0.04,9.81,0c2.16,0.02,3.84,1.79,3.84,3.93c0,2.16-1.67,3.89-3.83,3.93C53.42,83.12,51.74,83.09,50.07,83.09z"
      fill={color}
    />
    <path
      d="M54.22,35.11c-0.01,2.35-1.76,4.07-4.1,4.05c-2.29-0.02-4.13-1.87-4.1-4.11c0.03-2.24,1.93-4.11,4.16-4.1
	C52.42,30.95,54.23,32.81,54.22,35.11z"
      fill={color2}
    />
  </svg>
);

export default CelebritiesIcon;
