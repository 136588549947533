import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "../components/CopyRight";
import { updatePassword } from "../services/ResetPasswordService";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = ({ handleResetPassword, handleMessages, history }) => {
  const classes = useStyles();
  const params = useParams();
  const [password, setPassword] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const info = {
    new_password: password.newPassword,
    username: params.username,
    token: params.token,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let messageSeverity;
    if (password.newPassword === password.confirmNewPassword) {
      updatePassword(info)
        .then((data) => {
          data.ok ? (messageSeverity = "success") : (messageSeverity = "error");
          return data.json();
        })
        .then(({ message }) => {
          handleMessages(messageSeverity, message);
          if (messageSeverity === "success") history.push("/login");
        });
    } else {
      handleMessages("error", "Las contraseñas nuevas no coinciden");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Cambiar Contraseña
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                margin="normal"
                fullWidth
                id="newPassword"
                label="Ingrese nueva contraseña"
                name="newPassword"
                onChange={handleChange}
                type="password"
              />
              <TextField
                variant="outlined"
                required
                margin="normal"
                fullWidth
                id="confirmNewPassword"
                label="Confirme la nueva contraseña"
                name="confirmNewPassword"
                onChange={handleChange}
                type="password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={`${classes.submit} btn-primary`}
          >
            Aceptar
          </Button>
          <Link href="/login" variant="body2" className={classes.bigLink}>
            Intentar ingresar con tu contraseña actual
          </Link>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default ResetPassword;
