const HomeIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M107.813 98.396 C 8.167 171.034,4.078 175.788,39.797 177.492 L 59.282 178.421 60.110 271.242 L 60.938 364.063 200.000 364.063 L 339.063 364.063 339.890 271.242 L 340.718 178.421 360.203 177.492 C 396.450 175.763,393.133 171.905,292.684 98.934 C 243.805 63.427,201.900 34.428,199.563 34.493 C 197.225 34.558,155.938 63.315,107.813 98.396 M271.361 107.813 L 340.064 157.813 270.032 158.652 C 231.514 159.114,168.501 159.114,130.003 158.652 L 60.005 157.813 129.221 107.191 C 167.290 79.350,199.387 56.850,200.548 57.191 C 201.709 57.533,233.575 80.313,271.361 107.813 M325.000 262.500 L 325.000 346.875 292.398 346.875 L 259.796 346.875 258.804 295.851 C 257.453 226.307,245.100 207.813,200.001 207.813 C 154.111 207.813,140.625 228.621,140.625 299.424 L 140.625 346.875 107.813 346.875 L 75.000 346.875 75.000 262.500 L 75.000 178.125 200.000 178.125 L 325.000 178.125 325.000 262.500 M218.557 230.710 C 237.281 240.752,240.625 251.600,240.625 302.295 L 240.625 346.875 200.000 346.875 L 159.375 346.875 159.375 302.295 C 159.375 244.664,169.710 225.000,200.000 225.000 C 204.351 225.000,212.701 227.570,218.557 230.710 "
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default HomeIcon;
