const FollowIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M219.995 33.002c-17.935 3.41-35.138 12.653-47.051 25.283-6.191 6.562-6.554 6.787-10.965 6.794-25.085.04-52.801 15.091-67.414 36.609-26.934 39.662-17.722 90.601 21.713 120.067l2.861 2.137-2.312.647c-21.687 6.072-51.859 30.533-67.513 54.736-16.564 25.608-27.191 72.44-18.431 81.223l2.653 2.66 130.15.249c143.634.275 134.072.673 137.373-5.709 1.545-2.988 1.424-14.922-.248-24.444l-.546-3.11h65.477l3.397-3.398 3.397-3.397-.591-10.239c-3.15-54.548-37.325-102.262-86.699-121.044l-4.228-1.608 6.142-4.663c70.634-53.634 19.78-169.321-67.165-152.793m29.766 21.111c50.856 11.885 67.409 77.043 28.424 111.885-8.299 7.417-25.604 15.82-32.578 15.82-.29 0 .272-2.324 1.249-5.164 11.546-33.559-2.114-74.389-32.064-95.841-5.834-4.179-18.963-10.957-21.224-10.957-7.198 0 14.305-12.819 26.448-15.768 7.249-1.76 22.159-1.747 29.745.025m-68.9 33.574c45.683 11.518 64.305 68.225 34.268 104.355-38.908 46.8-114.942 19.464-114.942-41.324 0-42.207 39.832-73.328 80.674-63.031m82.367 119.344c46.345 11.85 81.485 51.255 87.4 98.007l.638 5.039-27.922-.254-27.923-.254-3.681-9.032c-13.267-32.551-39.333-58.98-72.31-73.316-8.455-3.675-8.375-3.211-1.502-8.69 3.034-2.419 7.885-6.861 10.78-9.869l5.263-5.471 10.048.749c5.526.412 14.171 1.803 19.209 3.091m-85.716 30.323c50.596 5.728 93.453 45.383 101.834 94.225.632 3.684 1.347 7.667 1.59 8.852l.44 2.153H48.781l.582-4.067c9.067-63.409 65.829-108.218 128.149-101.163"
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default FollowIcon;
