import React, { useState, useContext } from "react";
import { MenuItem, Menu, IconButton, Button } from "@material-ui/core";
import CircularPicure from "../CircularPicture";
import styles from "./memeUserInfo.module.css";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReportDialog from "../ReportDialog";
import { FixMeLater } from "../../models/Temp";
import { UserEntity } from "../../models/Entities";
import { SaveIcon, FollowIcon, DeleteIcon, ReportIcon } from "../../SVG/Icons";
import { ThemeContext } from "../../store/theme-context.js";
import MediaQuery from "react-responsive";
import MemeUserInfoFollow from "../MemeUserInfoFollow";

const MemeUserInfo: React.FC<{
  user: UserEntity;
  date: string;
  location: FixMeLater;
  categories: FixMeLater;
  meme: FixMeLater;
  deleteMeme: FixMeLater;
  handleMessages: FixMeLater;
  updateSuscribers: FixMeLater;
  isSaved: boolean;
  handleSave: () => void;
  isFollowed: boolean;
  handleFollow: () => void;
}> = ({
  user,
  date,
  location,
  categories,
  meme,
  deleteMeme,
  handleMessages,
  updateSuscribers,
  isSaved,
  handleSave,
  isFollowed,
  handleFollow,
}) => {
  const options =
    user && meme /*&& user.email === meme.owner.email*/
      ? ["Eliminar"]
      : ["Seguir", "Reportar", "Guardar"];
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null); // element where menu is displayed
  const open = Boolean(anchorEl);
  const { theme } = useContext(ThemeContext);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectedOption: FixMeLater) => {
    executeOption(selectedOption);
    setAnchorEl(null);
  };

  const getDate = (): string => {
    let result = null;
    const now = new Date();
    const created = Date.parse(date);
    result = getDifference(created, now);
    return String(result);
  };

  const getDifference = (date1: FixMeLater, date2: FixMeLater) => {
    let time = "segundos";
    let diff = Math.abs(date1 - date2) / 1000;
    if (isGreaterThan(diff, 59)) {
      time = "minutos";
      diff = diff / 60;
      if (isGreaterThan(diff, 59)) {
        time = "horas";
        diff = diff / 60;
        if (isGreaterThan(diff, 23)) {
          diff = diff / 24;
          time = "días";
        }
      }
    }
    return Math.round(diff).toString() + " " + time;
  };

  const isGreaterThan = (value: number, limit: number) => {
    return value > limit;
  };

  const executeOption = (option: string) => {
    if (option === "Eliminar") {
      deleteMeme(meme.id);
    }
    if (option === "Reportar") {
      handleOpenDialog();
    }
    if (option === "Seguir") {
      handleFollow();
    }
    if (option === "Guardar") {
      handleSave();
    }
  };

  const getOptionText = (option: string): string => {
    switch (option) {
      case "Seguir":
        return isFollowed ? "Seguido" : "Seguir";
      case "Guardar":
        return isSaved ? "Guardado" : "Guardar";
      default:
        return option;
    }
  };

  const icons = [
    <DeleteIcon color={"#ff3651"} width={25} height={25} />,
    <FollowIcon
      color={isFollowed && theme === "dark" ? "#fff" : "#ff3651"}
      width={25}
      height={25}
    />,
    <ReportIcon color={"#ff3651"} width={25} height={25} />,
    <SaveIcon
      color={isSaved && theme === "dark" ? "#fff" : "#ff3651"}
      width={25}
      height={25}
    />,
  ];

  return (
    <div className={styles.infoContainer}>
      {meme.owner && (
        <div className={styles.info}>
          {location === "dashboard" && (
            <div className={styles.memeHeaderDashboard}>
              <div className={styles.profilePicture}>
                <CircularPicure path={meme.owner.picture} />
              </div>
              <div className={styles.memeInfo}>
                {date && (
                  <p>
                    <span className={styles.username}>
                      {meme.owner.username}{" "}
                    </span>
                    <span className={styles.category}>
                      ·{" "}
                      {categories.map(
                        (categoria: FixMeLater) => categoria.name
                      )}{" "}
                      ·{" "}
                    </span>
                    <span className={styles.date}> Hace {getDate()}</span>
                  </p>
                )}
                {!date && (
                  <span className={styles.username}>{meme.owner.username}</span>
                )}
              </div>
            </div>
          )}
          <div className={styles.memeHeader}>
            <div className={styles.profilePicture}>
              <CircularPicure path={meme.owner.picture} />
            </div>
            <div className={styles.memeInfo}>
              {date && (
                <p>
                  <span className={styles.username}>
                    {meme.owner.username}{" "}
                  </span>
                  <span className={styles.category}>
                    ·{" "}
                    {categories.map((categoria: FixMeLater) => categoria.name)}{" "}
                    ·{" "}
                  </span>
                  <span className={styles.date}> Hace {getDate()}</span>
                </p>
              )}
              {!date && (
                <span className={styles.username}>{meme.owner.username}</span>
              )}
            </div>
          </div>

          <div className={styles.TopOptions}>
            <MediaQuery minWidth={700}>
              {user &&
                meme &&
                user.email !== meme.owner.email &&
                location === "dashboard" && (
                  <div className={styles.follow}>
                    <MemeUserInfoFollow
                      isFollowed={isFollowed}
                      handleFollow={handleFollow}
                    />
                  </div>
                )}
            </MediaQuery>
            <IconButton
              aria-label="Options"
              aria-controls="long-menu"
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon fontSize="large" htmlColor="#808080" />
            </IconButton>
            <Menu
              className={theme === "dark" ? styles.menuDark : styles.menu}
              anchorEl={anchorEl} // needed for menu to appear
              open={open}
              onClose={handleClose}
              PaperProps={{ className: styles.paper }}
            >
              {options.map((option, index) => (
                <MenuItem
                  divider={true}
                  key={option}
                  onClick={() => handleClose(option)}
                >
                  <Button
                    startIcon={
                      user?.email !== meme?.owner.email
                        ? icons[index + 1]
                        : icons[index]
                    }
                    className={
                      (isFollowed && option === "Seguir") ||
                      (isSaved && option === "Guardar")
                        ? theme === "dark"
                          ? styles.menuBtnDarkSelected
                          : styles.menuBtnSelected
                        : theme === "dark"
                        ? styles.menuBtnDark
                        : styles.menuBtn
                    }
                  >
                    {getOptionText(option)}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      )}
      <ReportDialog
        onClose={() => setOpenDialog(false)}
        openDialog={openDialog}
        user={user}
        report={meme}
        handleMessages={handleMessages}
      />
    </div>
  );
};

export default MemeUserInfo;
