import { IconButton } from "@material-ui/core";
import styles from "./memeMessageOptions.module.css";
import {CommentIcon} from "../../SVG/Icons";
import { FixMeLater } from "../../models/Temp";
import MediaQuery from "react-responsive";

const MemeMessageOptions: React.FC<{
  meme: FixMeLater;
  location: FixMeLater;
  handleMessages: FixMeLater;
  commentCount: FixMeLater;
}> = ({
  meme,
  location,
  handleMessages,
  commentCount,
}) => {
  const handleClickComment = async () => {
    if (!localStorage.getItem("token")) {
      handleMessages(
        "error",
        <p>
          Debe de iniciar sesión para poder poder comentar.
          <a href="/login">Iniciar sesión</a>
        </p>
      );
    }
  };
  return (
    <div className={styles.container}>
      {(location === "dashboard" || location === "meme") && (
        <div className={styles.options} id={meme.id}>
          <div className={styles.optionsLeft}>
            <div className={styles.commentBtn}>
              <IconButton onClick={handleClickComment}>
                <MediaQuery minWidth={700}>
                  <CommentIcon color="#ff3651" width={25} height={25} />{" "}
                </MediaQuery>
                <MediaQuery maxWidth={699}>
                  <CommentIcon color="#ff3651" width={25} height={25} />
                </MediaQuery>
              </IconButton>
              <p>{commentCount} Comentarios</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default MemeMessageOptions;
