export const resetPassword = (info) => {
  const url = "api/resetpassword";
  const method = "POST";
  return fetchUrl({ url, method, data: info });
};

export const updatePassword = (info) => {
  const url = "api/people/updatePassword";
  const method = "PUT";
  const headers = info.token
    ? {
        token: info.token,
        "Content-Type": "application/json",
      }
    : null;
  return fetchUrl({ url, method, data: info, headers });
};

const fetchUrl = ({ url, method, data, headers }) => {
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: method ? method : "GET",
    body: JSON.stringify(data),
    headers: headers
      ? headers
      : {
          token: window.localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
  })
    .then((response) => response)
    .catch((error) => console.log("error:", error));
};
