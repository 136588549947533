const DislikeIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M192.891 22.438 C 60.419 28.935,-18.881 171.237,45.762 286.456 C 102.506 387.597,239.758 407.648,323.570 327.040 C 388.498 264.595,395.722 160.614,340.022 90.239 C 304.387 45.214,248.991 19.687,192.891 22.438 M230.754 45.420 C 332.877 66.445,386.842 176.536,340.676 269.668 C 296.026 359.743,178.974 384.668,100.474 320.817 C 44.011 274.891,27.098 192.859,60.726 128.030 C 93.024 65.767,163.430 31.559,230.754 45.420 M121.801 114.569 C 113.619 119.651,110.979 128.944,115.432 136.989 C 116.003 138.020,129.932 152.296,146.386 168.715 C 162.841 185.133,176.303 198.999,176.303 199.526 C 176.303 200.054,162.841 213.919,146.386 230.337 C 115.239 261.417,114.870 261.834,113.642 267.276 C 111.418 277.135,122.422 289.334,131.185 286.725 C 131.759 286.554,133.507 286.137,135.071 285.799 C 137.157 285.348,146.249 276.871,169.206 253.973 L 200.498 222.762 230.818 253.149 C 263.117 285.521,264.270 286.475,271.112 286.475 C 282.822 286.475,290.426 275.051,286.201 263.806 C 285.650 262.338,271.364 247.269,254.455 230.320 L 223.710 199.502 254.130 169.182 C 270.872 152.495,285.147 137.491,285.877 135.812 C 290.708 124.707,283.204 112.796,271.377 112.796 C 263.869 112.796,263.768 112.878,230.818 145.903 L 200.498 176.290 169.680 145.545 C 152.731 128.636,137.662 114.350,136.194 113.799 C 131.983 112.217,124.986 112.591,121.801 114.569 "
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default DislikeIcon;
