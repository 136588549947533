import React from "react";
import { Container } from "@material-ui/core";

function Spinner({ children }) {
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {children}
    </Container>
  );
}
export default Spinner;
