const CategoriesIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M36.93 32.768c-5.528 3.04-5.231-1.178-5.254 74.5l-.021 69.378 2.804 2.804 2.803 2.804h138.247l3.133-2.798 3.133-2.799.291-66.986c.318-72.985.319-72.958-4.9-76.14-3.641-2.22-136.274-2.942-140.236-.763m186.356.382c-4.652 2.524-4.581 1.364-4.581 74.295v69.201l2.804 2.804 2.804 2.804h68.925c76.465 0 71.924.385 74.729-6.329 1.699-4.067 1.99-130.851.314-136.886-2.156-7.766 1.754-7.382-74.756-7.342-57.815.029-68.005.24-70.239 1.453m-62.135 74.284v54.676H51.799V52.758h109.352v54.676m187.05 0v54.676H238.849V52.758h109.352v54.676M36.235 220.2c-4.652 2.525-4.58 1.365-4.58 74.295v69.202l2.804 2.804 2.803 2.804h68.926c62.169 0 69.144-.154 71.158-1.565 4.938-3.458 4.908-3.012 4.907-73.944-.002-72.472.118-70.93-5.754-73.864-3.859-1.928-136.685-1.674-140.264.268m187.051 0c-4.652 2.525-4.581 1.365-4.581 74.295v69.202l2.804 2.804 2.804 2.804h69.009c77.888 0 72.766.51 74.985-7.478 1.371-4.938 1.347-130.793-.026-135.738-2.156-7.765 1.754-7.381-74.756-7.342-57.815.03-68.005.241-70.239 1.453m-62.135 74.284v54.677H51.799V239.808h109.352v54.676m187.05 0v54.677H238.849V239.808h109.352v54.676"
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default CategoriesIcon;
