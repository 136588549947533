const RelationshipIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M99.42,50.17c0.03,26.87-21.45,49.16-48.89,49.4C22.58,99.81,1.52,77.52,0.71,51.83
	C-0.16,24.37,21.48,1.17,49.17,0.85C77.21,0.53,99.29,22.39,99.42,50.17z M35.69,30.39c-0.15,0.04-0.4,0.15-0.66,0.18
	c-10.76,1.44-18.78,10.83-19.08,21.43c-0.39,13.91,12.51,25.08,26.19,22.4c0.92-0.18,1.5-0.06,2.18,0.66
	c5.37,5.66,11.98,8.12,19.69,6.89c8.85-1.41,14.93-6.53,17.71-15.02c2.78-8.5,0.77-16.12-5.4-22.61c-0.92-0.97-2-1.79-3.11-2.77
	c2.17-0.61,4.23-1.31,6.35-1.75c1.67-0.35,2.65-1.27,3.28-2.76c0.48-1.12,0.36-1.84-0.68-2.43c-3.97-2.21-7.95-4.4-11.94-6.57
	c-0.97-0.53-1.68-0.21-2.33,0.72c-0.95,1.37-1.23,2.72-0.58,4.35c0.69,1.73,1.12,3.57,1.66,5.36c0.09,0.28,0.13,0.58,0.22,1.02
	c-4.42-1.74-8.79-2.13-13.29-1.22c-0.34,0.07-0.88-0.24-1.15-0.54c-3.41-3.7-7.55-6.08-12.49-7.04c-0.6-0.12-1.2-0.21-1.78-0.31
	c1.86-1.91,3.64-3.74,5.42-5.56c0.81-0.83,1.19-3.32,0.51-4.23c-0.29-0.38-0.91-0.73-1.38-0.74c-4.59-0.05-9.18-0.07-13.77,0.02
	c-0.54,0.01-1.44,0.68-1.52,1.16c-0.26,1.6-0.3,3.15,1.21,4.44C32.69,26.97,34.13,28.75,35.69,30.39z"
      fill={color}
    />
    <path
      d="M35.69,30.39c-1.56-1.64-3.01-3.42-4.72-4.89c-1.51-1.29-1.48-2.84-1.21-4.44c0.08-0.47,0.98-1.15,1.52-1.16
	c4.59-0.09,9.18-0.07,13.77-0.02c0.47,0.01,1.09,0.35,1.38,0.74c0.69,0.9,0.31,3.39-0.51,4.23c-1.78,1.82-3.56,3.65-5.42,5.56
	c0.58,0.1,1.19,0.19,1.78,0.31c4.94,0.96,9.08,3.34,12.49,7.04c0.27,0.29,0.81,0.61,1.15,0.54c4.5-0.91,8.87-0.52,13.29,1.22
	c-0.09-0.44-0.14-0.74-0.22-1.02c-0.54-1.79-0.97-3.63-1.66-5.36c-0.65-1.63-0.37-2.99,0.58-4.35c0.65-0.93,1.36-1.24,2.33-0.72
	c3.99,2.17,7.98,4.35,11.94,6.57c1.05,0.58,1.16,1.3,0.68,2.43c-0.64,1.49-1.61,2.41-3.28,2.76c-2.12,0.44-4.19,1.14-6.35,1.75
	c1.11,0.98,2.19,1.8,3.11,2.77c6.17,6.48,8.18,14.11,5.4,22.61c-2.78,8.49-8.86,13.61-17.71,15.02c-7.71,1.23-14.32-1.23-19.69-6.89
	c-0.68-0.72-1.26-0.84-2.18-0.66c-13.68,2.69-26.58-8.48-26.19-22.4c0.3-10.6,8.32-19.99,19.08-21.43
	C35.29,30.54,35.54,30.44,35.69,30.39z M47.9,72.62c4.58,5.19,13.77,7.56,21.56,3.19c7.88-4.43,11.29-13.94,8.02-22.28
	c-3.29-8.39-12.2-13.11-20.96-11.03c-4.27,1.02-7.77,3.3-10.35,6.86c-3.89,5.38-4.48,11.26-2.24,17.55
	c-1.06,0.3-1.97,0.54-2.88,0.81c-0.84,0.25-1.28,0.02-1.56-0.88c-3.24-10.31,1.25-21.34,10.79-26.43c0.23-0.12,0.45-0.28,0.69-0.43
	c-5.14-5.57-14.58-7.57-22.31-2.74c-7.69,4.81-10.66,14.5-6.98,22.63c3.74,8.27,13.02,12.6,21.57,10.05
	c4.35-1.29,7.82-3.84,10.18-7.69c3.14-5.13,3.61-10.62,1.5-16.45c1.01-0.3,1.94-0.52,2.81-0.85c1.05-0.4,1.39,0.11,1.66,1.01
	c2.97,9.64-0.9,20.08-9.46,25.44C49.27,71.82,48.59,72.21,47.9,72.62z"
      fill={color2}
    />
    <path
      d="M47.9,72.62c0.69-0.41,1.38-0.8,2.04-1.22c8.56-5.36,12.43-15.8,9.46-25.44c-0.28-0.9-0.61-1.41-1.66-1.01
	c-0.88,0.33-1.8,0.55-2.81,0.85c2.11,5.83,1.65,11.32-1.5,16.45c-2.36,3.85-5.83,6.39-10.18,7.69c-8.56,2.54-17.83-1.79-21.57-10.05
	c-3.68-8.13-0.71-17.81,6.98-22.63c7.73-4.84,17.17-2.83,22.31,2.74c-0.24,0.15-0.45,0.31-0.69,0.43
	c-9.54,5.09-14.03,16.12-10.79,26.43c0.28,0.9,0.72,1.13,1.56,0.88c0.91-0.27,1.83-0.52,2.88-0.81c-2.24-6.29-1.65-12.17,2.24-17.55
	c2.58-3.57,6.08-5.85,10.35-6.86c8.76-2.08,17.67,2.64,20.96,11.03c3.27,8.34-0.15,17.86-8.02,22.28
	C61.67,80.18,52.47,77.81,47.9,72.62z"
      fill={color}
    />
  </svg>
);

export default RelationshipIcon;
