const LoveIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M49.94,99.59C24.41,99.87,0.7,79.26,0.63,50.34C0.55,21.64,24.34,0.23,51.27,0.93
	c26.18,0.67,48.69,22.4,48.05,50.55C98.7,78.63,76.24,99.84,49.94,99.59z M50.07,36.73c-1.33-1.48-2.61-3.17-4.15-4.58
	c-4.52-4.16-9.79-5.8-15.78-3.85c-5.86,1.91-9.34,6.13-10.55,12.08c-1.63,7.98,0.81,14.9,6.46,20.54c7.19,7.18,14.65,14.11,22,21.13
	c1.68,1.6,2.44,1.58,4.15-0.05c7.19-6.85,14.45-13.62,21.51-20.61c5.7-5.64,8.17-12.54,6.77-20.59c-1.38-7.94-7.96-13.44-15.9-13.26
	c-3.88,0.09-7.21,1.68-10,4.28C52.92,33.35,51.52,35.13,50.07,36.73z"
      fill={color}
    />
    <path
      d="M50.07,36.73c1.45-1.6,2.86-3.38,4.5-4.91c2.79-2.6,6.13-4.2,10-4.28c7.94-0.18,14.52,5.32,15.9,13.26
	c1.4,8.05-1.07,14.95-6.77,20.59c-7.05,6.99-14.32,13.76-21.51,20.61c-1.71,1.63-2.47,1.65-4.15,0.05
	c-7.36-7.02-14.81-13.95-22-21.13c-5.65-5.64-8.09-12.56-6.46-20.54c1.21-5.95,4.69-10.18,10.55-12.08
	c5.99-1.95,11.25-0.31,15.78,3.85C47.45,33.56,48.74,35.25,50.07,36.73z M50.05,78.26c0.19-0.16,0.32-0.24,0.43-0.35
	c6.94-6.65,13.96-13.21,20.77-20c4.2-4.18,6.09-9.42,5.3-15.38c-0.62-4.75-2.93-8.43-7.61-10.15c-4.67-1.71-8.78-0.46-12.11,3.02
	c-1.79,1.88-3.2,4.14-4.65,6.33c-1.31,1.97-2.92,2.01-4.17,0.02c-1.08-1.72-2.13-3.48-3.42-5.03c-2.4-2.88-5.39-4.93-9.27-5.06
	c-6.57-0.22-11.75,4.92-12.07,11.81c-0.27,5.81,1.83,10.71,5.9,14.75c4.87,4.83,9.84,9.56,14.79,14.31
	C45.95,74.47,48.01,76.35,50.05,78.26z"
      fill={color2}
    />
    <path
      d="M50.05,78.26c-2.05-1.92-4.1-3.8-6.11-5.73c-4.94-4.75-9.92-9.48-14.79-14.31c-4.07-4.04-6.17-8.94-5.9-14.75
	c0.32-6.88,5.49-12.02,12.07-11.81c3.88,0.13,6.87,2.18,9.27,5.06c1.29,1.55,2.34,3.31,3.42,5.03c1.25,1.99,2.86,1.95,4.17-0.02
	c1.45-2.18,2.86-4.45,4.65-6.33c3.32-3.48,7.43-4.74,12.11-3.02c4.68,1.72,6.99,5.4,7.61,10.15c0.78,5.96-1.11,11.2-5.3,15.38
	c-6.81,6.78-13.83,13.34-20.77,20C50.38,78.02,50.25,78.11,50.05,78.26z"
      fill={color}
    />
  </svg>
);

export default LoveIcon;
