import { useContext } from "react";
import UserOptions from "./UserOptions/UserOptions";
import MyMemes from "./MyMemes";
import styles from "../styles/mobilerightbar.module.css";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { ThemeContext } from "../store/theme-context.js";
const MobileRightBar = ({
  user,
  setShowMyMemes,
  showMyMemes,
  updateMemes,
  updateUserMemes,
  categories,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={styles.contentRightBar}>
      <div className={styles.UserOptions}>
        <UserOptions
          user={user}
          setShowMyMemes={setShowMyMemes}
          showMyMemes={showMyMemes}
        />
        <IconButton onClick={() => setShowMyMemes()}>
          <Close
            fontSize="medium"
            className={styles.closeIcon}
            htmlColor={theme === "dark" ? "#fff" : "000"}
          />
        </IconButton>
      </div>

      <div className={styles.myMemes}>
        {user === null && <h1>Anuncios</h1>}
        {user !== null && (
          <MyMemes
            user={user}
            updateMemes={updateMemes}
            updateUserMemes={updateUserMemes}
            categories={categories}
            showMyMemes={showMyMemes}
          />
        )}
      </div>
    </div>
  );
};

export default MobileRightBar;
