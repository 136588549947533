const DislikeIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M116.667 49.061 C 17.304 60.750,69.154 80.000,200.000 80.000 C 280.000 80.000,333.333 74.667,333.333 66.667 C 333.333 59.333,322.171 53.333,308.528 53.333 C 294.884 53.333,257.384 51.049,225.194 48.256 C 193.004 45.464,144.167 45.826,116.667 49.061 M80.000 226.667 L 80.000 346.667 200.000 346.667 L 320.000 346.667 320.000 226.667 L 320.000 106.667 200.000 106.667 L 80.000 106.667 80.000 226.667 M223.333 198.299 C 244.481 181.714,244.952 182.186,228.367 203.333 C 212.857 223.110,212.857 230.223,228.367 250.000 C 244.952 271.147,244.481 271.619,223.333 255.034 C 203.556 239.524,196.444 239.524,176.667 255.034 C 155.519 271.619,155.048 271.147,171.633 250.000 C 187.143 230.223,187.143 223.110,171.633 203.333 C 155.048 182.186,155.519 181.714,176.667 198.299 C 196.444 213.809,203.556 213.809,223.333 198.299 "
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default DislikeIcon;
