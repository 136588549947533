import ReactDOM from "react-dom/client";
import App from "./App";
import axios from "axios";
import ThemeProvider from "./store/theme-context.js";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
