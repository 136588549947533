import { useState } from "react";
import styles from "./text.module.css";
const Text: React.FC<{
  limit: number;
  text: string;
}> = ({ limit, text }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p>
      {text.length > limit ? (
        <>
          {isReadMore ? text.slice(0, 130) + "..." : text}
          <span onClick={toggleReadMore} className={styles.readMore}>
            {isReadMore ? "Ver más" : "Ver menos"}
          </span>
        </>
      ) : (
        text
      )}
    </p>
  );
};
export default Text;
