import styles from "./memeContent.module.css";
import Text from "../UI/Text";
import { useHistory } from "react-router-dom";
const MemeContent = ({ meme}) => {
  const history = useHistory();
  const handleInClickMeme = () => {
    if (history.location.pathname === "/") {
      history.push(`/meme/${meme.id}`);
    }
  };

  return history.location.pathname === "/" ? (
    <>
      <div className={styles.descriptionDashboard}>
        <Text limit={130} text={meme.description} />
      </div>
      <div className={styles.image} onClick={() => handleInClickMeme()}>
        <img src={process.env.REACT_APP_API_URL + meme.path} alt="meme" />
      </div>
    </>
  ) : (
    <>
      <div className={styles.descriptionMeme}>
        <Text limit={130} text={meme.description} />
      </div>
      <div className={styles.image}>
        <img src={process.env.REACT_APP_API_URL + meme.path} alt="meme" />
      </div>
    </>
  );
};
export default MemeContent;
