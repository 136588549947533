import React from "react";
import MemeCommentInfo from "../MemeCommentInfo/MemeCommentInfo";
import { CommentEntity, UserEntity } from "../../models/Entities";
import CircularPicture from "../CircularPicture";
import { FixMeLater } from "../../models/Temp";
import styles from "./comment.module.css";
import {
  LikeIcon,
  DislikeIcon,
  FilledLikeIcon,
  FilledDislikeIcon,
} from "../../SVG/Icons";
import { IconButton } from "@material-ui/core";
import { useState, useContext } from "react";
import { ThemeContext } from "../../store/theme-context.js";
const Comment: React.FC<{
  comment: CommentEntity;
  user: UserEntity;
  handleMessages: FixMeLater;
  deleteComment: (id: number) => void;
}> = ({ comment, user, deleteComment, handleMessages }) => {
  const date = comment.created_at;

  const getDate = (): string => {
    let result = null;
    const now = new Date();
    const created = Date.parse(date);
    result = getDifference(created, now);
    return String(result);
  };

  const getDifference = (date1: FixMeLater, date2: FixMeLater) => {
    let time = "segundos";
    let diff = Math.abs(date1 - date2) / 1000;
    if (isGreaterThan(diff, 59)) {
      time = "minutos";
      diff = diff / 60;
      if (isGreaterThan(diff, 59)) {
        time = "horas";
        diff = diff / 60;
        if (isGreaterThan(diff, 23)) {
          diff = diff / 24;
          time = "días";
        }
      }
    }
    return Math.round(diff).toString() + " " + time;
  };

  const isGreaterThan = (value: number, limit: number) => {
    return value > limit;
  };

  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [likesCount, setLikeCount] = useState(0);
  const [dislikesCount, setDislikesCount] = useState(0);

  const { theme } = useContext(ThemeContext);

  const handleLike = () => {
    setIsLiked(!isLiked);
  };

  const handleDislike = () => {
    setIsDisliked(!isDisliked);
  };

  const onClickDislike = () => {
    isDisliked
      ? setDislikesCount(dislikesCount - 1)
      : setDislikesCount(dislikesCount + 1);
    handleDislike();
  };

  const onClickLike = () => {
    isLiked ? setLikeCount(likesCount - 1) : setLikeCount(likesCount + 1);
    handleLike();
  };

  return (
    <>
      {comment && (
        <div className={styles.container}>
          <div className={styles.commentInfo}>
            <div className={styles.profilePicture}>
              <CircularPicture path={comment.owner.picture} />
            </div>
            <p>
              <span className={styles.username}>{comment.owner.username}</span>
              <span className={styles.date}>{getDate()} ·</span>
              <span className={styles.comment}>{comment.description}</span>
            </p>
          </div>
          <div className={styles.commentOptions}>
            <p>
              <span className={styles.reply}>Responder</span>
            </p>
            <div className={styles.likesOptions}>
              <IconButton className={styles.likesBtns} onClick={onClickLike}>
                {isLiked ? (
                  <FilledLikeIcon
                    color="#ff3651"
                    color2={theme === "dark" ? "#000" : "#fff"}
                    width={18}
                    height={18}
                  />
                ) : (
                  <LikeIcon
                    color={theme === "dark" ? "#ff3651" : "#ff3651"}
                    width={18}
                    height={18}
                  />
                )}
              </IconButton>
              <p className={styles.likesCount}>{likesCount}</p>
              <IconButton className={styles.likesBtns} onClick={onClickDislike}>
                {isDisliked ? (
                  <FilledDislikeIcon
                    color="#ff3651"
                    color2={theme === "dark" ? "#000" : "#fff"}
                    width={18}
                    height={18}
                  />
                ) : (
                  <DislikeIcon
                    color={theme === "dark" ? "gray" : "gray"}
                    width={18}
                    height={18}
                  />
                )}
              </IconButton>
              <p className={styles.likesCount}>{dislikesCount}</p>
            </div>
            <div>
              <MemeCommentInfo
                user={user}
                comment={comment}
                deleteComment={deleteComment}
                handleMessages={handleMessages}
              />
            </div>
          </div>
          <div className={styles.viewRepliesContainer}>
            <span className={styles.viewReplies}>Ver 0 respuestas</span>
          </div>
        </div>
      )}
    </>
  );
};
export default Comment;
