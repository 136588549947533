const SciFiIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 420 420"}
  >
    <path
      d="M209.32,2.25c116.84,0.35,209.39,96.93,205.94,212.8c-3.21,107.69-91.28,200.65-207.7,199.91
	C90.68,414.2,1.39,317.8,2.74,205.51C4.08,93.99,96.57,2.05,209.32,2.25z M272.76,282.94c7.86-2.34,15.11-4.22,22.17-6.66
	c15.58-5.39,30.11-12.64,41.07-25.5c7.06-8.28,6.83-11.93-0.78-19.56c-13.44-13.46-30.18-21.02-47.86-26.9
	c-10.11-3.36-10.13-3.31-10.26-13.82c-0.43-34.2-21.9-60.53-55.3-67.54c-3.25-0.68-3.95-2.15-3.89-5.06c0.13-7-0.18-14.02,0.17-21
	c0.11-2.15,1.3-4.7,2.82-6.26c5.04-5.17,6.59-11.13,4.72-17.88c-1.9-6.83-8.17-11.57-15.06-11.95c-7.4-0.4-13.71,3.2-16.73,9.54
	c-3.23,6.79-1.97,13.9,3.05,19.47c1.64,1.82,3.46,4.24,3.6,6.49c0.46,7.31,0.06,14.66,0.22,22c0.06,2.93-1.07,4.11-3.89,4.59
	c-30.71,5.21-56.54,33.73-55.24,69.05c0.43,11.67,1.92,8.48-8.74,11.88c-18.77,5.98-36.38,13.96-50.44,28.26
	c-6.42,6.53-6.52,10.23-0.92,17.38c8.35,10.66,19.42,17.75,31.76,22.68c10.57,4.22,21.51,7.5,32.54,11.28
	c-4.92,13.53-9.93,27.27-14.9,41.04c-2.14,5.93-0.36,10.75,4.49,12.46c5.09,1.8,9.32-0.5,11.66-6.33c0.43-1.08,0.8-2.19,1.2-3.28
	c4.62-12.66,9.31-25.31,13.82-38.01c0.86-2.42,1.86-3.45,4.61-3.2c11.09,1.04,22.19,1.84,34.01,2.78c0,4.43,0,9.05,0,13.67
	c0.01,15.51-0.07,31.01,0.06,46.52c0.05,5.87,3.21,9.16,8.27,9.28c5.28,0.12,8.65-3.33,8.95-9.22c0.08-1.5,0.02-3,0.02-4.5
	c0-16.67,0-33.35,0-50.02c0-1.94,0-3.88,0-5.78c12.91-0.98,25.13-1.9,37.42-2.83c0.92,2.43,1.76,4.59,2.56,6.76
	c4.58,12.5,9.14,25,13.73,37.49c1.33,3.64,4.04,6.38,7.79,5.75c2.88-0.48,6.37-2.44,7.82-4.81c1.26-2.05,0.71-5.85-0.2-8.49
	C282.55,309.48,277.65,296.41,272.76,282.94z"
      fill={color}
    />
    <path
      d="M272.76,282.94c4.89,13.47,9.79,26.54,14.3,39.75c0.9,2.63,1.45,6.44,0.2,8.49c-1.46,2.38-4.94,4.33-7.82,4.81
	c-3.74,0.63-6.45-2.11-7.79-5.75c-4.59-12.49-9.15-25-13.73-37.49c-0.8-2.17-1.64-4.33-2.56-6.76c-12.29,0.93-24.51,1.85-37.42,2.83
	c0,1.9,0,3.84,0,5.78c0,16.67,0,33.35,0,50.02c0,1.5,0.05,3-0.02,4.5c-0.3,5.89-3.67,9.35-8.95,9.22c-5.06-0.12-8.22-3.41-8.27-9.28
	c-0.13-15.51-0.06-31.01-0.06-46.52c0-4.62,0-9.24,0-13.67c-11.81-0.95-22.92-1.74-34.01-2.78c-2.75-0.26-3.75,0.78-4.61,3.2
	c-4.51,12.71-9.19,25.35-13.82,38.01c-0.4,1.09-0.76,2.2-1.2,3.28c-2.34,5.84-6.57,8.13-11.66,6.33c-4.85-1.71-6.63-6.53-4.49-12.46
	c4.97-13.76,9.99-27.51,14.9-41.04c-11.03-3.78-21.98-7.06-32.54-11.28c-12.34-4.93-23.4-12.01-31.76-22.68
	c-5.6-7.15-5.51-10.84,0.92-17.38c14.05-14.3,31.67-22.28,50.44-28.26c10.66-3.4,9.17-0.21,8.74-11.88
	c-1.3-35.32,24.53-63.84,55.24-69.05c2.83-0.48,3.95-1.66,3.89-4.59c-0.16-7.33,0.23-14.69-0.22-22c-0.14-2.25-1.97-4.67-3.6-6.49
	c-5.01-5.57-6.28-12.68-3.05-19.47c3.02-6.34,9.33-9.94,16.73-9.54c6.89,0.37,13.16,5.12,15.06,11.95
	c1.87,6.75,0.32,12.72-4.72,17.88c-1.52,1.56-2.71,4.1-2.82,6.26c-0.35,6.99-0.04,14-0.17,21c-0.06,2.91,0.64,4.38,3.89,5.06
	c33.4,7,54.87,33.34,55.3,67.54c0.13,10.51,0.15,10.46,10.26,13.82c17.68,5.88,34.43,13.44,47.86,26.9
	c7.61,7.63,7.84,11.28,0.78,19.56c-10.96,12.86-25.49,20.11-41.07,25.5C287.87,278.72,280.62,280.6,272.76,282.94z M97.24,241.8
	c3.77,2.9,6.97,5.76,10.54,8.03c14.88,9.46,31.53,14.1,48.66,17.14c28.91,5.14,58.03,5.53,87.16,2.63
	c20.78-2.07,41.27-6,60.03-15.79c6.17-3.22,11.76-7.58,18.1-11.74c-13.97-12.11-29.32-18.25-45.38-23.08
	c-3.42-1.03-5.85-0.58-8.66,1.61c-2.99,2.32-6.45,4.17-9.94,5.67c-19.46,8.37-39.93,9.92-60.76,8.42
	c-14.56-1.05-29.29-3.13-41.47-11.35c-7.88-5.32-14.37-4.93-21.97-1.54c-0.6,0.27-1.29,0.35-1.91,0.58
	C119.44,226.92,107.74,232.34,97.24,241.8z M209.19,218.04c14.46-0.18,29.06-1.89,42.88-8.07c5.47-2.45,9.22-5.52,7.95-12.4
	c-0.63-3.39-0.03-6.99-0.26-10.47c-1.39-21.48-15.05-39.22-35.16-45.79c-19.04-6.23-40.82,0.06-53.85,15.48
	c-10.09,11.94-12.37,25.96-12.16,41.09c0.09,6.4,2.36,9.69,7.81,12.09C179.91,215.94,194.15,217.79,209.19,218.04z"
      fill={color2}
    />
    <path
      d="M97.24,241.8c10.5-9.46,22.2-14.89,34.4-19.42c0.62-0.23,1.31-0.31,1.91-0.58c7.6-3.38,14.09-3.78,21.97,1.54
	c12.18,8.22,26.9,10.31,41.47,11.35c20.83,1.5,41.3-0.06,60.76-8.42c3.49-1.5,6.95-3.35,9.94-5.67c2.81-2.18,5.25-2.63,8.66-1.61
	c16.06,4.82,31.4,10.97,45.38,23.08c-6.34,4.16-11.92,8.51-18.1,11.74c-18.76,9.79-39.25,13.72-60.03,15.79
	c-29.12,2.9-58.25,2.51-87.16-2.63c-17.14-3.05-33.78-7.68-48.66-17.14C104.21,247.56,101.02,244.7,97.24,241.8z"
      fill={color}
    />
    <path
      d="M209.19,218.04c-15.03-0.26-29.27-2.1-42.79-8.07c-5.45-2.4-7.72-5.69-7.81-12.09
	c-0.21-15.13,2.08-29.15,12.16-41.09c13.03-15.42,34.81-21.71,53.85-15.48c20.11,6.58,33.77,24.32,35.16,45.79
	c0.23,3.49-0.37,7.08,0.26,10.47c1.27,6.87-2.47,9.95-7.95,12.4C238.25,216.15,223.65,217.87,209.19,218.04z"
      fill={color}
    />
  </svg>
);

export default SciFiIcon;
