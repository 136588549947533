import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { createReport } from "../services/ReportService";

const ReportDialog = ({
  handleMessages,
  onClose,
  openDialog,
  report,
  user,
}) => {
  const REPORT_OPTIONS = ["Spam", "Ofensivo", "Inapropiado"];
  const [info, setInfo] = useState({
    description: "",
    reportReason: REPORT_OPTIONS[0],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const emailBody = {
    description: info.description,
    report,
    subject: info.reportReason,
    user,
  };

  const handleClose = () => {
    setInfo({
      reportReason: REPORT_OPTIONS[0],
      description: "",
    });
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let messageSeverity;
    createReport(emailBody)
      .then((data) => {
        data.ok ? (messageSeverity = "success") : (messageSeverity = "error");
        return data.json();
      })
      .then(({ message }) => {
        handleMessages(messageSeverity, message);
      })
      .finally(() => handleClose());
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <DialogTitle id="alert-dialog-title">{"Reporte"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Por favor seleccione la razón del reporte
          </DialogContentText>

          <Select
            required
            defaultValue={REPORT_OPTIONS[0]}
            name="reportReason"
            onChange={handleChange}
            value={info.reportReason}
            fullWidth
          >
            {REPORT_OPTIONS?.map((option) => (
              <MenuItem
                key={option}
                value={option}
                selected={option === "Spam"}
              >
                {option}
              </MenuItem>
            ))}
          </Select>

          <div>
            <TextField
              required
              fullWidth
              id="standard-textarea"
              label="Descripción"
              placeholder="Descripción"
              multiline
              variant="standard"
              name="description"
              onChange={handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit">Reportar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReportDialog;
