import React from "react";
import {Helmet} from "react-helmet";
import MediaQuery from "react-responsive";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { Grid } from "@material-ui/core";
import Nav from "./components/Nav/Nav";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashCategory from "./pages/Dashboard/DashCategory";
import SearchBar from "./components/SearchBar/SearchBar";
import TopBar from "./components/TopBar/TopBar";
import Saves from "./pages/Saves";
import Login from "./pages/Login";
import Signup from "./pages/SignUp";
import Memes from "./pages/Memes/Memes";
import Messages from "./components/Messages";
import Profile from "./pages/Profile/Profile";
import ResetPassword from "./pages/ResetPassword";
import RecoverPassword from "./pages/RecoverPassword";

import "./styles/rdm.css";
import { ThemeContext } from "./store/theme-context.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      memes: [],
      filteredMemes: [],
      categories: null,
      isLoading: false,
      toggleNav: false,
      showMyMemes: false,
      message: {
        show: false,
        text: null,
        severity: null,
      },
    };
    this.updateUser = this.updateUser.bind(this);
  }

  componentDidMount = async () => {
    if (window.localStorage.getItem("token")) {
      const user = await this.getUser();
      this.updateUser(user);
    }
    const categories = await this.getCategories();
    this.updateCategories(categories);
  };
  getCategories = async () => {
    let response;
    try {
      response = await axios.get(`/api/categories`);
      if (response.data.success) {
        return response.data.categories;
      }
      if (response.data.error) {
        this.handleMessages("error", response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  updateCategories = (categories) => {
    this.setState({
      categories: categories,
    });
  };
  getUserMemes = async () => {
    const config = {
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    let response;
    try {
      response = await axios.get(`/api/meme`, config);
      if (response.data.success) {
        return response.data.memes;
      }
    } catch (error) {
      console.log(error);
    }
  };
  getUserSaves = async () => {
    const config = {
      headers: {
        token: window.localStorage.getItem("token"),
      },
    };
    let response;
    try {
      response = await axios.get(`/api/people/save`, config);
      if (response.data.success) {
        return response.data.memes;
      }
      if (response.data.error) {
        this.handleMessages("error", response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  getUser = async () => {
    const config = {
      headers: {
        token: window.localStorage.getItem("token"),
      },
    };
    let response;
    try {
      response = await axios.get(`/api/people/person`, config);
      if (response.data.success) {
        if (response.data.user) {
          return response.data.user;
        }
      } else {
        this.handleMessages("error", response.data.message);
      }
    } catch (error) {
      this.handleMessages("error", error);
    }
  };
  updateUser = (user) => {
    if (user !== undefined) {
      this.setState({
        user: {
          name: user.name,
          lastname: user.lastname,
          username: user.username,
          picture: user.picture,
          email: user.email,
          subscribers: user.subscribers,
          following: user.following,
          own_memes: user.own_memes,
          liked_memes: user.liked_memes,
          disliked_memes: user.disliked_memes,
          saved_memes: user.saved_memes,
          theme: user.theme,
        },
      });
      this.context.setTheme(user.theme);
    }
  };
  updateMemes = async (memes) => {
    this.setState({
      isLoading: true,
    });
    if (!memes) {
      memes = this.state.memes;
    }
    this.setState({
      memes: memes,
      filteredMemes: memes,
      isLoading: false,
    });
  };
  addMemes = async (memes) => {
    this.setState({
      isLoading: true,
    });
    this.setState((prevState) => ({
      memes: [...memes, ...prevState.memes],
      filteredMemes: [...memes, ...prevState.filteredMemes],
      isLoading: false,
    }));
  };

  updateUserMemes = async () => {
    const userMemes = await this.getUserMemes();
    if (userMemes.length) {
      this.setState({
        user: {
          ...this.state.user,
          own_memes: userMemes,
        },
      });
    }
    const user = await this.getUser();
    this.updateUser(user);
  };

  updateFilteredMemes = async (memes) => {
    this.setState({
      filteredMemes: memes,
    });
  };

  updateUserSaves = (meme) => {
    const newSavedMemes = this.state.user.saved_memes.find(
      (saved_meme) => saved_meme.id === meme.id
    )
      ? this.state.user.saved_memes.filter(
          (saved_meme) => saved_meme.id !== meme.id
        )
      : [...this.state.user.saved_memes, meme];
    this.setState({
      user: {
        ...this.state.user,
        saved_memes: newSavedMemes,
      },
    });
  };

  updateFollowing = (user) => {
    const newFollowings = this.state.user.following.find(
      (followed_user) => followed_user.email === user.email
    )
      ? this.state.user.following.filter(
          (followed_user) => followed_user.email !== user.email
        )
      : [...this.state.user.following, user];
    this.setState({
      user: {
        ...this.state.user,
        following: newFollowings,
      },
    });
  };

  handleLogin = async () => {
    this.setState({
      isLoading: true,
    });
    const user = await this.getUser();
    this.updateUser(user);
    this.setState({
      isLoading: false,
    });
  };

  handleLogout = async () => {
    this.setState({
      user: null,
    });
  };
  handleSignUp = async () => {
    const user = await this.getUser();
    this.updateUser(user);
  };

  handleResetPassword = async () => {
    const user = await this.getUser();
    this.updateUser(user);
  };

  handleForgotPassword = async () => {
    const user = await this.getUser();
    this.updateUser(user);
  };

  updateSuscribers = async () => {
    const user = await this.getUser();
    this.updateUser(user);
  };

  handleMessages = (severity, message) => {
    this.setState({
      message: {
        show: true,
        text: message,
        severity: severity,
      },
    });
  };

  closeMessage = () => {
    this.setState({
      message: {
        show: false,
        text: null,
        severity: null,
      },
    });
  };

  setToggleNav = () => {
    this.setState((prevState) => ({
      toggleNav: !prevState.toggleNav,
      showMyMemes: false,
    }));
  };

  setShowMyMemes = () => {
    this.setState((prevState) => ({
      showMyMemes: !prevState.showMyMemes,
      toggleNav: false,
    }));
  };

  deleteMeme = async (memeId) => {
    // delete meme from frontend
    this.setState((prevState) => ({
      filteredMemes: prevState.memes.filter((meme) => meme.id !== memeId),
      user: {
        ...this.state.user,
        own_memes: prevState.user.own_memes.filter(
          (meme) => meme.id !== memeId
        ),
      },
    }));
    // send delete cmd to backend
    let state = "error";
    let response;
    try {
      response = await axios.delete(`/api/meme`, {
        data: {
          meme: memeId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (response.data.success) {
        state = "success";
      }
    } catch (error) {
      response.data.message = error;
      console.log(error);
    } finally {
      this.handleMessages(state, response.data.message);
    }
  };
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Helmet>
          <script id="idhb" type="text/javascript" src="https://hb.improvedigital.com/pbw/headerlift.min.js?t=home"></script>        
          <script>
            {'idhb = idhb || {};idhb.que = idhb.que || [];idhb.que.push(function() {idhb.requestAds();});'}
          </script>
          <script type="text/javascript" class="teads" async="true" src="//a.teads.tv/page/90711/tag"></script>
          <script type="text/javascript" src="https://sac.ayads.co/sublime/23944"></script>
        </Helmet>
        <div className="container" id={this.context.theme}>
          <MediaQuery minWidth={700}>
            <div className="navbar">
              <Nav
                user={this.state.user}
                toggleNav={this.state.toggleNav}
                setToggleNav={this.setToggleNav}
                categories={this.state.categories}
                 
              />
            </div>
            <div className="rightPart">
              <div className="top_bar">
                {window.location.pathname === "/" && (
                  <SearchBar
                  filteredMemes={this.state.filteredMemes}
                  categories={this.state.categories}
                  handleMessages={this.handleMessages}
                  updateMemes={this.updateMemes}
                  />
                )}
                <TopBar
                  user={this.state.user}
                  handleLogout={this.handleLogout}
                  setToggleNav={this.setToggleNav}
                  setShowMyMemes={this.setShowMyMemes}
                  handleMessages={this.handleMessages}
                  history={this.history}
                />
              </div>
              <div className="dashboard">
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props) => (
                      <Dashboard
                        {...props}
                        user={this.state.user}
                        filteredMemes={this.state.filteredMemes}
                        categories={this.state.categories}
                        updateSuscribers={this.updateSuscribers}
                        updateUserSaves={this.updateUserSaves}
                        handleMessages={this.handleMessages}
                        updateMemes={this.updateMemes}
                        updateUserMemes={this.updateUserMemes}
                        setShowMyMemes={this.setShowMyMemes}
                        showMyMemes={this.state.showMyMemes}
                        updateFilteredMemes={this.updateFilteredMemes}
                        handleLogout={this.handleLogout}
                        setToggleNav={this.setToggleNav}
                        deleteMeme={this.deleteMeme}
                        updateFollowing={this.updateFollowing}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/memes/:category"
                    render={(props) => (
                      <DashCategory
                        {...props}
                        user={this.state.user}
                        filteredMemes={this.state.filteredMemes}
                        categories={this.state.categories}
                        updateSuscribers={this.updateSuscribers}
                        updateUserSaves={this.updateUserSaves}
                        handleMessages={this.handleMessages}
                        updateMemes={this.updateMemes}
                        updateUserMemes={this.updateUserMemes}
                        setShowMyMemes={this.setShowMyMemes}
                        showMyMemes={this.state.showMyMemes}
                        updateFilteredMemes={this.updateFilteredMemes}
                        handleLogout={this.handleLogout}
                        setToggleNav={this.setToggleNav}
                        deleteMeme={this.deleteMeme}
                        updateFollowing={this.updateFollowing}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/saves"
                    render={(props) => (
                      <Saves
                        {...props}
                        user={this.state.user}
                        memes={this.state.filteredMemes}
                        updateFilteredMemes={this.updateFilteredMemes}
                        categories={this.state.categories}
                        handleLogout={this.handleLogout}
                        handleMessages={this.handleMessages}
                        setToggleNav={this.setToggleNav}
                        setShowMyMemes={this.setShowMyMemes}
                        showMyMemes={this.state.showMyMemes}
                        updateMemes={this.updateMemes}
                        updateUserMemes={this.updateUserMemes}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/meme/:meme_id"
                    render={(props) => (
                      <Memes
                        {...props}
                        location={"meme"}
                        user={this.state.user}
                        updateSuscribers={this.updateSuscribers}
                        handleMessages={this.handleMessages}
                        deleteMeme={this.deleteMeme}
                         
                        backEvent={this.backEvent}
                        updateSaves={this.updateUserSaves}
                        updateFollowing={this.updateFollowing}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/profile"
                    render={(props) => (
                      <Profile
                        {...props}
                        user={this.state.user}
                        handleMessages={this.handleMessages}
                        updateUser={this.updateUser}
                        getUser={this.getUser}
                        handleLogout={this.handleLogout}
                        setToggleNav={this.setToggleNav}
                        setShowMyMemes={this.setShowMyMemes}
                         
                        backEvent={this.backEvent}
                      />
                    )}
                  />
                  {this.state.user === null && (
                    <Route
                      exact
                      path="/login"
                      render={(props) => (
                        <Login
                          {...props}
                          handleLogin={this.handleLogin}
                          handleMessages={this.handleMessages}
                        />
                      )}
                    />
                  )}
                  <Route
                    exact
                    path="/forgotPassword"
                    render={(props) => (
                      <RecoverPassword
                        {...props}
                        handleForgotPassword={this.handleForgotPassword}
                        handleMessages={this.handleMessages}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/passwordRecovery/:username/:token"
                    render={(props) => (
                      <ResetPassword
                        {...props}
                        handleResetPassword={this.handleResetPassword}
                        handleMessages={this.handleMessages}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/signup"
                    render={(props) => (
                      <Signup
                        {...props}
                        handleSignUp={this.handleSignUp}
                        handleMessages={this.handleMessages}
                      />
                    )}
                  />
                  <Route render={() => <Redirect to="/" />} />
                </Switch>
                <Grid item md={12}>
                  <Messages
                    show={this.state.message.show}
                    message={this.state.message.text}
                    severity={this.state.message.severity}
                    onClose={this.closeMessage}
                  ></Messages>
                </Grid>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={699}>
            <>
              {this.state.toggleNav && (
                <Nav
                  user={this.state.user}
                  toggleNav={this.state.toggleNav}
                  setToggleNav={this.setToggleNav}
                  categories={this.state.categories}
                   
                />
              )}
              <div className="topSection">
                <TopBar
                  user={this.state.user}
                  handleLogout={this.handleLogout}
                  setToggleNav={this.setToggleNav}
                  setShowMyMemes={this.setShowMyMemes}
                  history={this.props.history}
                  handleMessages={this.handleMessages}
                />
                <div className="dashboard">
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={(props) => (
                        <>
                          <SearchBar
                            filteredMemes={this.state.filteredMemes}
                            categories={this.state.categories}
                            handleMessages={this.handleMessages}
                            updateMemes={this.updateMemes}
                          />
                          <Dashboard
                            {...props}
                            user={this.state.user}
                            filteredMemes={this.state.filteredMemes}
                            categories={this.state.categories}
                            updateSuscribers={this.updateSuscribers}
                            updateUserSaves={this.updateUserSaves}
                            handleMessages={this.handleMessages}
                            updateMemes={this.updateMemes}
                            updateUserMemes={this.updateUserMemes}
                            setShowMyMemes={this.setShowMyMemes}
                            showMyMemes={this.state.showMyMemes}
                             
                            updateFilteredMemes={this.updateFilteredMemes}
                            handleLogout={this.handleLogout}
                            setToggleNav={this.setToggleNav}
                            deleteMeme={this.deleteMeme}
                            updateFollowing={this.updateFollowing}
                          />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path="/memes/:category"
                      render={(props) => (
                        <>
                          <SearchBar
                            filteredMemes={this.state.filteredMemes}
                            categories={this.state.categories}
                            handleMessages={this.handleMessages}
                            updateMemes={this.updateMemes}
                          />
                          <DashCategory
                            {...props}
                            user={this.state.user}
                            filteredMemes={this.state.filteredMemes}
                            categories={this.state.categories}
                            updateSuscribers={this.updateSuscribers}
                            updateUserSaves={this.updateUserSaves}
                            handleMessages={this.handleMessages}
                            updateMemes={this.updateMemes}
                            updateUserMemes={this.updateUserMemes}
                            setShowMyMemes={this.setShowMyMemes}
                            showMyMemes={this.state.showMyMemes}
                             
                            updateFilteredMemes={this.updateFilteredMemes}
                            handleLogout={this.handleLogout}
                            setToggleNav={this.setToggleNav}
                            deleteMeme={this.deleteMeme}
                            updateFollowing={this.updateFollowing}
                          />
                        </>
                      )}
                    />
                    <Route
                      exact
                      path="/saves"
                      render={(props) => (
                        <Saves
                          {...props}
                          user={this.state.user}
                          memes={this.state.filteredMemes}
                          updateFilteredMemes={this.updateFilteredMemes}
                          categories={this.state.categories}
                          handleLogout={this.handleLogout}
                          handleMessages={this.handleMessages}
                          setToggleNav={this.setToggleNav}
                          setShowMyMemes={this.setShowMyMemes}
                          showMyMemes={this.state.showMyMemes}
                          updateMemes={this.updateMemes}
                          updateUserMemes={this.updateUserMemes}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/meme/:meme_id"
                      render={(props) => (
                        <Memes
                          {...props}
                          location={"meme"}
                          user={this.state.user}
                          updateSuscribers={this.updateSuscribers}
                          handleMessages={this.handleMessages}
                          deleteMeme={this.deleteMeme}
                          handleLogout={this.handleLogout}
                          setToggleNav={this.setToggleNav}
                          setShowMyMemes={this.setShowMyMemes}
                          categories={this.state.categories}
                          filteredMemes={this.state.filteredMemes}
                          updateFilteredMemes={this.updateFilteredMemes}
                          history={this.props.history}
                           
                          updateSaves={this.updateUserSaves}
                          updateFollowing={this.updateFollowing}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/profile"
                      render={(props) => (
                        <Profile
                          {...props}
                          user={this.state.user}
                          handleMessages={this.handleMessages}
                          updateUser={this.updateUser}
                          getUser={this.getUser}
                          handleLogout={this.handleLogout}
                          setToggleNav={this.setToggleNav}
                          setShowMyMemes={this.setShowMyMemes}
                           
                        />
                      )}
                    />
                    {this.state.user === null && (
                      <Route
                        exact
                        path="/login"
                        render={(props) => (
                          <Login
                            {...props}
                            handleLogin={this.handleLogin}
                            handleMessages={this.handleMessages}
                          />
                        )}
                      />
                    )}
                    <Route
                      exact
                      path="/forgotPassword"
                      render={(props) => (
                        <RecoverPassword
                          {...props}
                          handleForgotPassword={this.handleForgotPassword}
                          handleMessages={this.handleMessages}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/passwordRecovery/:username/:token"
                      render={(props) => (
                        <ResetPassword
                          {...props}
                          handleResetPassword={this.handleResetPassword}
                          handleMessages={this.handleMessages}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/signup"
                      render={(props) => (
                        <Signup
                          {...props}
                          handleSignUp={this.handleSignUp}
                          handleMessages={this.handleMessages}
                        />
                      )}
                    />
                    <Route render={() => <Redirect to="/" />} />
                  </Switch>

                  <Grid item md={12}>
                    <Messages
                      show={this.state.message.show}
                      message={this.state.message.text}
                      severity={this.state.message.severity}
                      onClose={this.closeMessage}
                    ></Messages>
                  </Grid>
                </div>
              </div>
            </>
          </MediaQuery>
        </div>
      </Router>
    );
  }
}
App.contextType = ThemeContext;
export default App;
