const ForWomenIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M49.85,99.65c-27.23,0-49.41-22.11-49.32-49.31C0.63,22.13,23.56,0.69,50.4,0.94
	C77.11,1.2,99.73,23.06,99.27,51.18C98.82,78.42,76.44,99.83,49.85,99.65z M52.17,74.21c0-2.65-0.02-5.15,0.04-7.65
	c0.01-0.23,0.55-0.61,0.87-0.66c10.99-1.53,19.19-10.86,19.24-21.92c0.05-11.89-9.23-21.65-21.18-22.26
	c-16.22-0.84-27.9,15.73-21.53,30.68c3.29,7.72,9.25,12.17,17.54,13.5c0.31,0.05,0.82,0.38,0.83,0.59c0.06,2.54,0.04,5.08,0.04,7.72
	c-1.31,0-2.46,0-3.61,0c-3.38,0-4.58,0.53-4.4,2.09c0.17,1.43,0.35,2.24,4.33,2.05c1.18-0.06,2.37-0.01,3.69-0.01
	c0,0.6,0,1.07,0,1.54c0.01,1.52-0.03,3.03,0.04,4.55c0.05,1.04,0.68,1.69,1.73,1.83c1.09,0.14,1.87-0.33,2.22-1.38
	c0.13-0.4,0.13-0.86,0.13-1.3c0.01-1.7,0.01-3.41,0.01-5.23c1.98,0,3.8,0.01,5.63,0c1.5-0.01,2.39-0.79,2.39-2.07
	c0-1.27-0.9-2.05-2.41-2.06C55.98,74.2,54.19,74.21,52.17,74.21z"
      fill={color}
    />
    <path
      d="M52.17,74.21c2.02,0,3.81-0.01,5.6,0c1.51,0.01,2.4,0.79,2.41,2.06c0,1.27-0.89,2.06-2.39,2.07
	c-1.82,0.01-3.65,0-5.63,0c0,1.83,0.01,3.53-0.01,5.23c0,0.43,0,0.89-0.13,1.3c-0.35,1.05-1.13,1.52-2.22,1.38
	c-1.05-0.13-1.68-0.79-1.73-1.83c-0.07-1.51-0.04-3.03-0.04-4.55c0-0.47,0-0.94,0-1.54c-1.32,0-2.51-0.05-3.69,0.01
	c-3.98,0.2-4.16-0.62-4.33-2.05c-0.19-1.57,1.01-2.09,4.4-2.09c1.15,0,2.3,0,3.61,0c0-2.64,0.02-5.18-0.04-7.72
	c0-0.21-0.52-0.54-0.83-0.59c-8.29-1.34-14.25-5.78-17.54-13.5c-6.37-14.95,5.31-31.51,21.53-30.68
	C63.1,22.34,72.38,32.1,72.33,43.99c-0.05,11.06-8.26,20.39-19.24,21.92c-0.33,0.05-0.87,0.42-0.87,0.66
	C52.15,69.06,52.17,71.56,52.17,74.21z M32.1,43.8c0,10.03,8,18.12,17.92,18.12c10,0.01,18.1-8.01,18.13-17.94
	c0.03-9.94-8.07-18.1-17.97-18.1C40.11,25.86,32.1,33.81,32.1,43.8z"
      fill={color2}
    />
    <path
      d="M32.1,43.8c0-9.99,8.01-17.93,18.07-17.93c9.91,0.01,18,8.16,17.97,18.1c-0.02,9.93-8.12,17.95-18.13,17.94
	C40.09,61.91,32.09,53.82,32.1,43.8z"
      fill={color}
    />
  </svg>
);

export default ForWomenIcon;
