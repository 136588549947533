import { Container } from "@material-ui/core";
import Memes from "./Memes/Memes";
import React from "react";
import MediaQuery from "react-responsive";
import TopBar from "../components/TopBar/TopBar";
import MobileRightBar from "../components/MobileRightBar";
import styles from "../styles/saves.module.css";

const Saves = ({
  user,
  memes,
  updateFilteredMemes,
  handleLogout,
  handleMessages,
  setToggleNav,
  setShowMyMemes,
  showMyMemes,
  updateMemes,
  updateUserMemes,
  categories,
  history,
}) => {
  const sortMemes = (memes) => {
    return memes.sort((first, second) => {
      return new Date(second.createdAt) - new Date(first.createdAt);
    });
  };
  return (
    <div>
      <MediaQuery maxWidth={699}>
        <div className={"top-bar"}>
          <TopBar
            user={user}
            memes={memes}
            updateFilteredMemes={updateFilteredMemes}
            handleLogout={handleLogout}
            handleMessages={handleMessages}
            setToggleNav={setToggleNav}
            setShowMyMemes={setShowMyMemes}
          />
        </div>
      </MediaQuery>
      <div className={styles.pageTitle}>
        <h1>Guardados</h1>
      </div>
      <div className={styles.saves}>
        {user && user.saved_memes.length > 0
          ? sortMemes(user.saved_memes).map((meme) => {
              return (
                <Memes
                  key={meme.id}
                  location={"saves"}
                  meme={meme}
                  history={history}
                />
              );
            })
          : "No se ha guardado ningún meme"}
      </div>
      <MediaQuery maxWidth={699}>
        <Container
          xs={6}
          direction="column"
          className={showMyMemes ? styles.myMemes : styles.hide}
        >
          <MobileRightBar
            user={user}
            setShowMyMemes={setShowMyMemes}
            showMyMemes={showMyMemes}
            updateMemes={updateMemes}
            updateUserMemes={updateUserMemes}
            categories={categories}
          />
        </Container>
      </MediaQuery>
    </div>
  );
};

export default Saves;
