import React from "react";
import { Button } from "@material-ui/core";
import styles from "./memeUserInfoFollow.module.css";

const MemeUserInfoFollow: React.FC<{
  isFollowed: boolean;
  handleFollow: () => void;
}> = ({ isFollowed, handleFollow }) => {
  return (
    <Button
      className={isFollowed ? styles.followBtnSelected : styles.followBtn}
      onClick={handleFollow}
    >
      {isFollowed ? "Seguido" : "Seguir"}
    </Button>
  );
};

export default MemeUserInfoFollow;
