import Comment from "../Comment";
import Commenter from "../Commenter";
import axios from "axios";
import React from "react";
import { FixMeLater } from "../../models/Temp";
import { CommentEntity, UserEntity } from "../../models/Entities";
import { deleteComment } from "../../services/CommentsService";
import styles from "./memeComments.module.css";
import MemeMessageOptions from "../MemeMessageOptions";
import MediaQuery from "react-responsive";
import MemeOptions from "../MemeOptions";
const MemeComments: React.FC<{
  user: UserEntity;
  meme: FixMeLater;
  currentUser: UserEntity;
  location: FixMeLater;
  updateSaves: FixMeLater;
  updateSuscribers: FixMeLater;
  handleMessages: FixMeLater;
  isSaved: boolean;
  isLiked: boolean;
  isDisliked: boolean;
  isFollowed: boolean;
  handleSave: () => {};
  handleLike: () => {};
  handleDislike: () => {};
  handleFollow: () => void;
}> = ({
  user,
  meme,
  currentUser,
  location,
  updateSaves,
  handleMessages,
  updateSuscribers,
  isFollowed,
  isSaved,
  isLiked,
  isDisliked,
  handleSave,
  handleLike,
  handleDislike,
  handleFollow,
}) => {
  const [comments, setComments] = React.useState<CommentEntity[]>([]);
  const [commentCount, setCommentCount] = React.useState(0);

  React.useEffect(() => {
    if (meme) {
      setComments(orderArray(meme.comments));
      setCommentCount(meme.comments.length);
    }
  }, [meme]);

  const orderArray = (array: []) => {
    return array.sort(
      (first: { created_at: string }, second: { created_at: string }) => {
        return (
          Number(new Date(second.created_at)) -
          Number(new Date(first.created_at))
        );
      }
    );
  };

  const updateComments = async () => {
    try {
      const response = await axios.get("/api/meme/comments", {
        params: { meme_id: meme.id },
      });
      if (response.data.success) {
        setComments(orderArray(response.data.comments));
        setCommentCount(response.data.comments.length);
      } else {
        handleMessages("error", response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommentDelete = (commentId: number) => {
    // Delete commnet from UI
    setComments(
      comments.filter((comment: CommentEntity) => comment.id !== commentId)
    );
    // Delete comments from Database
    deleteComment(commentId);
    // Update comment count
    setCommentCount(commentCount - 1);
  };

  return (
    <>
      {location === "meme" && (
        <>
          <MediaQuery minWidth={700}>
            <MemeMessageOptions
              meme={meme}
              location={location}
              handleMessages={handleMessages}
              commentCount={commentCount}
            />
          </MediaQuery>
          <div className={styles.containerMeme}>
            {location === "meme" && (
              <>
                <MediaQuery maxWidth={699}>
                  <MemeMessageOptions
                    meme={meme}
                    handleMessages={handleMessages}
                    commentCount={commentCount}
                    location={location}
                  />
                </MediaQuery>
                <Commenter
                  meme={meme}
                  currentUser={currentUser}
                  updateComments={updateComments}
                  handleMessages={handleMessages}
                  location={location}
                />
              </>
            )}
            {comments.length > 0 &&
              comments.map((comment: CommentEntity) => {
                return (
                  <Comment
                    key={comment.id}
                    user={currentUser}
                    comment={comment}
                    deleteComment={handleCommentDelete}
                    handleMessages={handleMessages}
                  />
                );
              })}
          </div>
        </>
      )}
      {location === "dashboard" && (
        <>
          <MemeOptions
            meme={meme}
            user={user}
            currentUser={user}
            location={location}
            updateSaves={updateSaves}
            handleMessages={handleMessages}
            updateSuscribers={updateSuscribers}
            isSaved={isSaved}
            isLiked={isLiked}
            isDisliked={isDisliked}
            handleSave={handleSave}
            handleLike={handleLike}
            handleDislike={handleDislike}
            isFollowed={isFollowed}
            handleFollow={handleFollow}
            commentCount={commentCount}
          />
          <div className={styles.container}>
            {comments.length > 0 ? (
              <Comment
                key={comments[0].id}
                user={currentUser}
                comment={comments[0]}
                deleteComment={handleCommentDelete}
                handleMessages={handleMessages}
              />
            ) : (
              comments.map((comment: CommentEntity) => {
                return (
                  <Comment
                    key={comment.id}
                    user={currentUser}
                    comment={comment}
                    deleteComment={handleCommentDelete}
                    handleMessages={handleMessages}
                  />
                );
              })
            )}
            <Commenter
              meme={meme}
              currentUser={currentUser}
              updateComments={updateComments}
              handleMessages={handleMessages}
              location={location}
            />
          </div>
        </>
      )}
    </>
  );
};
export default MemeComments;
