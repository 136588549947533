import { useTheme } from "@material-ui/core/styles";
import {
  Container,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@material-ui/core";
import { useState } from "react";
import axios from "axios";
import styles from "../styles/mymemes.module.css";
import Memes from "../pages/Memes/Memes";

const MyMemes = ({
  user,
  updateMemes,
  categories,
  updateUserMemes,
  history,
}) => {
  const [open, setOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [categories_selected, setCategories_selected] = useState([]);

  const handleAddMeme = () => {
    setOpen(true);
  };

  const [isDisabled, setIsDisabled] = useState(false);
  const handleAddMemeClick = () => {
  };

  const handleClose = () => {
    document.getElementById("description").value = null;
    setCategories_selected([]);
    setImagePreview(null);
    setOpen(false);
  };

  const handleSelectClose = () => {
    setOpenSelect(false);
  };

  const handleSelectOpen = () => {
    setOpenSelect(true);
  };

  const handleChange = (event) => {
    setCategories_selected(event.target.value);
    handleSelectClose();
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsDisabled(true);
    console.log("Button Add Meme Clicked")
    var fd = new FormData(document.getElementById("meme-form"));
    fd.append("email", user.email);
    const config = {
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    };
    setTimeout(() => {
      setIsDisabled(false);
      console.log("Button Add Meme Restored")
    },10000)
    let response;
    try {
      await axios.post(`/api/meme/`, fd, config);
      response = await axios.get(`/api/meme/`);
      if (response.data.success) {
        setOpen(false);
        updateMemes(response.data.memes);
        updateUserMemes();
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  function handleImageChange(event) {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const sortMemes = (memes) => {
    return memes.sort((first, second) => {
      return new Date(second.created_at) - new Date(first.created_at);
    });
  };

  return (
    <Container>
      <Grid item xs={12} className={styles.addMeme}>
        <Button onClick={() => handleAddMeme()} className={"btn-primary"}>
          Agregar meme
        </Button>
      </Grid>
      <Grid item xs={12}>
        {user?.own_memes &&
          sortMemes(user.own_memes).map((meme) => {
            return (
              <Memes
                key={meme.id}
                location="userMemes"
                user={user}
                meme={meme}
                history={history}
              />
            );
          })}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {categories && (
          <form
            id="meme-form"
            encType="multipart/form-data"
            onSubmit={handleUpload}
          >
            <DialogTitle>Agregar meme</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                name="description"
                label="Description"
                multiline
                fullWidth
                required
              />
              <InputLabel htmlFor="categories">Categorias</InputLabel>
              <Select
                multiple
                required
                fullWidth
                value={categories_selected}
                onChange={handleChange}
                open={openSelect}
                onOpen={handleSelectOpen}
                id="categories"
                label="Categorias"
                name="categories"
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>

              <div className={styles.imgPreview}>
                {imagePreview === null && "Selecionar picture para visualizar"}
                {imagePreview !== null && (
                  <img
                    className={styles.image}
                    src={imagePreview}
                    alt="preview"
                  />
                )}
              </div>
              <input
                accept="image/*"
                id="image"
                name="image"
                type="file"
                onChange={(event) => handleImageChange(event)}
                multiple
                required
                className={styles.imageAdd}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button 
                  type="submit"  
                  color="primary"  
                  disabled={isDisabled} 
                  style={isDisabled ? styles.buttonDisabled : styles.button}>
                Agregar
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </Container>
  );
};

export default MyMemes;