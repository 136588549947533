import { InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import styles from "./searchbar.module.css";
import MediaQuery from "react-responsive";
import { useState, useEffect } from "react";
import axios from "axios";

const SearchBar = ({
  filteredMemes,
  categories,
  handleMessages,
  updateMemes,
}) => {
  const [allMemes, setAllMemes] = useState([]);
  const [value, setValue] = useState("");
  const { search } = window.location;
  const query = new URLSearchParams(search).get('search'); 
  const [searchQuery, setSearchQuery] = useState(query || '');    
  const filterMemes = (allMemes, word) => {
    if (!word) {
        return allMemes;
    }
    const memesByDescription = allMemes.filter((meme) =>
        meme.description.toLowerCase().includes(word.toLowerCase()))
        .map(filteredMeme => (filteredMeme)
      );
    
      updateMemes([...memesByDescription]);
      return memesByDescription;
  };

  useEffect(() => {
    getMemes();
  }, []);
  const getMemes = () => {
    try {
      axios
        .get("/api/meme")
        .then((response) => setAllMemes(response.data.memes));
    } catch (error) {
      console.log("Error de conexion con el API");
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (value.length > 0) {
      if (isValidCategory(value)) {
        const memesByCategory = getMemesByCategory(allMemes, value);
      } else {
        handleMessages("warning", "No se encontraron memes con esa descripción");
      }
    }
  };
  const isValidCategory = (category) => {
    let isValid = false;
    categories.forEach((cat) => {
      if (cat.name.toLowerCase() === category.toLowerCase()) isValid = true;
    });
    return isValid;
  };
  const getMemesByCategory = (allMemes, category) => {
    return allMemes.filter((meme) =>
      meme.categories
        .map((cat) => cat.name.toLowerCase())
        .includes(category.toLowerCase())
    );
  };
  const handleChange = ({ target: { value: searchValue } }) => {
    
    if (searchValue === "") {
      axios({
        url: `/api/meme/some`,
        params: {
          page: 0,
          size: 10,
        },
      })
        .then((response) => {
          //updateFilteredMemes(response.data.memes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setValue(searchValue);
  };

  const handleChange1 = (e) => {
    const word = e.target.value;
    filteredMemes = filterMemes(allMemes, word);
  };

  return (
    <>
      <MediaQuery minWidth={700}>
        <div className={styles.searchBar_container}>
            <InputBase
              name={"search"}
              className={styles.search}
              placeholder="Buscar"
              onInput={(e) => handleChange1(e)}
              startAdornment={
                <SearchIcon
                  fontSize="large"
                  style={{ color: "red", marginRight: 10 }}
                 />
              }
            />
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={699}>
        <div className={styles.mobileContainer}>
            <InputBase
              id={"searchBarInput"}
              className={styles.mobileSearch}
              placeholder="Buscar"
              onInput={(e) => handleChange1(e)}
              startAdornment={
                <SearchIcon
                  fontSize="large"
                  style={{ color: "red", marginRight: 10 }}
                />
              }
            /> 
        </div>
      </MediaQuery>
    </>
  );
};
export default SearchBar;
