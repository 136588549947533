import styles from "./topbar.module.css";
import { IconButton } from "@material-ui/core";
import UserOptions from "../UserOptions/UserOptions";
import MediaQuery from "react-responsive";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/icons/logo.svg";
import { Link } from "react-router-dom";

const TopBar = ({
  user,
  handleLogout,
  setToggleNav,
  setShowMyMemes,
  handleMessages,
  history,
}) => {
  return (
    <>
      <MediaQuery minWidth={700}>
        <div className={styles.topbar}>
          <UserOptions
            user={user}
            handleLogout={handleLogout}
            handleMessages={handleMessages}
            history={history}
          />
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={699}>
        <div className={styles.mobileContainer}>
          <div className={styles.logoContainer} >
            <Link to="/" className={styles.mobileLogo}>
              <img src={logo} alt="logo"/>
            </Link>
          </div>
          <div className={styles.userOptions}>
            <UserOptions
              user={user}
              handleLogout={handleLogout}
              setShowMyMemes={setShowMyMemes}
              handleMessages={handleMessages}
              history={history}
            />
            <IconButton
              onClick={() => {
                setToggleNav();
              }}
              className={styles.menuIcon}
              color="primary"
              aria-label="login button"
            >
              <MenuIcon fontSize="large" htmlColor="#808080" />
            </IconButton>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default TopBar;
