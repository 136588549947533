const AcademicIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox={"0 0 460 460"}
  >
    <path
      d="M217.19,10.38c114.2,0.01,206.16,92.02,206.07,206.21c-0.1,113.97-91.85,203.27-200.99,205.91
	c-116.51,2.82-212.02-91.62-211.4-207.6C11.46,102.11,103.54,10.37,217.19,10.38z M227.46,344.43c8.18,3.13,15.95,6.52,23.99,9.06
	c5.94,1.87,10.18-0.63,11.51-5.58c1.38-5.13-1.6-8.53-7.32-11.06c-9.06-4-17.75-8.84-26.49-13.52c-1.28-0.68-2.72-2.4-2.81-3.73
	c-0.33-4.9-0.13-9.83-0.13-14.61c7.89-0.91,15.02-1.47,22.06-2.58c16.05-2.53,31.64-6.54,45.12-16.22
	c8.64-6.2,13.38-14.24,13.16-25.38c-0.39-19.48-0.02-38.98-0.19-58.47c-0.03-3.76,0.82-5.92,4.49-7.65
	c12.18-5.74,24.11-12.01,36.21-17.93c3.81-1.86,7.09-4.08,7.17-8.69c0.09-4.82-3.2-7.2-7.19-9.19
	c-40.93-20.41-81.84-40.87-122.67-61.47c-4.92-2.48-9.19-2.52-14.13-0.03c-40.97,20.66-82.02,41.18-123.08,61.66
	c-3.84,1.92-6.89,4.32-6.87,8.89c0.02,4.61,3.23,6.88,7.06,8.75c12.25,6,24.34,12.31,36.64,18.2c3.36,1.61,4.52,3.53,4.48,7.21
	c-0.17,19.49,0.13,38.98-0.16,58.47c-0.16,10.6,4.11,18.6,12.27,24.9c9.42,7.27,20.3,11.49,31.72,13.94
	c11.82,2.54,23.86,4.1,36.21,6.15c0,6.17,0.33,12.97-0.06,19.72c-1.03,17.69,3.02,33.69,14.94,47.21c1.18,1.34,2.63,2.68,4.24,3.35
	c3.94,1.63,7.71,0.89,10.27-2.61c2.66-3.64,2.44-7.55-0.6-10.99C232.6,356.97,228.8,351.26,227.46,344.43z"
      fill={color}
    />
    <path
      d="M227.46,344.43c1.34,6.83,5.14,12.53,9.8,17.82c3.04,3.44,3.26,7.35,0.6,10.99c-2.56,3.51-6.33,4.24-10.27,2.61
	c-1.61-0.67-3.06-2.01-4.24-3.35c-11.92-13.52-15.97-29.52-14.94-47.21c0.39-6.76,0.06-13.55,0.06-19.72
	c-12.35-2.05-24.39-3.61-36.21-6.15c-11.42-2.46-22.3-6.67-31.72-13.94c-8.16-6.29-12.43-14.3-12.27-24.9
	c0.29-19.49-0.01-38.98,0.16-58.47c0.03-3.68-1.12-5.61-4.48-7.21c-12.3-5.89-24.39-12.2-36.64-18.2c-3.83-1.87-7.03-4.14-7.06-8.75
	c-0.02-4.57,3.03-6.97,6.87-8.89c41.06-20.49,82.11-41,123.08-61.66c4.94-2.49,9.21-2.46,14.13,0.03
	c40.84,20.6,81.74,41.06,122.67,61.47c3.99,1.99,7.28,4.37,7.19,9.19c-0.08,4.61-3.36,6.83-7.17,8.69
	c-12.1,5.92-24.03,12.19-36.21,17.93c-3.67,1.73-4.52,3.89-4.49,7.65c0.18,19.49-0.2,38.99,0.19,58.47
	c0.23,11.14-4.52,19.18-13.16,25.38c-13.48,9.68-29.07,13.69-45.12,16.22c-7.04,1.11-14.17,1.68-22.06,2.58
	c0,4.78-0.2,9.72,0.13,14.61c0.09,1.33,1.53,3.04,2.81,3.73c8.74,4.68,17.44,9.52,26.49,13.52c5.73,2.53,8.7,5.92,7.32,11.06
	c-1.33,4.94-5.57,7.45-11.51,5.58C243.41,350.96,235.64,347.57,227.46,344.43z M226.16,217.92c33.67-16.83,66.46-33.23,99.76-49.87
	c-1.35-0.86-1.88-1.26-2.47-1.56c-34.51-17.27-69-34.56-103.58-51.67c-1.55-0.77-4.21-0.5-5.84,0.3
	c-33.98,16.83-67.88,33.81-101.79,50.77c-1.07,0.53-2.03,1.28-3.5,2.23c33.44,16.7,66.31,33.12,100.07,49.99
	c0-12.03,0.08-22.66-0.11-33.3c-0.02-1.19-1.59-2.3-2.28-3.54c-2.13-3.83-5.24-7.5-6.03-11.6c-1.52-7.85,3.73-15.35,11.2-17.95
	c7.27-2.53,15.66,0.26,19.89,6.61c4.56,6.85,3.75,15.54-1.91,21.61c-1.29,1.39-3.22,3.03-3.26,4.6
	C226.02,195.31,226.16,206.09,226.16,217.92z M208.55,287.43c0.11-2.14,0.27-3.77,0.27-5.39c0.02-13.31-0.1-26.62,0.09-39.92
	c0.05-3.5-1.1-5.3-4.26-6.85c-18.05-8.84-35.98-17.92-53.96-26.9c-1.38-0.69-2.86-1.2-4.79-2c-0.1,2.34-0.24,3.97-0.24,5.61
	c-0.01,15.8,0.26,31.61-0.12,47.41c-0.16,6.54,2.53,10.79,7.7,13.95C170.11,283.63,188.95,285.99,208.55,287.43z M289.2,205.99
	c-1.67,0.69-2.6,1.01-3.46,1.44c-18.9,9.41-37.83,18.75-56.61,28.39c-1.53,0.78-2.83,3.56-2.87,5.43
	c-0.24,13.82-0.15,27.64-0.12,41.46c0,1.43,0.26,2.86,0.46,4.95c7.04-0.84,13.65-1.38,20.17-2.47c12.68-2.11,25.23-4.98,35.84-12.71
	c2.83-2.07,6.2-5.78,6.29-8.84C289.48,244.69,289.2,225.72,289.2,205.99z"
      fill={color2}
    />
    <path
      d="M226.16,217.92c0-11.83-0.14-22.61,0.15-33.37c0.04-1.57,1.97-3.21,3.26-4.6c5.66-6.07,6.47-14.76,1.91-21.61
	c-4.23-6.35-12.62-9.14-19.89-6.61c-7.47,2.6-12.72,10.1-11.2,17.95c0.8,4.11,3.9,7.77,6.03,11.6c0.69,1.24,2.25,2.35,2.28,3.54
	c0.18,10.63,0.11,21.27,0.11,33.3c-33.76-16.87-66.63-33.29-100.07-49.99c1.48-0.95,2.44-1.7,3.5-2.23
	c33.91-16.97,67.82-33.94,101.79-50.77c1.63-0.81,4.29-1.07,5.84-0.3c34.58,17.11,69.07,34.4,103.58,51.67
	c0.59,0.29,1.12,0.7,2.47,1.56C292.62,184.69,259.83,201.09,226.16,217.92z"
      fill={color}
    />
    <path
      d="M208.55,287.43c-19.6-1.44-38.44-3.8-55.3-14.1c-5.17-3.16-7.86-7.42-7.7-13.95
	c0.38-15.79,0.11-31.6,0.12-47.41c0-1.63,0.13-3.26,0.24-5.61c1.93,0.8,3.41,1.31,4.79,2c17.98,8.98,35.91,18.06,53.96,26.9
	c3.16,1.55,4.31,3.36,4.26,6.85c-0.19,13.31-0.07,26.62-0.09,39.92C208.82,283.66,208.66,285.29,208.55,287.43z"
      fill={color}
    />
    <path
      d="M289.2,205.99c0,19.73,0.29,38.7-0.29,57.65c-0.09,3.05-3.46,6.77-6.29,8.84
	c-10.61,7.74-23.16,10.6-35.84,12.71c-6.52,1.09-13.14,1.63-20.17,2.47c-0.2-2.09-0.46-3.52-0.46-4.95
	c-0.03-13.82-0.12-27.64,0.12-41.46c0.03-1.87,1.34-4.65,2.87-5.43c18.78-9.64,37.71-18.98,56.61-28.39
	C286.6,207,287.52,206.68,289.2,205.99z"
      fill={color}
    />
  </svg>
);

export default AcademicIcon;
