export const updateTheme = (theme) => {
  const data = {
    theme,
  };
  const url = "api/people/theme";
  const method = "PUT";
  return fetchUrl({ url, method, data });
};

const fetchUrl = ({ url, method, data, headers }) => {
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: method ? method : "GET",
    body: JSON.stringify(data),
    headers: headers
      ? headers
      : {
          token: window.localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
  })
    .then((response) => response)
    .catch((error) => console.log("error:", error));
};
