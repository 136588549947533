const ScienceIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M99.32,50.3c0,27.22-22.15,49.33-49.5,49.24C23.1,99.46,0.68,78.16,0.62,50.23
	C0.57,22.36,23.19,0.45,50.67,0.83C78.02,1.2,99.45,23.54,99.32,50.3z M39.89,21.4c0.04,0.39,0.07,0.59,0.07,0.79
	c0,7.54,0.02,15.08-0.03,22.61c0,0.51-0.35,1.12-0.73,1.5c-4.52,4.5-9.15,8.9-13.6,13.47c-1.32,1.36-2.48,3.07-3.17,4.84
	C20.43,69.7,22.81,75,27.76,76.69c1.4,0.48,2.96,0.7,4.44,0.71c11.89,0.06,23.77,0.03,35.66,0.03c0.56,0,1.12-0.02,1.67-0.07
	c5.87-0.47,9.57-5.25,8.58-11.06c-0.47-2.76-1.94-4.93-3.88-6.84c-4.44-4.36-8.86-8.73-13.33-13.06c-0.64-0.62-0.86-1.23-0.86-2.08
	c0.03-6.9,0.02-13.79,0.02-20.69c0-2.09,0-2.09,2.07-2.23c1.16-0.08,1.98-0.87,2.04-1.95c0.05-0.99-0.7-1.91-1.75-2.11
	c-0.39-0.07-0.79-0.06-1.19-0.06c-7.5,0-15,0-22.5,0c-0.36,0-0.72-0.01-1.07,0.05c-1.11,0.2-1.85,1.16-1.76,2.22
	c0.09,1.03,0.89,1.78,2,1.85C38.53,21.43,39.17,21.4,39.89,21.4z"
      fill={color}
    />
    <path
      d="M39.89,21.4c-0.73,0-1.36,0.03-1.99-0.01c-1.11-0.06-1.91-0.82-2-1.85c-0.1-1.06,0.65-2.02,1.76-2.22
	c0.35-0.06,0.71-0.05,1.07-0.05c7.5,0,15,0,22.5,0c0.4,0,0.8-0.01,1.19,0.06c1.05,0.2,1.8,1.12,1.75,2.11
	c-0.05,1.08-0.88,1.87-2.04,1.95c-2.07,0.14-2.07,0.14-2.07,2.23c0,6.9,0.01,13.79-0.02,20.69c0,0.86,0.22,1.46,0.86,2.08
	c4.47,4.33,8.9,8.7,13.33,13.06c1.94,1.91,3.41,4.08,3.88,6.84c0.99,5.81-2.71,10.59-8.58,11.06c-0.56,0.04-1.11,0.07-1.67,0.07
	c-11.89,0-23.77,0.03-35.66-0.03c-1.49-0.01-3.04-0.23-4.44-0.71c-4.95-1.69-7.34-6.99-5.32-12.08c0.7-1.76,1.85-3.47,3.17-4.84
	c4.44-4.57,9.07-8.97,13.6-13.47c0.38-0.38,0.73-0.99,0.73-1.5c0.05-7.54,0.03-15.08,0.03-22.61C39.96,21.99,39.93,21.79,39.89,21.4
	z M50.04,73.3c3.91,0,7.81,0.01,11.72,0c2.47-0.01,4.95,0.09,7.41-0.09c3.89-0.28,5.98-3.5,4.56-7.13
	c-0.56-1.43-1.74-2.62-2.64-3.91c-0.43-0.62-0.92-0.69-1.62-0.32c-4.87,2.57-9.95,2.96-15.14,1.21c-3.35-1.13-6.62-2.5-9.95-3.7
	c-5.1-1.84-9.9-1.08-14.33,1.92c-1.72,1.17-3.1,2.76-3.8,4.8c-1.18,3.45,0.5,6.39,4.07,7.08c0.7,0.14,1.43,0.14,2.14,0.14
	C38.32,73.3,44.18,73.3,50.04,73.3z M67.18,58.35c-0.34-0.36-0.6-0.65-0.87-0.92c-3.11-3.04-6.21-6.09-9.35-9.09
	c-0.76-0.72-1.08-1.51-1.04-2.54c0.05-1.19-0.05-2.4,0.03-3.59c0.07-0.99-0.33-1.26-1.24-1.19c-0.87,0.06-1.75,0.02-2.63,0.01
	c-1.49-0.02-2.32-0.7-2.36-1.95c-0.04-1.29,0.81-2.12,2.31-2.17c1.04-0.04,2.08,0.03,3.11-0.04c0.28-0.02,0.75-0.38,0.76-0.6
	c0.07-1.5,0.04-3.01,0.04-4.65c-1.44,0-2.75,0.04-4.05-0.01c-1.38-0.06-2.21-0.94-2.16-2.2c0.05-1.2,0.88-1.9,2.27-1.91
	c1.27-0.01,2.53,0,3.95,0c0-1.7-0.04-3.28,0.02-4.87c0.04-0.91-0.21-1.26-1.19-1.24c-3.19,0.06-6.38,0.02-9.58,0.03
	c-0.34,0-0.69,0.06-1.08,0.09c0,0.58,0,1.06,0,1.54c0,7.62-0.01,15.24,0.02,22.86c0,0.99-0.25,1.76-0.98,2.44
	c-1.58,1.46-3.11,2.98-4.66,4.48c-0.44,0.42-0.85,0.87-1.5,1.55c5.1-0.51,9.36,1.18,13.62,2.89c2.03,0.82,4.08,1.6,6.18,2.2
	C60.32,60.46,63.78,60.07,67.18,58.35z"
      fill={color2}
    />
    <path
      d="M50.04,73.3c-5.86,0-11.72,0-17.58-0.01c-0.72,0-1.45,0-2.14-0.14c-3.57-0.69-5.25-3.62-4.07-7.08
	c0.7-2.04,2.07-3.62,3.8-4.8c4.43-3.01,9.23-3.76,14.33-1.92c3.33,1.2,6.6,2.57,9.95,3.7c5.19,1.75,10.28,1.37,15.14-1.21
	c0.7-0.37,1.19-0.3,1.62,0.32c0.9,1.29,2.08,2.48,2.64,3.91c1.42,3.63-0.67,6.85-4.56,7.13c-2.46,0.18-4.94,0.08-7.41,0.09
	C57.86,73.31,53.95,73.3,50.04,73.3z"
      fill={color}
    />
    <path
      d="M67.18,58.35c-3.41,1.72-6.86,2.11-10.41,1.1c-2.1-0.6-4.15-1.38-6.18-2.2c-4.26-1.71-8.52-3.4-13.62-2.89
	c0.66-0.68,1.07-1.13,1.5-1.55c1.54-1.5,3.08-3.02,4.66-4.48c0.74-0.68,0.99-1.45,0.98-2.44c-0.03-7.62-0.02-15.24-0.02-22.86
	c0-0.48,0-0.95,0-1.54c0.4-0.03,0.74-0.09,1.08-0.09c3.19-0.01,6.38,0.03,9.58-0.03c0.99-0.02,1.23,0.33,1.19,1.24
	c-0.06,1.58-0.02,3.17-0.02,4.87c-1.42,0-2.68-0.01-3.95,0c-1.39,0.01-2.21,0.71-2.27,1.91c-0.06,1.25,0.77,2.14,2.16,2.2
	c1.3,0.05,2.61,0.01,4.05,0.01c0,1.64,0.03,3.15-0.04,4.65c-0.01,0.22-0.48,0.58-0.76,0.6c-1.03,0.07-2.07,0.01-3.11,0.04
	c-1.49,0.05-2.35,0.88-2.31,2.17c0.04,1.24,0.87,1.93,2.36,1.95c0.88,0.01,1.76,0.05,2.63-0.01c0.91-0.07,1.31,0.2,1.24,1.19
	c-0.08,1.19,0.02,2.39-0.03,3.59c-0.04,1.03,0.29,1.82,1.04,2.54c3.14,3.01,6.24,6.06,9.35,9.09C66.59,57.71,66.85,58,67.18,58.35z"
      fill={color}
    />
  </svg>
);

export default ScienceIcon;
