const PoliticsIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M99.34,50.22c0,27.27-22.13,49.38-49.43,49.37C22.69,99.59,0.5,77.4,0.59,50.11C0.69,22.4,23.15,0.5,50.56,0.82
	C77.64,1.14,99.33,23.13,99.34,50.22z M73.62,38.18c0.49,0,0.92,0,1.34,0c1.6-0.01,3.19,0,4.79-0.03c0.94-0.02,1.61-0.52,1.87-1.41
	c0.27-0.92,0.02-1.74-0.79-2.33c-0.23-0.16-0.46-0.31-0.7-0.46C70.75,27.96,61.36,22,52.01,15.98c-1.32-0.85-2.4-0.89-3.72-0.03
	c-6.43,4.16-12.9,8.25-19.35,12.37c-3.06,1.95-6.12,3.9-9.18,5.85c-0.92,0.59-1.47,1.35-1.15,2.5c0.33,1.15,1.19,1.52,2.29,1.51
	c0.6,0,1.2,0,1.8,0c1.27,0,2.53,0,3.8,0c0.06,0.36,0.11,0.55,0.11,0.75c0.01,7.42-0.01,14.85,0.02,22.27c0,0.86-0.46,0.93-1.1,1.04
	c-4.36,0.76-7.02,5.12-5.73,9.36c1.02,3.34,3.83,5.25,7.81,5.25c14.96,0.01,29.93,0,44.89,0c0.6,0,1.19-0.05,1.79-0.12
	c3.63-0.42,6.39-3.43,6.46-7c0.07-3.85-2.45-6.91-6.16-7.51c-0.34-0.05-0.67-0.16-0.97-0.24C73.62,54.02,73.62,46.17,73.62,38.18z"
      fill={color}
    />
    <path
      d="M73.62,38.18c0,8,0,15.84,0,23.8c0.3,0.07,0.63,0.18,0.97,0.24c3.71,0.6,6.22,3.66,6.16,7.51
	c-0.06,3.57-2.83,6.58-6.46,7c-0.59,0.07-1.19,0.12-1.79,0.12c-14.96,0.01-29.93,0.01-44.89,0c-3.98,0-6.79-1.91-7.81-5.25
	c-1.29-4.24,1.37-8.6,5.73-9.36c0.64-0.11,1.1-0.19,1.1-1.04c-0.03-7.42-0.02-14.85-0.02-22.27c0-0.19-0.06-0.39-0.11-0.75
	c-1.27,0-2.53,0-3.8,0c-0.6,0-1.2,0-1.8,0c-1.1,0.01-1.97-0.36-2.29-1.51c-0.33-1.14,0.22-1.91,1.15-2.5
	c3.06-1.95,6.12-3.9,9.18-5.85c6.45-4.12,12.92-8.21,19.35-12.37c1.33-0.86,2.4-0.82,3.72,0.03C61.36,22,70.75,27.96,80.13,33.94
	c0.24,0.15,0.48,0.29,0.7,0.46c0.81,0.59,1.06,1.41,0.79,2.33c-0.26,0.89-0.92,1.39-1.87,1.41c-1.6,0.03-3.19,0.02-4.79,0.03
	C74.54,38.18,74.11,38.18,73.62,38.18z M50.05,66.31c-7.42,0-14.84,0-22.26,0c-0.4,0-0.8-0.02-1.2,0.01
	c-1.48,0.1-2.66,1.16-2.88,2.58c-0.24,1.57,0.57,3.04,2.03,3.52c0.52,0.17,1.1,0.18,1.65,0.18c15.12,0.01,30.23,0.01,45.35,0.01
	c0.32,0,0.64,0.01,0.96-0.03c1.32-0.13,2.22-0.83,2.68-2.06c0.8-2.15-0.76-4.19-3.24-4.2C65.44,66.3,57.75,66.31,50.05,66.31z
	 M72.32,34.01c-0.33-0.28-0.43-0.4-0.55-0.48c-6.99-4.46-13.98-8.9-20.95-13.38c-0.66-0.42-1.07-0.19-1.6,0.15
	c-6.81,4.36-13.64,8.7-20.45,13.05c-0.24,0.16-0.46,0.36-0.83,0.66C42.78,34.01,57.4,34.01,72.32,34.01z M54.27,38.18
	c-2.45,0-4.83,0.05-7.21-0.02c-1.04-0.03-1.25,0.36-1.24,1.31c0.03,7.1,0.02,14.2,0.02,21.3c0,0.39,0.03,0.78,0.05,1.19
	c2.86,0,5.62,0,8.38,0C54.27,54,54.27,46.16,54.27,38.18z M68.16,38.18c-2.48,0-4.83,0.04-7.17-0.02c-0.98-0.03-1.29,0.26-1.29,1.27
	c0.04,6.62,0.02,13.24,0.02,19.85c0,2.79,0,2.79,2.83,2.79c1.86,0,3.71,0,5.61,0C68.16,54.03,68.16,46.19,68.16,38.18z M32.16,38.26
	c0,7.96,0,15.83,0,23.72c2.79,0,5.48,0,8.2,0c0-7.96,0-15.83,0-23.72C37.57,38.26,34.88,38.26,32.16,38.26z"
      fill={color2}
    />
    <path
      d="M50.05,66.31c7.7,0,15.4-0.01,23.09,0.01c2.49,0.01,4.05,2.05,3.24,4.2c-0.46,1.23-1.36,1.93-2.68,2.06
	c-0.32,0.03-0.64,0.03-0.96,0.03c-15.12,0-30.23,0-45.35-0.01c-0.55,0-1.13-0.01-1.65-0.18c-1.46-0.48-2.27-1.95-2.03-3.52
	c0.22-1.41,1.39-2.48,2.88-2.58c0.4-0.03,0.8-0.01,1.2-0.01C35.21,66.31,42.63,66.31,50.05,66.31z"
      fill={color}
    />
    <path
      d="M72.32,34.01c-14.91,0-29.54,0-44.39,0c0.38-0.3,0.59-0.5,0.83-0.66c6.82-4.35,13.64-8.69,20.45-13.05
	c0.53-0.34,0.94-0.57,1.6-0.15c6.97,4.48,13.96,8.93,20.95,13.38C71.88,33.61,71.99,33.72,72.32,34.01z M53.98,27.87
	c0-2.22-1.74-3.97-3.91-3.94c-2.11,0.03-3.85,1.8-3.86,3.92c-0.01,2.16,1.82,3.98,3.96,3.94C52.3,31.77,53.98,30.04,53.98,27.87z"
      fill={color}
    />
    <path
      d="M54.27,38.18c0,7.98,0,15.82,0,23.78c-2.76,0-5.52,0-8.38,0c-0.02-0.41-0.05-0.8-0.05-1.19
	c0-7.1,0.01-14.2-0.02-21.3c0-0.95,0.2-1.34,1.24-1.31C49.44,38.23,51.83,38.18,54.27,38.18z"
      fill={color}
    />
    <path
      d="M68.16,38.18c0,8.01,0,15.85,0,23.89c-1.9,0-3.75,0-5.61,0c-2.83,0-2.83,0-2.83-2.79
	c0-6.62,0.02-13.24-0.02-19.85c-0.01-1.01,0.3-1.3,1.29-1.27C63.33,38.22,65.68,38.18,68.16,38.18z"
      fill={color}
    />
    <path
      d="M32.16,38.26c2.72,0,5.41,0,8.2,0c0,7.89,0,15.76,0,23.72c-2.72,0-5.41,0-8.2,0
	C32.16,54.09,32.16,46.21,32.16,38.26z"
      fill={color}
    />
    <path
      d="M53.98,27.87c0,2.17-1.68,3.89-3.81,3.93c-2.14,0.03-3.97-1.79-3.96-3.94c0.01-2.12,1.75-3.89,3.86-3.92
	C52.23,23.9,53.98,25.66,53.98,27.87z"
      fill={color2}
    />
  </svg>
);

export default PoliticsIcon;
