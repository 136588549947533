import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import styles from "../styles/picturesDialog.module.css";
import AddIcon from "@material-ui/icons/Add";
import { Container } from "@material-ui/core";

function PicturesDialog({ onClose, open, handleUploadPicture }) {
  const avatarPicturesPaths = [`images/sonrisa.png`, `images/mujer.png`, `images/caballero.png`];

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth={"md"}
      onClose={handleClose} open={open}>
      <DialogTitle>Elija una imagen de perfil</DialogTitle>
      <Container>
        <List className={styles.picturesGridContainer}>
          {avatarPicturesPaths.map((imagePath, index) => {
            return (
              <ListItem key={index} onClick={() => handleUploadPicture(imagePath)}>
                <Avatar
                  style={{ width: "12vw", height: "12vw" }}
                  src={`${process.env.REACT_APP_API_URL}${imagePath}`} />

              </ListItem>)

          })}
          <ListItem autoFocus button onClick={() => handleUploadPicture()}>
            <Avatar
              style={{ width: "12vw", height: "12vw" }}>
              <AddIcon />
            </Avatar>
          </ListItem>
        </List>
      </Container>

    </Dialog >
  );
}

PicturesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleUploadPicture: PropTypes.func.isRequired,
};

export default PicturesDialog;
