const MoneyIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M49.97,0.76c27.22-0.02,49.71,22.23,49.3,50.16c-0.39,26.2-21.49,48.53-49.35,48.54
	C22.38,99.48,0.6,77.39,0.54,50.09C0.48,22.81,22.66,0.76,49.97,0.76z M38.53,42.25c-1.02,0.18-1.85,0.3-2.66,0.49
	c-10.92,2.49-17.8,12.51-16.28,23.71c1.55,11.47,12.82,19.72,24.17,17.71c7.32-1.3,12.57-5.34,15.83-11.99
	c0.31-0.62,0.64-0.82,1.33-0.88c2.5-0.23,5.01-0.43,7.47-0.9c3.55-0.67,6.93-1.85,9.77-4.22c1.69-1.41,2.54-3.17,2.53-5.42
	c-0.04-10.29-0.04-20.59,0-30.88c0.01-2.2-0.71-4.09-2.49-5.29c-1.86-1.26-3.84-2.49-5.94-3.23c-7.02-2.45-14.23-2.57-21.47-1.1
	c-3.58,0.73-7.01,1.89-9.85,4.32c-1.6,1.37-2.45,3.06-2.41,5.23c0.06,3.71,0.02,7.42,0.02,11.13
	C38.53,41.35,38.53,41.78,38.53,42.25z"
      fill={color}
    />
    <path
      d="M38.53,42.25c0-0.47,0-0.9,0-1.33c0-3.71,0.05-7.42-0.02-11.13c-0.04-2.17,0.81-3.86,2.41-5.23
	c2.84-2.43,6.27-3.6,9.85-4.32c7.24-1.47,14.45-1.35,21.47,1.1c2.1,0.73,4.08,1.96,5.94,3.23c1.78,1.21,2.49,3.09,2.49,5.29
	c-0.03,10.29-0.04,20.59,0,30.88c0.01,2.26-0.84,4.01-2.53,5.42c-2.84,2.37-6.22,3.55-9.77,4.22c-2.46,0.47-4.97,0.67-7.47,0.9
	c-0.68,0.06-1.02,0.26-1.33,0.88c-3.26,6.65-8.5,10.69-15.83,11.99c-11.35,2.02-22.62-6.24-24.17-17.71
	c-1.51-11.21,5.36-21.23,16.28-23.71C36.68,42.55,37.51,42.44,38.53,42.25z M40.41,80.24c9.33,0,16.93-7.57,16.95-16.86
	c0.02-9.34-7.68-17.02-16.98-16.96c-9.4,0.06-16.88,7.62-16.88,17.05C23.51,72.66,31.15,80.25,40.41,80.24z M59.66,23.49
	c-0.02-0.1-0.04-0.2-0.05-0.31c-3.21,0.5-6.45,0.9-9.63,1.55c-2.34,0.48-4.56,1.43-6.4,3.04c-1.1,0.96-1.17,1.69,0,2.53
	c1.34,0.96,2.79,1.88,4.34,2.43c6.79,2.4,13.74,2.54,20.68,0.8c2.2-0.55,4.35-1.55,6.32-2.68c2.04-1.17,1.96-2.25,0.04-3.57
	c-0.94-0.65-1.98-1.2-3.04-1.63C68,24.01,63.87,23.54,59.66,23.49z M42.71,35.15c0,1.36-0.11,2.72,0.04,4.05
	c0.08,0.72,0.41,1.64,0.94,2.03c1.23,0.91,2.59,1.75,4.03,2.27c6.54,2.39,13.27,2.57,20.01,1.07c2.33-0.52,4.56-1.6,6.76-2.59
	c0.74-0.33,1.65-1.12,1.76-1.81c0.26-1.64,0.08-3.34,0.08-4.96C65.1,40.07,54.01,40.09,42.71,35.15z M56.83,49.57
	c1.14,2.14,2.21,4.2,3.35,6.23c0.14,0.25,0.64,0.45,0.96,0.42c1.75-0.12,3.5-0.21,5.23-0.5c3.12-0.53,6.15-1.37,8.74-3.32
	c0.54-0.4,1.16-1.05,1.23-1.64c0.17-1.56,0.06-3.16,0.06-4.92C70.15,49.18,63.55,49.84,56.83,49.57z M76.38,56.68
	c-4.78,2.59-9.78,3.5-14.95,3.77c0,2.22,0,4.35,0,6.7c1.74-0.21,3.48-0.35,5.19-0.64c2.88-0.5,5.68-1.31,8.11-3
	c0.65-0.45,1.47-1.14,1.57-1.82C76.54,60.12,76.38,58.48,76.38,56.68z"
      fill={color2}
    />
    <path
      d="M40.41,80.24c-9.26,0-16.9-7.58-16.91-16.77c-0.01-9.43,7.47-16.99,16.88-17.05c9.31-0.06,17,7.62,16.98,16.96
	C57.35,72.68,49.75,80.24,40.41,80.24z M38.75,52.13c-1,0.41-1.92,0.7-2.75,1.15c-2.16,1.19-3.36,2.97-3.3,5.52
	c0.05,2.46,1.38,3.98,3.49,4.98c0.65,0.31,1.34,0.52,1.97,0.86c0.26,0.14,0.56,0.48,0.57,0.74c0.05,1.66,0.03,3.32,0.03,5.31
	c-1.14-0.68-2.07-1.18-2.93-1.78c-1.31-0.92-2.5-0.96-3.29,0c-0.83,1.01-0.62,2.37,0.76,3.26c1.68,1.09,3.5,1.96,5.44,3.03
	c0-0.03,0,0.24,0.03,0.52c0.1,1.12,0.83,1.89,1.9,1.94c1.1,0.05,2.01-0.48,2.1-1.53c0.1-1.14,0.69-1.36,1.58-1.66
	c2.67-0.89,4.37-3.06,4.48-5.62c0.13-2.87-1.2-4.87-3.74-6.12c-0.54-0.26-1.12-0.44-1.63-0.73c-0.23-0.13-0.52-0.43-0.52-0.66
	c-0.05-1.49-0.02-2.99-0.02-4.78c0.78,0.54,1.32,0.9,1.86,1.28c1.01,0.73,2.15,0.65,2.9-0.2c0.78-0.89,0.75-2.16-0.26-2.96
	c-0.93-0.73-1.96-1.4-3.05-1.83c-0.99-0.38-1.57-0.69-1.51-1.93c0.06-1.19-0.99-1.97-2.14-1.91c-1.12,0.06-1.86,0.83-1.92,2.03
	C38.73,51.4,38.75,51.76,38.75,52.13z"
      fill={color}
    />
    <path
      d="M59.66,23.49c4.2,0.05,8.34,0.53,12.26,2.15c1.06,0.44,2.09,0.99,3.04,1.63c1.93,1.32,2,2.4-0.04,3.57
	c-1.97,1.14-4.12,2.13-6.32,2.68c-6.94,1.74-13.89,1.6-20.68-0.8c-1.54-0.55-3-1.47-4.34-2.43c-1.17-0.84-1.1-1.56,0-2.53
	c1.84-1.62,4.06-2.56,6.4-3.04c3.18-0.65,6.42-1.05,9.63-1.55C59.63,23.28,59.65,23.38,59.66,23.49z"
      fill={color}
    />
    <path
      d="M42.71,35.15c11.3,4.94,22.38,4.92,33.63,0.07c0,1.62,0.18,3.32-0.08,4.96c-0.11,0.69-1.03,1.48-1.76,1.81
	c-2.2,0.99-4.43,2.07-6.76,2.59c-6.74,1.5-13.47,1.31-20.01-1.07c-1.43-0.52-2.79-1.36-4.03-2.27c-0.54-0.39-0.86-1.31-0.94-2.03
	C42.61,37.87,42.71,36.51,42.71,35.15z"
      fill={color}
    />
    <path
      d="M56.83,49.57c6.73,0.27,13.32-0.39,19.56-3.72c0,1.76,0.11,3.35-0.06,4.92c-0.07,0.59-0.69,1.24-1.23,1.64
	c-2.58,1.94-5.62,2.78-8.74,3.32c-1.72,0.29-3.48,0.38-5.23,0.5c-0.32,0.02-0.82-0.17-0.96-0.42C59.04,53.77,57.96,51.7,56.83,49.57
	z"
      fill={color}
    />
    <path
      d="M76.38,56.68c0,1.8,0.16,3.44-0.07,5.02c-0.1,0.67-0.92,1.36-1.57,1.82c-2.44,1.68-5.23,2.5-8.11,3
	c-1.71,0.29-3.44,0.43-5.19,0.64c0-2.36,0-4.48,0-6.7C66.59,60.18,71.6,59.26,76.38,56.68z"
      fill={color}
    />
    <path
      d="M38.75,52.13c0-0.37-0.02-0.73,0-1.09c0.07-1.2,0.8-1.97,1.92-2.03c1.16-0.06,2.2,0.72,2.14,1.91
	c-0.06,1.24,0.53,1.54,1.51,1.93c1.09,0.43,2.12,1.1,3.05,1.83c1.02,0.8,1.05,2.07,0.26,2.96c-0.75,0.85-1.89,0.92-2.9,0.2
	c-0.53-0.38-1.08-0.75-1.86-1.28c0,1.79-0.02,3.29,0.02,4.78c0.01,0.23,0.29,0.53,0.52,0.66c0.52,0.29,1.1,0.46,1.63,0.73
	c2.54,1.24,3.87,3.25,3.74,6.12c-0.11,2.56-1.81,4.73-4.48,5.62c-0.89,0.3-1.48,0.52-1.58,1.66c-0.09,1.05-1,1.59-2.1,1.53
	c-1.06-0.05-1.79-0.81-1.9-1.94c-0.03-0.27-0.03-0.55-0.03-0.52c-1.94-1.07-3.76-1.94-5.44-3.03c-1.38-0.89-1.59-2.24-0.76-3.26
	c0.79-0.96,1.98-0.93,3.29,0c0.86,0.6,1.79,1.1,2.93,1.78c0-1.99,0.02-3.65-0.03-5.31c-0.01-0.26-0.31-0.6-0.57-0.74
	c-0.63-0.34-1.32-0.55-1.97-0.86c-2.1-0.99-3.43-2.52-3.49-4.98c-0.06-2.55,1.15-4.34,3.3-5.52C36.82,52.83,37.74,52.54,38.75,52.13
	z M42.94,70.53c1.08-0.4,1.73-1,1.73-2.1c-0.01-1.09-0.62-1.72-1.73-2.02C42.94,67.77,42.94,69.06,42.94,70.53z M38.68,60.35
	c0-1.32,0-2.48,0-3.72c-1.03,0.37-1.81,0.84-1.83,1.98C36.83,59.76,37.75,59.95,38.68,60.35z"
      fill={color2}
    />
    <path
      d="M42.94,70.53c0-1.47,0-2.76,0-4.12c1.1,0.31,1.72,0.93,1.73,2.02C44.67,69.53,44.01,70.13,42.94,70.53z"
      fill={color}
    />
    <path
      d="M38.68,60.35c-0.93-0.4-1.84-0.59-1.83-1.74c0.02-1.15,0.8-1.61,1.83-1.98C38.68,57.87,38.68,59.03,38.68,60.35
	z"
      fill={color}
    />
  </svg>
);

export default MoneyIcon;
