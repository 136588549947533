const FilledDislikeIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M50.6,95.5c-25.2,0-45.35-20.71-44.88-45.75c0.46-24.51,20.83-44.61,46.03-44c24.06,0.58,44.23,20.5,43.74,45.81
	C95.02,75.81,75.22,95.49,50.6,95.5z M44.59,50.56c-0.24,0.26-0.37,0.41-0.51,0.55c-4.68,4.69-9.38,9.36-14.05,14.06
	c-1.99,2-2.07,4.64-0.26,6.38c1.8,1.74,4.29,1.59,6.27-0.39c4.55-4.54,9.09-9.09,13.63-13.63c0.3-0.3,0.62-0.58,1.11-1.05
	c0.33,0.44,0.56,0.83,0.87,1.14c4.59,4.61,9.2,9.21,13.8,13.81c1.28,1.28,2.81,1.65,4.5,1.05c1.6-0.57,2.52-1.79,2.75-3.47
	c0.23-1.65-0.55-2.91-1.68-4.03c-4.5-4.48-8.98-8.97-13.47-13.46c-0.3-0.3-0.58-0.62-0.99-1.07c0.41-0.34,0.77-0.6,1.08-0.91
	c4.61-4.59,9.21-9.19,13.8-13.8c1.37-1.37,1.67-3.15,0.88-4.84c-0.75-1.59-2.46-2.72-4.18-2.35c-1.1,0.24-2.27,0.84-3.08,1.63
	c-4.36,4.22-8.62,8.54-12.9,12.84c-0.5,0.5-0.93,1.08-1.41,1.65c-0.47-0.45-0.77-0.72-1.05-1c-4.57-4.57-9.13-9.16-13.73-13.71
	c-1.95-1.93-4.55-1.98-6.29-0.22c-1.75,1.77-1.61,4.3,0.34,6.27c2.23,2.23,4.46,4.46,6.69,6.69C39.33,45.3,41.92,47.9,44.59,50.56z"
      fill={color}
    />
    <path
      d="M44.59,50.56c-2.67-2.66-5.26-5.26-7.86-7.85c-2.23-2.23-4.46-4.46-6.69-6.69c-1.96-1.96-2.09-4.49-0.34-6.27
	c1.74-1.77,4.34-1.72,6.29,0.22c4.6,4.55,9.15,9.14,13.73,13.71c0.28,0.28,0.57,0.55,1.05,1c0.49-0.57,0.91-1.15,1.41-1.65
	c4.28-4.3,8.54-8.62,12.9-12.84c0.81-0.78,1.98-1.39,3.08-1.63c1.72-0.37,3.43,0.76,4.18,2.35c0.79,1.69,0.49,3.47-0.88,4.84
	c-4.59,4.61-9.2,9.2-13.8,13.8c-0.31,0.31-0.67,0.56-1.08,0.91c0.42,0.45,0.69,0.77,0.99,1.07c4.49,4.49,8.97,8.98,13.47,13.46
	c1.12,1.12,1.91,2.37,1.68,4.03c-0.23,1.68-1.15,2.9-2.75,3.47c-1.69,0.6-3.22,0.23-4.5-1.05c-4.6-4.6-9.2-9.2-13.8-13.81
	c-0.31-0.31-0.54-0.7-0.87-1.14c-0.5,0.47-0.81,0.75-1.11,1.05c-4.55,4.54-9.09,9.09-13.63,13.63c-1.98,1.98-4.46,2.13-6.27,0.39
	c-1.8-1.74-1.72-4.38,0.26-6.38c4.67-4.7,9.37-9.37,14.05-14.06C44.22,50.97,44.35,50.82,44.59,50.56z"
      fill={color2}
    />
  </svg>
);

export default FilledDislikeIcon;
