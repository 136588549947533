const SportsIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M50.06,99.64c-27.32,0-49.32-21.95-49.34-49.21C0.71,22.82,22.68,0.92,50.44,0.99
	c27.44,0.07,49.63,22.96,48.94,50.59C98.73,77.89,77.05,99.75,50.06,99.64z M50.31,18.1c-17.68,0-32.04,14.3-32.26,31.74
	c-0.22,17.45,13.75,32.64,32.03,32.71c17.93,0.08,32.48-14.32,32.48-32C82.57,32.47,68.29,18.1,50.31,18.1z"
      fill={color}
    />
    <path
      d="M50.31,18.1c17.98,0,32.26,14.37,32.26,32.45c-0.01,17.69-14.55,32.08-32.48,32
	c-18.27-0.08-32.25-15.27-32.03-32.71C18.28,32.4,32.63,18.1,50.31,18.1z M50.28,60.35c-1.16,0-2.32,0.11-3.46-0.02
	c-1.84-0.22-3.2,0.26-4.14,1.97c-0.53,0.96-1.31,1.81-2.05,2.64c-0.49,0.55-0.54,1.06-0.34,1.73c0.97,3.32,1.93,6.64,2.86,9.97
	c0.18,0.65,0.49,0.99,1.17,1.13c3.99,0.82,7.98,0.86,11.96-0.04c0.42-0.1,0.95-0.59,1.08-1.01c1.03-3.38,1.99-6.79,2.91-10.2
	c0.11-0.42,0.02-1.03-0.23-1.38c-1.05-1.45-2.16-2.86-3.31-4.23c-0.26-0.3-0.78-0.51-1.19-0.52C53.78,60.32,52.03,60.35,50.28,60.35
	z M69.08,63.18c0-0.06,0-0.11,0-0.17c1.83,0,3.66,0.04,5.49-0.04c0.39-0.02,0.95-0.4,1.12-0.76c1.58-3.21,2.44-6.62,2.64-10.2
	c0.04-0.74-0.24-1.15-0.84-1.53c-2.73-1.72-5.41-3.51-8.15-5.2c-0.42-0.26-1.12-0.3-1.61-0.17c-1.22,0.34-2.42,0.79-3.58,1.32
	c-0.47,0.22-0.99,0.68-1.16,1.15c-1.1,3.11-2.13,6.25-3.12,9.39c-0.13,0.41-0.04,1.04,0.2,1.38c1.07,1.49,2.21,2.92,3.38,4.33
	c0.21,0.26,0.64,0.47,0.98,0.48C65.98,63.2,67.53,63.18,69.08,63.18z M31.47,63.01c0,0.06,0,0.11,0,0.17c1.55,0,3.11,0.04,4.66-0.03
	c0.37-0.01,0.82-0.27,1.06-0.56c1.12-1.35,2.22-2.72,3.22-4.15c0.28-0.4,0.37-1.11,0.24-1.58c-0.55-1.91-1.2-3.79-1.84-5.67
	c-0.5-1.47-0.65-3.27-1.63-4.29c-0.98-1.01-2.8-1.18-4.22-1.79c-0.72-0.31-1.24-0.18-1.86,0.23c-2.66,1.75-5.33,3.48-8.03,5.17
	c-0.63,0.39-0.85,0.83-0.8,1.55c0.25,3.57,1.1,6.99,2.67,10.21c0.16,0.34,0.68,0.69,1.04,0.71C27.81,63.05,29.65,63.01,31.47,63.01z
	 M48.24,33.77c-0.25-0.9,0.54-2.01-0.6-2.81c-2.74-1.92-5.41-3.93-8.15-5.86c-0.3-0.21-0.96-0.29-1.25-0.1
	c-2.27,1.45-4.5,2.97-6.74,4.48c-0.61,0.41-0.71,0.96-0.5,1.67c0.52,1.79,0.96,3.61,1.46,5.41c0.42,1.48,0.49,3.32,1.43,4.32
	c0.95,1.01,2.77,1.26,4.25,1.7c0.51,0.15,1.27,0.06,1.7-0.23c2.17-1.47,4.22-3.13,6.42-4.57C47.77,36.79,48.79,35.73,48.24,33.77z
	 M69.79,30.37c-0.12-0.16-0.26-0.54-0.54-0.73c-2.28-1.58-4.58-3.15-6.91-4.66c-0.29-0.19-0.95-0.13-1.25,0.08
	c-2.7,1.9-5.37,3.86-8.04,5.82c-0.96,0.7-0.98,5.21-0.02,5.92c2.6,1.91,5.21,3.82,7.85,5.68c0.32,0.23,0.91,0.29,1.29,0.18
	c1.4-0.44,2.78-0.96,4.15-1.5c0.33-0.13,0.73-0.44,0.82-0.74C68.04,37.15,68.88,33.88,69.79,30.37z M55.7,56.25
	c1.04-3.14,2.07-6.18,3.04-9.25c0.1-0.31-0.06-0.9-0.31-1.08c-2.51-1.89-5.06-3.74-7.62-5.55c-0.24-0.17-0.79-0.19-1.01-0.03
	c-2.6,1.84-5.17,3.71-7.73,5.62c-0.21,0.16-0.39,0.64-0.31,0.88c1,3.13,2.05,6.25,3.1,9.42c1.11,0,2.15,0,3.18,0
	C50.55,56.25,53.06,56.25,55.7,56.25z M62,75.65c3.22-0.88,9.63-6.09,10.34-8.35c-2.44,0-4.87-0.02-7.3,0.02
	c-0.27,0-0.71,0.27-0.78,0.51C63.48,70.4,62.75,72.99,62,75.65z M27.99,67.3c2.91,3.75,6.28,6.47,10.63,8.51
	c-0.82-2.88-1.53-5.43-2.3-7.97c-0.07-0.24-0.5-0.52-0.76-0.52C33.14,67.28,30.71,67.3,27.99,67.3z M56.82,23.01
	c-4.45-1.02-8.62-1.01-13.07,0.02c2.32,1.68,4.42,3.21,6.54,4.75C52.45,26.19,54.53,24.68,56.82,23.01z M22.7,45.84
	c2.19-1.41,4.24-2.7,6.25-4.06c0.24-0.16,0.36-0.74,0.29-1.07c-0.39-1.71-0.85-3.4-1.3-5.09c-0.12-0.47-0.29-0.92-0.46-1.46
	C25.2,36.9,23.02,42.23,22.7,45.84z M78.04,45.91c-0.72-4.43-2.32-8.24-4.9-11.92c-0.7,2.62-1.33,5.02-1.99,7.49
	C73.41,42.93,75.63,44.36,78.04,45.91z"
      fill={color2}
    />
    <path
      d="M50.28,60.35c1.75,0,3.51-0.03,5.26,0.02c0.41,0.01,0.94,0.22,1.19,0.52c1.16,1.37,2.27,2.78,3.31,4.23
	c0.25,0.34,0.34,0.96,0.23,1.38c-0.92,3.41-1.89,6.82-2.91,10.2c-0.13,0.42-0.65,0.92-1.08,1.01c-3.97,0.9-7.96,0.86-11.96,0.04
	c-0.68-0.14-0.99-0.49-1.17-1.13c-0.93-3.33-1.89-6.66-2.86-9.97c-0.19-0.66-0.14-1.18,0.34-1.73c0.74-0.83,1.52-1.67,2.05-2.64
	c0.94-1.71,2.3-2.18,4.14-1.97C47.95,60.46,49.12,60.35,50.28,60.35z"
      fill={color}
    />
    <path
      d="M69.08,63.18c-1.55,0-3.11,0.03-4.66-0.02c-0.34-0.01-0.76-0.22-0.98-0.48c-1.16-1.41-2.31-2.85-3.38-4.33
	c-0.24-0.34-0.33-0.97-0.2-1.38c0.99-3.15,2.02-6.28,3.12-9.39c0.17-0.47,0.69-0.93,1.16-1.15c1.15-0.53,2.36-0.97,3.58-1.32
	c0.49-0.14,1.19-0.09,1.61,0.17c2.74,1.69,5.43,3.48,8.15,5.2c0.6,0.38,0.88,0.79,0.84,1.53c-0.2,3.58-1.06,6.99-2.64,10.2
	c-0.18,0.36-0.73,0.74-1.12,0.76c-1.83,0.08-3.66,0.04-5.49,0.04C69.08,63.06,69.08,63.12,69.08,63.18z"
      fill={color}
    />
    <path
      d="M31.47,63.01c-1.83,0-3.66,0.04-5.49-0.03c-0.36-0.01-0.88-0.37-1.04-0.71c-1.57-3.22-2.42-6.63-2.67-10.21
	c-0.05-0.72,0.17-1.16,0.8-1.55c2.7-1.69,5.37-3.42,8.03-5.17c0.62-0.41,1.14-0.54,1.86-0.23c1.42,0.62,3.24,0.78,4.22,1.79
	c0.98,1.01,1.14,2.82,1.63,4.29c0.64,1.88,1.29,3.76,1.84,5.67c0.14,0.48,0.04,1.18-0.24,1.58c-1,1.44-2.1,2.8-3.22,4.15
	c-0.24,0.29-0.69,0.54-1.06,0.56c-1.55,0.06-3.11,0.03-4.66,0.03C31.47,63.12,31.47,63.06,31.47,63.01z"
      fill={color}
    />
    <path
      d="M48.24,33.77c0.55,1.96-0.47,3.02-1.96,4c-2.19,1.44-4.25,3.09-6.42,4.57c-0.43,0.29-1.19,0.38-1.7,0.23
	c-1.48-0.44-3.3-0.68-4.25-1.7c-0.94-1-1.02-2.84-1.43-4.32c-0.5-1.8-0.94-3.62-1.46-5.41c-0.2-0.71-0.11-1.25,0.5-1.67
	c2.24-1.51,4.46-3.03,6.74-4.48c0.29-0.18,0.94-0.11,1.25,0.1c2.73,1.92,5.41,3.93,8.15,5.86C48.78,31.76,47.99,32.88,48.24,33.77z"
      fill={color}
    />
    <path
      d="M69.79,30.37c-0.92,3.51-1.76,6.78-2.66,10.04c-0.08,0.31-0.49,0.61-0.82,0.74c-1.37,0.55-2.75,1.07-4.15,1.5
	c-0.38,0.12-0.97,0.05-1.29-0.18c-2.64-1.86-5.25-3.76-7.85-5.68c-0.96-0.71-0.93-5.22,0.02-5.92c2.67-1.96,5.33-3.92,8.04-5.82
	c0.3-0.21,0.96-0.27,1.25-0.08c2.33,1.51,4.63,3.08,6.91,4.66C69.53,29.83,69.68,30.21,69.79,30.37z"
      fill={color}
    />
    <path
      d="M55.7,56.25c-2.64,0-5.15,0-7.66,0c-1.03,0-2.07,0-3.18,0c-1.05-3.17-2.1-6.28-3.1-9.42
	c-0.08-0.24,0.1-0.73,0.31-0.88c2.55-1.9,5.13-3.78,7.73-5.62c0.23-0.16,0.78-0.14,1.01,0.03c2.57,1.82,5.11,3.66,7.62,5.55
	c0.25,0.19,0.41,0.77,0.31,1.08C57.77,50.06,56.74,53.11,55.7,56.25z"
      fill={color}
    />
    <path
      d="M62,75.65c0.76-2.66,1.48-5.25,2.26-7.83c0.07-0.23,0.51-0.5,0.78-0.51c2.43-0.04,4.86-0.02,7.3-0.02
	C71.62,69.56,65.22,74.77,62,75.65z"
      fill={color}
    />
    <path
      d="M27.99,67.3c2.72,0,5.14-0.02,7.57,0.02c0.27,0,0.69,0.28,0.76,0.52c0.77,2.54,1.48,5.09,2.3,7.97
	C34.28,73.77,30.91,71.05,27.99,67.3z"
      fill={color}
    />
    <path
      d="M56.82,23.01c-2.29,1.67-4.37,3.19-6.53,4.76c-2.12-1.54-4.22-3.07-6.54-4.75C48.2,22,52.37,21.99,56.82,23.01z
	"
      fill={color}
    />
    <path
      d="M22.7,45.84c0.32-3.61,2.51-8.94,4.78-11.68c0.17,0.54,0.34,1,0.46,1.46c0.45,1.69,0.91,3.38,1.3,5.09
	c0.07,0.33-0.05,0.91-0.29,1.07C26.94,43.14,24.89,44.43,22.7,45.84z"
      fill={color}
    />
    <path
      d="M78.04,45.91c-2.4-1.55-4.63-2.98-6.88-4.43c0.65-2.47,1.29-4.87,1.99-7.49
	C75.71,37.66,77.31,41.48,78.04,45.91z"
      fill={color}
    />
  </svg>
);

export default SportsIcon;
