import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Divider,
  Card,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import axios from "axios";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import GoogleIcon from "@mui/material/IconButton";

import Copyright from "../components/CopyRight";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { FacebookLogin } from 'react-facebook-login';

const colorRojo = '#ff000050';

var registerUser = false;//variable que controla si muestro o no el avisod e registro
var usuarioSocial = "";//el usuario social que se registro

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  x: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 20,
  },
  bigLink: {
    fontSize: "14px",
  },
  text: {
    color: colorRojo,
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#00000060',
    },
  },
  google: {
    margin: theme.spacing(3, 0, 2),
    background : "#fff",
    '&:hover': {
      backgroundColor : "#fff",
      color: '#f00',
    },
    borderRadius: 20,
  },
}));

const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: "#00000060",
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'gray',
          borderRadius: "20px",
        },
        '&:hover $notchedOutline': {
          borderColor: colorRojo,
        },
        '&$focused $notchedOutline': {
          borderColor: colorRojo,
        },
      },
    },
  },
});

function SignIn({ handleLogin, handleMessages, history }) {

  //id de google api
  const clientId = '125893593885-a4ip9i955j64aku2uf1grt53p0crmgb8.apps.googleusercontent.com';
  const clientScope = "https://www.googleapis.com/auth/cloud-platform";
  
  //hook llamado cada vez que se renderiza o carga la clase
  React.useEffect(() => {
      const initClient = () => {
            gapi.client.init({
            clientId: clientId,
            scope: clientScope,
          });
      };
      gapi.load('client:auth2', initClient);
  });

  const responseFacebook = (response) => {
    console.log(response);
    setData(response);
    setPicture(response.picture.data.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }

  const onSuccess = (res) => {
    //console.log('success:', res);
    let user = {
      email: res.wt.cu,
      password: res.wt.NT,
      name: res.wt.rV,
      lastname: res.wt.uT,
      username: res.wt.cu,
    };
    //console.log('el usuario:',user);
    loginSocial(user);
  };

  const onFailure = (err) => {
    console.log('failed:', err);
  };

  async function loginSocial(user){
    //console.log('login social');
    let response;
    try {
      //console.log('respuesta');
      response = await axios.post('/api/people/isSubscribed', user);
      //console.log(response);
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        handleLogin();
        handleMessages("success", response.data.message);
        history.push("/");
      } else {
        handleMessages("error", response.data.message);
        //registro al usuario
        signupSocial(user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function signupSocial(user) {
    let response;
    try {
      response = await axios.post(`/api/people/addPerson`, user);
      //console.log("addgoogle");
      console.log(response.config.data);
      if (response.data.error) {
        handleMessages("error", response.data.error);
      } else {
        localStorage.setItem("token", response.data.token);
        registerUser = true;
        usuarioSocial = response.data.nombre;
        handleMessages("success", response.data.message);
        //history.push("/");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [values, setValues] = React.useState({
    email: '',
    password: "",
    showPassword: false,
  });

  const [error, setError] = React.useState({
    email: '',
    password: '',
  });

  //constantes globales para inicios de sesion
  const [loginUser, setLogin] = useState(false);
  const [data, setData] = useState({});
  //constantes para facebook
  const [pictureFace, setPicture] = useState('');
  
  const classes = useStyles();

  const handleLoginEvent = (event) => {
    event.preventDefault();
    let data = getData(event.target.elements);
    login(data);
  };

  function getData(data) {
    let user = {
      email: data.email.value,
      password: data.password.value,
    };
    return user;
  }

  async function login(user) {
    let response;
    try {
      response = await axios.post('/api/people/login', user);
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        handleLogin();
        handleMessages("success", response.data.message);
        history.push("/");
      } else {
        handleMessages("error", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    //validamos el usuario, password y confirmacion
    //onInputChange(event);
  };

  return (
    <MuiThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
    {registerUser &&
      <div class="circle">
        <div>
          <span class="avisoMessageA">¡Bienvenidx!</span><br/>
          <span class="avisoMessageB">{usuarioSocial}</span><br/><br/><br/>
        </div> 
        <div>
          <span class="avisoMessageC">¡Tu cuenta ha sido creada exitosamente!</span><br/>
          <span class="avisoMessageC">Ya sólo faltan un par de cosas para terminar</span><br/>
          <span class="avisoMessageC">de completar tu perfil</span><br/><br/>
        </div>
        <div>
        <Button
            spacing={2}
            type="submit"
            variant="contained"
            className={`${classes.submit} btn-white`}
            href="/profile"
          >
            Continuar
          </Button><br/>
        <Button
            spacing={2}
            type="submit"
            variant="contained"
            className={`${classes.submit} btn-red`}
            href="/"
          >
            Omitir
          </Button>
        </div>
      </div>
    }
    {!registerUser &&
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Ingresar
        </Typography>
        <form
          className={classes.x}
          onSubmit={(event) => handleLoginEvent(event)}
          noValidate
        >
        <Grid container spacing={5} justify="center">
          <Grid item align="center">
          ¿Aún no tienes cuenta? {" "}
            <Link href="/signup" variant="body2" className={classes.bigLink}>
              Regístrate
            </Link>
          </Grid>
        </Grid>
          <TextField
            variant="outlined"
            className={classes.text}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField htmlFor="standard-adornment-password"
            variant="outlined"
            className={classes.text}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type={values.showPassword ? "text" : "password"}
            onChange={handlePasswordChange("password")}
            id="password"
            autoComplete="current-password"
            value={values.password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error.password && (
            <span className="err">{error.password}</span>
          )}
          <Button
            spacing={2}
            type="submit"
            fullWidth
            variant="contained"
            className={`${classes.submit} btn-primary`}
          >
            Ingresar
          </Button>
          <Grid container>
            <Grid item>
            ¿Olvidaste tu Contraseña?{" "}
              <Link
                href="/forgotPassword"
                variant="body2"
                className={classes.bigLink}
              >
                Cámbiala Aquí
              </Link>
            </Grid>
          </Grid>
        </form>
            <Divider />
            <GoogleLogin
                clientId={clientId}
                buttonText="Entrar con Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
                render={(renderProps) => (
                  <Button 
                  fullWidth
                  variant="contained"
                  spacing={2}
                  startIcon={<GoogleIcon />}
                  onClick={renderProps.onClick} 
                  disabled={renderProps.disabled} 
                  className={`${classes.google}`}>Entrar con Google</Button>
                )}
            />
            {/*{!loginFace &&
              <FacebookLogin
                appId="6005500676161926"
                autoLoad={true}
                fields="name,email,picture"
                scope="public_profile,user_friends"
                callback={responseFacebook}
                icon="fa-facebook" />
            }
            {loginFace &&
              <Box component="img" src={pictureFace} roundedCircle />
            }*/}
        </div>
    }
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </MuiThemeProvider>
  );
}

export default SignIn;