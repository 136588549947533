/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import axios from "axios";
import MediaQuery from "react-responsive";
import { CircularProgress, Grid } from "@material-ui/core";
import Memes from "../Memes/Memes";
import MyMemes from "../../components/MyMemes";
import styles from "./dashboard.module.css";
import MobileRightBar from "../../components/MobileRightBar";
import Banner from "../../components/Banner";
import Filters from "../../components/Filters/Filters";
const Dashboard = ({
  user,
  filteredMemes,
  categories,
  handleMessages,
  updateSuscribers,
  updateUserSaves,
  updateUserMemes,
  updateMemes,
  history,
  showMyMemes,
  setShowMyMemes,
  deleteMeme,
  updateFollowing,
}) => {
  const [memesLimit] = useState(10);
  const [totalMemes, setTotalMemes] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!filteredMemes.length) {
      getSomeMemes();
    }
  }, []);
  const getSomeMemes = () => {
    axios({
      url: `/api/meme/some`,
      params: {
        page: page,
        size: memesLimit,
      },
    })
      .then((response) => {
        setTotalMemes(response.data.total);
        updateMemes([...filteredMemes, ...response.data.memes]);
        setIsLoading(false);
        setPage((prev) => prev + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleScroll = (target) => {
    const { scrollTop, clientHeight, scrollHeight } = target;
    if (
      Math.round(scrollHeight - scrollTop) === Math.round(clientHeight) &&
      filteredMemes.length < totalMemes &&
      !isLoading
    ) {
      setIsLoading(true);
      getSomeMemes();
    }
  };

  return (
    <>
      <MediaQuery minWidth={700}>
        <div className={styles.content}>
          <div className={styles.contentCenter}>
            <div className={styles.filters}></div>
            <div
              className={"dashboard-scroll"}
              onScroll={(event) => handleScroll(event.currentTarget)}
            >
              <Filters updateMemes={updateMemes}/>
              <div id='banner1' className={styles.banner_tag}></div>
              <Banner />
              <div id='banner2' className={styles.banner_tag}></div>
              {filteredMemes !== null &&
                (filteredMemes.length > 0
                  ? filteredMemes.map((meme) => {
                      return (
                        <Memes
                          location={"dashboard"}
                          key={meme.id}
                          user={user}
                          meme={meme}
                          updateSuscribers={updateSuscribers}
                          updateSaves={updateUserSaves}
                          handleMessages={handleMessages}
                          history={history}
                          deleteMeme={deleteMeme}
                          updateFollowing={updateFollowing}
                        />
                      );
                    })
                  : "Se el primero en publicar un meme")}
            </div>
          </div>
          <div className={styles.contentRight}>
            {isLoading && (
              <Grid className={"main-content flex-center"} item md={12}>
                <CircularProgress />
              </Grid>
            )}
            <div className={"myMemes"}>
              {user === null && (
                <h1 className={styles.advertisements}>Ingresa para Agregar Memes</h1>
              )}
              {user !== null && (
                <MyMemes
                  user={user}
                  updateMemes={updateMemes}
                  updateUserMemes={updateUserMemes}
                  categories={categories}
                  history={history}
                />
              )}
            </div>
          </div>
        </div>
      </MediaQuery>
      {/*Mobile */}
      <MediaQuery maxWidth={699}>
        <div
          className={"dashboard-scroll"}
          onScroll={(event) => handleScroll(event.currentTarget)}
        >
          
          {user === null && (
                <h1 className={styles.advertisements}>Ingresa para Agregar Memes</h1>
              )}
        <div id='banner1' className={styles.banner_tag}></div>
        <Banner />
        <div id='banner2' className={styles.banner_tag}></div>
          <Filters updateMemes={updateMemes}/>
          {filteredMemes !== null &&
            (filteredMemes.length > 0
              ? filteredMemes.map((meme) => {
                  return (
                    <Memes
                      location={"dashboard"}
                      key={meme.id}
                      user={user}
                      meme={meme}
                      updateSuscribers={updateSuscribers}
                      updateSaves={updateUserSaves}
                      handleMessages={handleMessages}
                      history={history}
                      deleteMeme={deleteMeme}
                      updateFollowing={updateFollowing}
                    />
                  );
                })
              : "Se el primero en publicar un meme")}
        </div>
        {isLoading && (
          <Grid className={"main-content flex-center"} item md={12}>
            <CircularProgress />
          </Grid>
        )}
        <div className={showMyMemes ? styles.myMemes : styles.hide}>
          <MobileRightBar
            user={user}
            setShowMyMemes={setShowMyMemes}
            showMyMemes={showMyMemes}
            updateMemes={updateMemes}
            updateUserMemes={updateUserMemes}
            categories={categories}
          />
        </div>
      </MediaQuery>
    </>
  );
};
export default Dashboard;
