import React, { useRef } from "react";
import { TextField, Button } from "@material-ui/core";
import CircularPicure from "../CircularPicture";
import axios from "axios";
import styles from "./commenter.module.css";
import { FixMeLater } from "../../models/Temp";
import { UserEntity } from "../../models/Entities";

const Commenter: React.FC<{
  currentUser: UserEntity;
  meme: FixMeLater;
  handleMessages: FixMeLater;
  location: FixMeLater;
  updateComments: FixMeLater;
}> = ({ currentUser, meme, handleMessages, updateComments, location }) => {
  const commentRef = useRef<HTMLInputElement>(null);

  const handleCommentOnClick = async () => {
    let data = {
      meme: meme.id,
      comment: commentRef.current!.value,
    };
    const config = {
      headers: {
        token: localStorage.getItem("token"),
      },
    };
    let response;
    try {
      response = await axios.post(`/api/meme/meme`, data, config);
      if (response.data.success) {
        updateComments();
      } else {
        handleMessages("error", response.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      commentRef.current!.value = "";
    }
  };
  return (
    <>
      {currentUser && location === "meme" && (
        <div className={styles.container}>
          <div className={styles.textfieldMeme}>
            <TextField
              inputRef={commentRef}
              name="comment"
              className={styles.commentInputMeme}
              multiline
              placeholder="Agrega un comentario"
              fullWidth
            />
          </div>
          <div className={styles.buttonMeme}>
            <Button
              color="primary"
              aria-label="Comment"
              component="span"
              className={styles.commentButtonMeme}
              onClick={() => handleCommentOnClick()}
            >
              Publicar
            </Button>
          </div>
        </div>
      )}
      {currentUser && location === "dashboard" && (
        <div className={styles.container}>
          <div className={styles.picture}>
            <CircularPicure path={currentUser.picture}></CircularPicure>
          </div>
          <div className={styles.textfield}>
            <TextField
              inputRef={commentRef}
              name="comment"
              className={styles.commentInput}
              multiline
              placeholder="Agrega un comentario"
              fullWidth
            />
          </div>
          <div className={styles.button}>
            <Button
              color="primary"
              aria-label="Comment"
              component="span"
              className={styles.commentButton}
              onClick={() => handleCommentOnClick()}
            >
              Publicar
            </Button>
          </div>
        </div>
      )}
      {!currentUser && location === "meme" && (
        <div className={styles.container}>
          <div className={styles.messageContainer}>
            <div className={styles.message}>
              <p>Regístrate o inicia sesión para dejar un comentario:</p>
            </div>
            <div className={styles.actionsContainer}>
              <div className={styles.actionButtons}>
                <Button className={styles.signin} href="/signup">
                  Registrarme
                </Button>
              </div>
              <div className={styles.actionButtons}>
                <Button className={styles.login} href="/login">
                  Iniciar Sesión
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Commenter;
