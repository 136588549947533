const ReportIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
    fill="none"
    
  >
    <path
    d="M83.843 38.346 L 81.517 40.672 81.517 199.526 L 81.517 358.380 83.843 360.707 L 86.170 363.033 200.474 363.033 L 314.778 363.033 317.105 360.707 L 319.431 358.380 319.431 233.010 L 319.431 107.641 283.666 71.830 L 247.901 36.019 167.035 36.019 L 86.170 36.019 83.843 38.346 M236.019 83.929 L 236.019 114.778 238.346 117.105 L 240.672 119.431 271.521 119.431 L 302.370 119.431 302.370 232.701 L 302.370 345.972 200.474 345.972 L 98.578 345.972 98.578 199.526 L 98.578 53.081 167.299 53.081 L 236.019 53.081 236.019 83.929 M271.564 84.360 L 289.555 102.370 271.318 102.370 L 253.081 102.370 253.081 84.360 C 253.081 74.455,253.191 66.351,253.327 66.351 C 253.462 66.351,261.669 74.455,271.564 84.360 M138.727 162.077 C 131.849 164.853,131.965 174.904,138.908 177.805 C 143.074 179.546,256.568 179.815,261.499 178.096 C 268.523 175.648,268.921 165.372,262.112 162.270 C 259.143 160.917,142.054 160.735,138.727 162.077 M135.976 223.180 C 132.606 226.550,132.798 231.522,136.440 235.164 L 139.231 237.955 200.869 237.698 C 261.668 237.444,262.534 237.414,264.459 235.488 C 267.730 232.217,267.928 227.434,264.939 223.881 L 262.391 220.853 200.347 220.853 L 138.302 220.853 135.976 223.180 M136.420 282.392 C 132.888 285.924,132.779 289.816,136.103 293.766 L 138.557 296.682 200.157 296.682 L 261.757 296.682 264.645 293.795 C 268.208 290.232,267.921 285.195,263.960 281.754 L 261.506 279.621 200.348 279.621 L 139.191 279.621 136.420 282.392 "
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default ReportIcon;
