import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import logo from "../../assets/icons/logo.svg";
import styles from "./nav.module.css";
import MediaQuery from "react-responsive";
import {
  CategoriesIcon,
  FunnyIcon,
  LoveIcon,
  AnimalsIcon,
  AwesomeIcon,
  RandomIcon,
  TVAndMoviesIcon,
  AcademicIcon,
  SciFiIcon,
  ScienceIcon,
  MoneyIcon,
  GeekIcon,
  TravelsIcon,
  RelationshipIcon,
  PoliticsIcon,
  SportsIcon,
  CelebritiesIcon,
  ForWomenIcon,
  ArrowExpandIcon,
} from "../../SVG/Icons";
import { ThemeContext } from "../../store/theme-context.js";

const Nav = ({
  user,
  toggleNav,
  setToggleNav,
  categories,
}) => {
  const history = useHistory();
  const [openCategories, setOpenCategories] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const onClickListItem = (event) => {
    var uri = event.view.location.origin;
    console.log(uri);
    var categoria = event.target.innerText;
    //setSelectedIndex(item);
    window.location.href = uri+'/memes/'+categoria;
  };
  
  function onClickCategories(event) {
    setOpenCategories(!openCategories);
  }

  const icons = [
    <FunnyIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <LoveIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <AnimalsIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <AwesomeIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <RandomIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <TVAndMoviesIcon
      color={"#ff3651"}
      color2={"#fff"}
      width={25}
      height={25}
    />,
    <AcademicIcon color={"#ff3651"} color2={"#fff"} width={23} height={23} />,
    <SciFiIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <ScienceIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <MoneyIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <GeekIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <TravelsIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <RelationshipIcon
      color={"#ff3651"}
      color2={"#fff"}
      width={25}
      height={25}
    />,
    <PoliticsIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <SportsIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
    <CelebritiesIcon
      color={"#ff3651"}
      color2={"#fff"}
      width={25}
      height={25}
    />,
    <ForWomenIcon color={"#ff3651"} color2={"#fff"} width={25} height={25} />,
  ];

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <MediaQuery maxWidth={699}>
        <div className={`${toggleNav && styles.showMobileNavBar}`}>
          <div className={styles.contentLeftBar}>
            <span className={styles.categoriesText}>Categorías</span>
            <IconButton onClick={() => setToggleNav()}>
              <Close htmlColor={theme === "dark" ? "#fff" : "000"} />
            </IconButton>
          </div>

          <List>
            {categories != null &&
              categories.map((category, index) => (
                <ListItem
                  button
                  key={category.id}
                  value={category.name}
                  selected={selectedIndex === index}
                  //onClick={() => history.push(category.name)}
                  onClick={(event) => onClickListItem(event)}
                  className={
                    selectedIndex !== index
                      ? styles.categoriesDropdownItems
                      : styles.categoriesDropdownItemsSelected
                  }
                >
                  <ListItemIcon className={styles.categoriesDropdownItemsIcon}>
                    {icons[index]}
                  </ListItemIcon>
                  <div className={styles.categoriesDropdownItemsText}>
                    {category.name}
                  </div>
                </ListItem>
              ))}
          </List>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={700}>
        <Link to="/">
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <div className={styles.navContainer}>
          <div className={styles.signupContainer}>
            <div className={styles.signupMessage}>
              <div className={styles.signupMessageA}>¡Bienvenidx</div>
              <div className={styles.signupMessageB}>al Rincón del Meme!</div>
              <p className={styles.signupMessageC}>
                Disfruta del mejor contenido
              </p>
            </div>

            <div className={styles.buttonContainer}>
              <div className={styles.buttonCenter}>
                <Button className={styles.signupButton} href="/signup">
                  Registrarme
                </Button>
              </div>
            </div>
          </div>
          <div>
            <List>
              <ListItem
                className={styles.categoriesDropdown}
                onClick={onClickCategories}
                button
              >
                <ListItemIcon className={styles.categoriesDropdownItemsIcon}>
                  <CategoriesIcon color={"#ff3651"} width={25} height={25} />
                </ListItemIcon>
                <div className={styles.categoriesText}>Categorías</div>
                <div className={styles.categoriesDropdownArrow}>
                  {openCategories ? (
                    <ArrowExpandIcon color={"gray"} width={18} height={18} />
                  ) : (
                    <ArrowExpandIcon color={"gray"} width={18} height={18} />
                  )}
                </div>
              </ListItem>
            </List>
            <List className={!openCategories ? styles.hide : null}>
              {categories != null &&
                categories.map((category, index) => (
                  <ListItem
                    button
                    key={category.id}
                    value={category.name}
                    selected={selectedIndex === index}
                    onClick={(event) => onClickListItem(event)}
                    className={
                      selectedIndex !== index
                        ? styles.categoriesDropdownItems
                        : styles.categoriesDropdownItemsSelected
                    }
                  >
                    <ListItemIcon
                      className={styles.categoriesDropdownItemsIcon}
                    >
                      {icons[index]}
                    </ListItemIcon>
                    <div className={styles.categoriesDropdownItemsText}>
                      {category.name}
                    </div>
                  </ListItem>
                ))}
            </List>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};
export default Nav;