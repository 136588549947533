import React, { useEffect, useState } from "react";
import { Grid, Container, Button, TextField, Switch } from "@material-ui/core";
import axios from "axios";
import Spinner from "../../components/Spinner";
import PicturesDialog from "../../components/PicturesDialog";
import styles from "./profile.module.css";
import MediaQuery from "react-responsive";
import { Redirect } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../store/theme-context.js";
import { GoogleLogout } from 'react-google-login';

const Profile = ({
  user,
  handleMessages,
  updateUser,
  getUser,
  handleLogout,
  setToggleNav,
  setShowMyMemes,
}) => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [imagePreview, setImagePreview] = useState(null);
  const [showSaveImageButton, setShowSaveImageButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPicturesProfileModal, setShowPicturesProfileModal] =
    useState(false);
  useEffect(() => {
    if (user) {
      setImagePreview(process.env.REACT_APP_API_URL + user.picture);
    }
  }, [user]);

  const handleImageEditClick = () => {
    setShowPicturesProfileModal(true);
  };

  const handleUploadPicture = (path) => {
    if (path) {
      setImagePreview(`${process.env.REACT_APP_API_URL}${path}`);
      setShowSaveImageButton(true);
    } else {
      document.getElementById("profileImage").click();
    }
    setShowPicturesProfileModal(false);
  };

  const handleClickLogout = () => {
    localStorage.clear("token");
    handleLogout();
  };

  const handleImageChange = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      setImagePreview(reader.result);
      setShowSaveImageButton(true);
    };
    reader.readAsDataURL(file);
  };

  const getLikes = () => {
    let result = 0;
    if (user.own_memes) {
      user.own_memes.forEach((meme) => {
        if (meme.likes && meme.likes.length > 0) {
          result += meme.likes.length;
        }
      });
    }
    return result;
  };

  const getMemes = () => {
    let result = 0;
    if (user.own_memes) {
      result = user.own_memes.length;
    }
    return result;
  };

  const getFollowers = () => {
    let result = 0;
    if (user.subscribers) {
      result = user.subscribers.length;
    }
    return result;
  };

  const getComments = () => {
    let result = 0;

    if (user.own_memes) {
      user.own_memes.forEach((meme) => {
        if (meme.comments && meme.comments.length > 0) {
          result += meme.comments.length;
        }
      });
    }
    return result;
  };

  const handleSaveChanges = async (event) => {
    event.preventDefault();
    if (await passwordIsOk()) {
      const newPassword = document.getElementById("new_password").value;
      if (newPassword) {
        if (doesPasswordsMatch(newPassword)) {
          await updateProfile();
        } else {
          handleMessages("error", "Las contraseñas nuevas no coinciden");
        }
      } else {
        await updateProfile();
      }
    } else {
      handleMessages("error", "Contraseña actual incorrecta");
    }
  };

  const updateProfile = async () => {
    let response;
    try {
      response = await axios.post(
        `/api/people/update`,
        new FormData(document.getElementById("profileForm")),
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        handleMessages("success", response.data.message);
        updateUser(await getUser(localStorage.getItem("token")));
      } else {
        handleMessages("error", response.data.message);
      }
    } catch (error) {
      handleMessages("error", error);
    }
  };

  const passwordIsOk = async () => {
    const params = {
      params: {
        password: document.getElementById("old_password").value,
      },
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    let response;
    try {
      response = await axios.get(`/api/people/isPasswordOk`, params);
      return response.data.isPasswordOk;
    } catch (error) {
      console.log(error);
    }
  };

  const doesPasswordsMatch = (newPassword) => {
    let result = false;
    const confirm = document.getElementById("confirm_password").value;
    if (newPassword === confirm) {
      result = true;
    }
    return result;
  };

  const handleSaveImageClick = async () => {
    imagePreview.startsWith("data:")
      ? await updateProfile()
      : await updateProfilePictureFromURL();
    setShowSaveImageButton(false);
    setIsLoading(false);
  };

  const updateProfilePictureFromURL = async () => {
    let response;
    let url = imagePreview.includes(process.env.REACT_APP_API_URL)
      ? imagePreview.replace(process.env.REACT_APP_API_URL, "")
      : imagePreview;
    try {
      response = await axios.put(
        `/api/people/${user.email}/pictureURL`,
        { url: url },
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        handleMessages("success", response.data.success);
        updateUser(await getUser(localStorage.getItem("token")));
      } else {
        handleMessages("error", response.data.error);
      }
    } catch (error) {
      handleMessages("error", error);
    }
  };

  //id de google api
  const clientId = '125893593885-a4ip9i955j64aku2uf1grt53p0crmgb8.apps.googleusercontent.com';

  return isLoading ? (
    <Spinner>Cargando...</Spinner>
  ) : !localStorage.getItem("token") && !user ? (
    <Redirect to="/" />
  ) : (
    <Container>
      <h2>Perfil</h2>
      {user && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <div className={styles.user_image}>
              <img src={imagePreview} alt="profile" />
              {showSaveImageButton && (
                <Button
                  fullWidth
                  variant="contained"
                  className="btn-primary"
                  onClick={() => handleSaveImageClick()}
                >
                  Guardar Imagen
                </Button>
              )}
              <Button
                fullWidth
                variant="contained"
                className="btn-primary"
                onClick={handleImageEditClick}
              >
                Editar
              </Button>
            </div>
            <div>
              <h2>Modo Oscuro</h2>
              <Switch onChange={toggleTheme} checked={theme === "dark"} />
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <form
              id="profileForm"
              encType="multipart/form-data"
              onSubmit={(event) => handleSaveChanges(event)}
            >
              <input
                accept="image/*"
                id="profileImage"
                name="image"
                type="file"
                onChange={(event) => handleImageChange(event)}
                hidden
              />
              <Grid container spacing={2}>
                <h3>Información Personal</h3>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={user.username}
                    name="username"
                    label="Usuario"
                    id="username"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    disabled
                    InputProps={{
                      readOnly: true,
                    }}
                    defaultValue={user.email}
                    id="email"
                    label="Correo"
                    name="email"
                  />
                </Grid>
                <h3>Administración de contraseña</h3>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    name="password"
                    label="Contraseña actual"
                    type="password"
                    id="old_password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="new_password"
                    label="Contraseña nueva"
                    type="password"
                    id="new_password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="confirm_password"
                    label="Confirmar contraseña"
                    type="password"
                    id="confirm_password"
                  />
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="btn-primary"
                >
                  Guardar cambios
                </Button>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={4}>
            <h3>Estadísticas Personales</h3>
            <div className={styles.option_btn}>
              <div className={styles.views}></div>
              <div>
                <p>{getMemes()} - Memes publicados</p>
              </div>
            </div>
            <div className={styles.option_btn}>
              <div className={styles.likes}></div>
              <div>
                <p>{getLikes()} - Me gusta</p>
              </div>
            </div>
            <div className={styles.option_btn}>
              <div className={styles.subscribers}></div>
              <div>
                <p>{getFollowers()} - Seguidores</p>
              </div>
            </div>
            <div className={styles.option_btn}>
              <div className={styles.comments}></div>
              <div>
                <p>{getComments()} - Comentarios</p>
              </div>
            </div>
          </Grid>
          <MediaQuery maxWidth={959}>
            <Container>
              <GoogleLogout
                clientId={clientId}
                onLogoutSuccess={handleClickLogout}
                type="submit"
                render={(renderProps) => (
                  <Button 
                  fullWidth
                  variant="contained"
                  spacing={2}
                  onClick={renderProps.onClick} 
                  disabled={renderProps.disabled}
                  className={`${styles.btnLogoff} btn-primary`}>Cerrar Sesión</Button>
                )}
              />
            </Container>
          </MediaQuery>
          <PicturesDialog
            open={showPicturesProfileModal}
            onClose={() => setShowPicturesProfileModal(false)}
            handleUploadPicture={handleUploadPicture}
          />
        </Grid>
      )}
    </Container>
  );
};

export default Profile;
