const ShareIcon: React.FC<{ width: number; height: number; color: string }> = ({
  width,
  height,
  color,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M286.229 51.241c-28.842 5.448-48.832 35.797-42.409 64.383l.655 2.915-49.096 24.423-49.096 24.424-5.276-5.276c-31.958-31.958-87.627-10.468-90.84 35.069-3.496 49.529 57.716 76.702 92.398 41.017l4.085-4.203 49.063 24.535c40.977 20.491 48.958 24.804 48.427 26.169-5.313 13.661 1.465 37.558 14.37 50.66 33.282 33.79 91.61 9.998 91.61-37.367 0-47.869-57.447-72.478-91.08-39.017l-5.323 5.296-48.59-24.461-48.589-24.46.472-10.552c.259-5.803.076-12.278-.408-14.388l-.879-3.837 48.997-24.586 48.997-24.585 6.406 6.038c34.163 32.202 89.312 8.237 89.476-38.882.122-35.299-29.008-59.807-63.37-53.315m23.844 22.867c14.214 6.507 22.169 22.311 18.592 36.935-6.462 26.424-37.994 34.881-56.118 15.05-23.958-26.213 5.297-66.74 37.526-51.985M117.78 171.093c23.056 11.801 25.594 42.422 4.693 56.598-31.498 21.361-67.288-17.542-44.127-47.965 8.425-11.067 26.815-15.093 39.434-8.633m190.531 95.625c22.166 8.294 28.235 37.323 11.282 53.964-20.866 20.482-55.804 6.181-55.804-22.843 0-23.286 22.823-39.24 44.522-31.121"
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default ShareIcon;
