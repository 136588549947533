const TravelsIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M50.07,0.76c27.16,0,49.31,22.16,49.24,49.39C99.23,77.33,77.51,99.5,49.87,99.46
	C21.89,99.42,0.21,76.67,0.58,49.41C0.96,22.28,23.08,0.67,50.07,0.76z M41.58,58.26c0.09,0.3,0.18,0.49,0.19,0.68
	c0.28,3.85,0.58,7.71,0.81,11.57c0.02,0.42-0.32,0.95-0.64,1.28c-2.09,2.1-3.68,4.5-4.5,7.36c-0.58,2.05,0.26,3.17,2.41,3.17
	c6.78,0.01,13.56,0.01,20.34,0c2.22,0,3.05-1.12,2.44-3.24c-0.8-2.79-2.3-5.16-4.39-7.15c-0.61-0.58-0.84-1.15-0.76-1.98
	c0.25-2.78,0.44-5.56,0.66-8.34c0.08-1.09,0.16-2.18,0.26-3.42c4.04,1.95,7.84,3.86,11.72,5.62c2.05,0.94,4.16,1.85,6.35,2.36
	c2.77,0.65,4.79-1.15,4.8-4c0-5.17-2.04-9.35-6.28-12.38c-4.6-3.29-9.21-6.59-13.86-9.81c-0.93-0.65-1.26-1.31-1.23-2.43
	c0.08-3.43,0.18-6.87-0.02-10.29c-0.13-2.28-0.5-4.61-1.23-6.77c-1.4-4.1-4.75-6.42-8.61-6.4c-3.87,0.02-7.19,2.36-8.57,6.46
	c-0.71,2.12-1.08,4.41-1.21,6.65c-0.2,3.5-0.09,7.02-0.03,10.53c0.02,0.98-0.27,1.57-1.09,2.14c-4.71,3.28-9.39,6.6-14.06,9.95
	c-4.19,3.01-6.22,7.15-6.23,12.28c-0.01,2.91,2,4.6,4.86,4.08c1.28-0.23,2.55-0.66,3.74-1.19c2.55-1.13,5.04-2.38,7.56-3.58
	C37.17,60.38,39.35,59.33,41.58,58.26z"
      fill={color}
    />
    <path
      d="M41.58,58.26c-2.23,1.07-4.41,2.12-6.59,3.17c-2.51,1.2-5.01,2.45-7.56,3.58c-1.19,0.53-2.46,0.96-3.74,1.19
	c-2.87,0.53-4.87-1.17-4.86-4.08c0.01-5.13,2.05-9.27,6.23-12.28c4.66-3.35,9.34-6.67,14.06-9.95c0.82-0.57,1.1-1.16,1.09-2.14
	c-0.06-3.51-0.17-7.03,0.03-10.53c0.13-2.24,0.5-4.53,1.21-6.65c1.38-4.11,4.7-6.45,8.57-6.46c3.86-0.02,7.21,2.3,8.61,6.4
	c0.73,2.15,1.09,4.49,1.23,6.77c0.2,3.42,0.1,6.86,0.02,10.29c-0.03,1.12,0.3,1.78,1.23,2.43c4.65,3.22,9.26,6.51,13.86,9.81
	c4.23,3.03,6.28,7.21,6.28,12.38c0,2.84-2.03,4.64-4.8,4c-2.18-0.51-4.29-1.43-6.35-2.36c-3.87-1.77-7.68-3.68-11.72-5.62
	c-0.09,1.24-0.17,2.33-0.26,3.42c-0.21,2.78-0.41,5.56-0.66,8.34c-0.07,0.82,0.15,1.39,0.76,1.98c2.08,1.99,3.58,4.37,4.39,7.15
	c0.61,2.12-0.22,3.24-2.44,3.24c-6.78,0.01-13.56,0.01-20.34,0c-2.15,0-2.99-1.12-2.41-3.17c0.82-2.87,2.4-5.27,4.5-7.36
	c0.33-0.33,0.67-0.86,0.64-1.28c-0.22-3.86-0.52-7.71-0.81-11.57C41.76,58.75,41.67,58.56,41.58,58.26z M77.11,62.37
	c0-0.7,0.02-0.98,0-1.25c-0.03-0.36-0.06-0.72-0.13-1.07c-0.62-2.88-2.04-5.22-4.5-6.94c-5.13-3.59-10.2-7.26-15.33-10.86
	c-0.98-0.69-1.41-1.5-1.4-2.7c0.04-4.07,0.14-8.15-0.04-12.21c-0.09-1.99-0.57-4.02-1.22-5.91c-0.68-1.99-2.27-3.18-4.48-3.18
	c-2.15,0.01-3.74,1.17-4.41,3.11c-0.67,1.93-1.15,3.99-1.25,6.02c-0.19,4.02-0.09,8.06-0.04,12.09c0.02,1.24-0.4,2.08-1.43,2.8
	c-5.1,3.57-10.14,7.22-15.23,10.78c-2.46,1.72-3.95,4.01-4.53,6.91c-0.13,0.67-0.12,1.37-0.2,2.33c1.24-0.48,2.23-0.78,3.15-1.22
	c5.07-2.41,10.19-4.74,15.17-7.33c2.93-1.53,4.36-0.78,4.52,2.72c0.22,4.78,0.6,9.55,1.04,14.31c0.14,1.51-0.17,2.57-1.41,3.51
	c-1.28,0.97-2.3,2.22-3.02,3.78c5.18,0,10.2,0,15.22,0c0.07-0.11,0.15-0.22,0.22-0.33c-1.21-1.3-2.48-2.55-3.6-3.91
	c-0.49-0.59-1.03-1.44-0.99-2.14c0.32-5.41,0.73-10.82,1.19-16.22c0.21-2.48,1.44-3.12,3.66-2.05c4.81,2.32,9.61,4.66,14.42,6.96
	C73.94,61.08,75.41,61.65,77.11,62.37z"
      fill={color2}
    />
    <path
      d="M77.11,62.37c-1.7-0.73-3.17-1.3-4.59-1.98c-4.82-2.3-9.62-4.64-14.42-6.96c-2.22-1.07-3.45-0.44-3.66,2.05
	c-0.46,5.4-0.88,10.81-1.19,16.22c-0.04,0.7,0.5,1.54,0.99,2.14c1.13,1.36,2.39,2.62,3.6,3.91c-0.07,0.11-0.15,0.22-0.22,0.33
	c-5.02,0-10.04,0-15.22,0c0.71-1.56,1.74-2.81,3.02-3.78c1.24-0.94,1.55-2,1.41-3.51c-0.44-4.76-0.82-9.53-1.04-14.31
	c-0.16-3.49-1.59-4.24-4.52-2.72c-4.98,2.59-10.1,4.92-15.17,7.33c-0.92,0.44-1.91,0.74-3.15,1.22c0.08-0.96,0.07-1.66,0.2-2.33
	c0.59-2.9,2.08-5.19,4.53-6.91c5.1-3.56,10.14-7.22,15.23-10.78c1.03-0.72,1.45-1.56,1.43-2.8c-0.05-4.03-0.15-8.07,0.04-12.09
	c0.1-2.03,0.58-4.09,1.25-6.02c0.67-1.94,2.25-3.1,4.41-3.11c2.21-0.01,3.8,1.19,4.48,3.18c0.65,1.89,1.13,3.92,1.22,5.91
	c0.18,4.06,0.08,8.14,0.04,12.21c-0.01,1.2,0.41,2.01,1.4,2.7c5.13,3.6,10.2,7.27,15.33,10.86c2.46,1.72,3.88,4.05,4.5,6.94
	c0.07,0.35,0.1,0.71,0.13,1.07C77.13,61.39,77.11,61.67,77.11,62.37z"
      fill={color}
    />
  </svg>
);

export default TravelsIcon;
