import React, { useState, useEffect  } from "react";
import styles from "./filters.module.css";
import { Button } from "@material-ui/core";
import MediaQuery from "react-responsive";
import axios from "axios";

const Filters= ({
    updateMemes,
  }) => { 
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [allMemes, setAllMemes] = useState([]);

  const filters = [
    "Recientes",
    "Tendencias",
    "Más comentados",
    /*"Top México",
    "Top Brasil",
    "Lorem ipsum",
    "Dolor sit",
    "Amet",*/
  ];

  useEffect(() => {
    getMemes();
  }, []);
  const getMemes = () => {
    try {
      axios
        .get("/api/meme")
        .then((response) => setAllMemes(response.data.memes));
    } catch (error) {
      console.log("Error de conexion con el API");
    }
  };

  const onClickListFilter = (index: number) => {
    setSelectedIndex(index);
    if(index === 0){//recientes
      axios({
        url: `/api/meme/some`,
        params: {
          page: 0,
          size: 10,
        },
      })
        .then((response) => {
          updateMemes([...response.data.memes]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if(index === 1){//tendencias
      axios({
        url: `/api/meme`,
        params: {
          page: 0,
          size: 10,
        },
      })
        .then((response) => {
          let memes = [...response.data.memes];
          memes.sort((a, b) => {
            return b.likes.length - a.likes.length;
          })
          updateMemes(memes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if(index === 2){//comments
      axios({
        url: `/api/meme`,
        params: {
          page: 0,
          size: 10,
        },
      })
        .then((response) => {
          let memes = [...response.data.memes];
          memes.sort((a, b) => {
            return b.comments.length - a.comments.length;
          })
          updateMemes(memes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <MediaQuery minWidth={700}>
        <div className={styles.filtersContainer}>
          {filters.map((filter, index) => (
            <Button
              key={index}
              onClick={() => onClickListFilter(index)}
              className={
                selectedIndex !== index
                  ? styles.filtersButton
                  : styles.filtersButtonSelected
              }
            >
              {filter}
            </Button>
          ))}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={699}>
        <div className={styles.mobileContainer}>
          {filters.map((filter, index) => (
            <Button
              key={index}
              onClick={() => onClickListFilter(index)}
              className={
                selectedIndex !== index
                  ? styles.filtersButton
                  : styles.filtersButtonSelected
              }
            >
              {filter}
            </Button>
          ))}
        </div>
      </MediaQuery>
    </>
  );
};

export default Filters;

