import React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Copyright from "../components/CopyRight";
import axios from "axios";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import GoogleIcon from "@mui/material/IconButton";

import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { FacebookLogin } from 'react-facebook-login';

const colorRojo = '#ff000050';

var registerUser = false;//variable que controla si muestro o no el avisod e registro
var usuarioSocial = "";//el usuario social que se registro

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 20,
  },
  text: {
    color: colorRojo,
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#00000060',
    },
  },
  bigLink: {
    fontSize: "14px",
  },
  google: {
    margin: theme.spacing(3, 0, 2),
    background : "#fff",
    '&:hover': {
      backgroundColor : "#fff",
      color: '#f00',
    },
    borderRadius: 20,
  },
}));

const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: "#00000060",
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'gray',
          borderRadius: "20px",
        },
        '&:hover $notchedOutline': {
          borderColor: colorRojo,
        },
        '&$focused $notchedOutline': {
          borderColor: colorRojo,
        },
      },
    },
  },
});

const SignUp = ({ handleSignUp, handleMessages, history }) => {

  //id de google api
  const clientId = '125893593885-a4ip9i955j64aku2uf1grt53p0crmgb8.apps.googleusercontent.com';
  const clientScope = "https://www.googleapis.com/auth/cloud-platform";
  
  //hook llamado cada vez que se renderiza o carga la clase
  React.useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: clientScope,
        });
    };
    gapi.load('client:auth2', initClient);
  });

  const onSuccess = (res) => {
    //console.log('success:', res);
    let user = {
      email: res.wt.cu,
      password: res.wt.NT,
      name: res.wt.rV,
      lastname: res.wt.uT,
      username: res.wt.cu,
    };
    //console.log('el usuario:',user);
    loginSocial(user);
  };

  const onFailure = (err) => {
    console.log('failed:', err);
  };

  async function loginSocial(user){
    //console.log('login social');
    let response;
    try {
      //console.log('respuesta');
      response = await axios.post('/api/people/isSubscribed', user);
      //console.log(response);
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        handleSignUp();
        handleMessages("success", response.data.message);
        history.push("/");
      } else {
        handleMessages("error", response.data.message);
        //registro al usuario
        signupSocial(user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function signupSocial(user) {
    let response;
    try {
      response = await axios.post(`/api/people/addPerson`, user);
      //console.log("addgoogle");
      console.log(response.config.data);
      if (response.data.error) {
        handleMessages("error", response.data.error);
      } else {
        localStorage.setItem("token", response.data.token);
        registerUser = true;
        usuarioSocial = response.data.nombre;
        handleMessages("success", response.data.message);
        //history.push("/");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [values, setValues] = React.useState({
    username: '',
    password: "",
    confirmPasword: "",
    showPassword: false,
  });

  const [error, setError] = React.useState({
    username: '',
    password: '',
    confirmPassword: '',
  });

  const classes = useStyles();

  const handleSignUpEvent = (event) => {
    event.preventDefault();
    //validamos el usuario, password y confirmacion
    onInputChange(event);
    let data = getData(event.target.elements);
    signup(data);
  };

  function getData(data) {
    let user = {
      name: data.name.value,
      lastname: data.lastname.value,
      username: data.username.value,
      email: data.email.value,
      password: data.password.value,
    };
    return user;
  }

  async function signup(user) {
    let response;
    try {
      response = await axios.post(`/api/people/addPerson`, user);
      if (response.data.error) {
        handleMessages("error", response.data.error);
      } else {
        localStorage.setItem("token", response.data.token);
        handleSignUp();
        handleMessages("success", response.data.message);
        history.push("/");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    //validamos el usuario, password y confirmacion
    onInputChange(event);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };
  
      switch (name) {
        case 'username':
          if (!value) {
            stateObj[name] = 'Ingresa el Usuario.';
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Ingresa la Contraseña.";
          } else if (values.confirmPassword && value !== values.confirmPassword) {
            stateObj["confirmPassword"] = "Las contraseñas no coinciden.";
          } else {
            stateObj["confirmPassword"] = values.confirmPassword ? "" : error.confirmPassword;
          }
          break;
  
        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Vuelve a Ingresar la Contraseña.";
          } else if (values.password && value !== values.password) {
            stateObj[name] = "Las contraseñas no coinciden.";
          }
          break;
  
        default:
          break;
      }
  
      return stateObj;
    });
  };

  return (
    <MuiThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
    {registerUser &&
      <div class="circle">
        <div>
          <span class="avisoMessageA">¡Bienvenidx!</span><br/>
          <span class="avisoMessageB">{usuarioSocial}</span><br/><br/><br/>
        </div> 
        <div>
          <span class="avisoMessageC">¡Tu cuenta ha sido creada exitosamente!</span><br/>
          <span class="avisoMessageC">Ya sólo faltan un par de cosas para terminar</span><br/>
          <span class="avisoMessageC">de completar tu perfil</span><br/><br/>
        </div>
        <div>
        <Button
            spacing={2}
            type="submit"
            variant="contained"
            className={`${classes.submit} btn-white`}
            href="/profile"
          >
            Continuar
          </Button><br/>
        <Button
            spacing={2}
            type="submit"
            variant="contained"
            className={`${classes.submit} btn-red`}
            href="/"
          >
            Omitir
          </Button>
        </div>
      </div>
    }
    {!registerUser &&
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Registrarme
        </Typography>
        <form
          className={classes.form}
          onSubmit={(event) => handleSignUpEvent(event)}
        >
        <Grid container spacing={5} justify="center">
          <Grid item align="center">
          ¿Ya eres miembro? {" "}
            <Link href="login" variant="body2" className={classes.bigLink}>
              Entra Aquí
            </Link>
          </Grid>
        </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="name"
                variant="outlined"
                className={classes.text}
                required
                fullWidth
                id="name"
                label="Nombre"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                className={classes.text}
                required
                fullWidth
                id="lastname"
                label="Apellido(s)"
                name="lastname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                className={classes.text}
                required
                fullWidth
                name="username"
                label="Usuario"
                id="username"
                onChange={onInputChange}
                onBlur={validateInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                className={classes.text}
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField htmlFor="standard-adornment-password"
                variant="outlined"
                className={classes.text}
                required
                fullWidth
                name="password"
                label="Contraseña"
                id="password"
                autoComplete="current-password"
                type={values.showPassword ? "text" : "password"}
                onChange={handlePasswordChange("password")}
                onBlur={validateInput}
                value={values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {error.password && (
                <span className="err">{error.password}</span>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField htmlFor="standard-adornment-password"
                variant="outlined"
                className={classes.text}
                required
                fullWidth
                name="confirmPassword"
                label="Confirmar Contraseña"
                id="confirmPassword"
                type={values.showPassword ? "text" : "password"}
                onChange={handlePasswordChange("confirmPassword")}
                onBlur={validateInput}
                value={values.confirmPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {error.confirmPassword && (
                <span className="err">{error.confirmPassword}</span>
              )}
            </Grid>
          </Grid>
          <Button
            spacing={2}
            type="submit"
            fullWidth
            variant="contained"
            className={`${classes.submit} btn-primary`}
          >
            Crear Cuenta
          </Button>
        </form>
            <Divider />
            <GoogleLogin
                clientId={clientId}
                buttonText="Registrarme con Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
                render={(renderProps) => (
                  <Button 
                  fullWidth
                  variant="contained"
                  spacing={2}
                  startIcon={<GoogleIcon />}
                  onClick={renderProps.onClick} 
                  disabled={renderProps.disabled} 
                  className={`${classes.google}`}>Registrarme con Google</Button>
                )}
            />
            {/*{!loginFace &&
              <FacebookLogin
                appId="6005500676161926"
                autoLoad={true}
                fields="name,email,picture"
                scope="public_profile,user_friends"
                callback={responseFacebook}
                icon="fa-facebook" />
            }
            {loginFace &&
              <Box component="img" src={pictureFace} roundedCircle />
            }*/}
      </div>
    }
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
    </MuiThemeProvider>
  );
};
export default SignUp;