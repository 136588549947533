import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const deleteComment = async (id: number) => {
    await fetchUrl({
        url: "api/comment",
        method: "DELETE",
        params: { id },
        headers: { token: window.localStorage.getItem("token"),

     },
    });
};

const fetchUrl = async (config: AxiosRequestConfig) => {
    try {
        const response: AxiosResponse = await axios(config);
        return response;
    } catch (error) {
        console.log(error);
    }
};
