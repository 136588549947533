import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const Messages = ({ show, message, severity, onClose }) => {

  return (
    <div>
      {show !== false &&
        <Snackbar open={show} autoHideDuration={5000} onClose={onClose}>
          <Alert severity={severity}>
            <AlertTitle>{severity}</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      }
    </div>
  )
}

export default Messages;
