import React, { useState, useContext } from "react";
import { IconButton, Menu, MenuItem, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { FixMeLater } from "../../models/Temp";
import { UserEntity } from "../../models/Entities";
import ReportDialog from "../ReportDialog";
import { DeleteIcon, ReportIcon } from "../../SVG/Icons";
import styles from "./memeCommentInfo.module.css";
import { ThemeContext } from "../../store/theme-context.js";

const MemeCommentInfo: React.FC<{
  user: UserEntity;
  comment: FixMeLater;
  handleMessages: FixMeLater;
  deleteComment: (id: number) => void;
}> = ({ user, comment, deleteComment, handleMessages }) => {
  const options =
    comment && user?.email === comment.owner.email
      ? ["Eliminar"]
      : ["Reportar"];
  const { theme } = useContext(ThemeContext);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const [anchorEl, setAnchorEl] = React.useState<FixMeLater>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectedOption: FixMeLater) => {
    executeOption(selectedOption);
    setAnchorEl(null);
  };

  const executeOption = (option: FixMeLater) => {
    if (option === "Eliminar") {
      if (window.confirm("Esta seguro que desea eliminar este comentario?"))
        deleteComment(comment.id);
    }
    if (option === "Reportar") {
      handleOpenDialog();
    }
  };

  const icons = [
    <DeleteIcon color={"#ff3651"} width={25} height={25} />,
    <ReportIcon color={"#ff3651"} width={25} height={25} />,
  ];

  return (
    <div style={{ transform: "rotate(90deg)" }}>
      <IconButton
        aria-label="Options"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon htmlColor="#808080" />
      </IconButton>
      <Menu
        className={theme === "dark" ? styles.menuDark : styles.menu}
        anchorEl={anchorEl} // needed for menu to appear
        open={open}
        onClose={handleClose}
        PaperProps={{ className: styles.paper }}
      >
        {options.map((option, index) => (
          <MenuItem
            divider={true}
            key={option}
            onClick={() => handleClose(option)}
          >
            <Button
              startIcon={
                user?.email !== comment.owner.email
                  ? icons[index + 1]
                  : icons[index]
              }
              className={theme === "dark" ? styles.menuBtnDark : styles.menuBtn}
            >
              {option}
            </Button>
          </MenuItem>
        ))}
      </Menu>
      <ReportDialog
        onClose={() => setOpenDialog(false)}
        openDialog={openDialog}
        user={user}
        report={comment}
        handleMessages={handleMessages}
      />
    </div>
  );
};

export default MemeCommentInfo;
