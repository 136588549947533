export const createReport = (info) => {
  const url = "api/report";
  const method = "POST";
  return fetchUrl({ url, method, data: info });
};

const fetchUrl = ({ url, method, data }) => {
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: method ? method : "GET",
    body: JSON.stringify(data),
    headers: {
      token: window.localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  })
    .then((response) => response)
    .catch((error) => console.log("error:", error));
};
