const LikeIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M186.730 22.801 C 51.913 32.186,-22.707 186.043,52.938 298.664 C 123.251 403.346,278.772 402.552,348.140 297.156 C 428.822 174.571,333.520 12.583,186.730 22.801 M230.806 45.474 C 341.159 69.083,392.576 194.024,330.285 287.204 C 287.239 351.594,202.146 374.782,132.126 341.201 C 59.414 306.329,24.578 220.572,52.624 145.490 C 79.635 73.181,157.091 29.704,230.806 45.474 M264.929 110.633 C 257.097 112.512,253.465 117.161,232.415 152.257 C 217.483 177.151,206.776 196.847,191.003 228.436 C 183.845 242.773,177.893 254.609,177.777 254.740 C 177.662 254.870,168.517 245.989,157.457 235.003 C 145.787 223.412,135.987 214.462,134.107 213.676 C 122.674 208.899,110.900 216.242,110.900 228.149 C 110.900 235.322,111.931 236.786,127.075 251.133 C 141.385 264.690,155.057 279.362,164.223 290.995 C 172.497 301.496,179.733 304.569,187.945 301.070 C 192.974 298.927,194.617 296.292,206.631 271.090 C 230.811 220.369,252.736 180.361,276.225 144.096 C 283.678 132.590,283.886 132.110,283.886 126.487 C 283.886 116.196,274.490 108.339,264.929 110.633 "
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default LikeIcon;
