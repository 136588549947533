import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./userOptions.module.css";
import { Button, IconButton } from "@material-ui/core";
import CircularPicure from "../CircularPicture";
import MediaQuery from "react-responsive";
import { HomeIcon, FollowIcon, SaveIcon } from "../../SVG/Icons";
import { Link } from "react-router-dom";
import { GoogleLogout } from 'react-google-login';

const UserOptions = ({
  user,
  handleLogout,
  setShowMyMemes,
  showMyMemes,
  handleMessages,
}) => {
  let history = useHistory();
  const [selectedButton, setSelectedButton] = React.useState("Normal");
  const handleClickLogout = () => {
    localStorage.clear("token");
    handleLogout();
  };

  const handleSave = () => {
    if (!localStorage.getItem("token")) {
      handleMessages(
        "error",
        <p>
          Debe de iniciar sesión para poder poder ver elementos guardados.
          <a href="/login">Iniciar sesión</a>
        </p>
      );
    } else {
      history.push("/saves");
    }
  };

  //id de google api
  const clientId = '125893593885-a4ip9i955j64aku2uf1grt53p0crmgb8.apps.googleusercontent.com';

  return (
    <>
      {/*No user logged*/}
      {!user ? (
        <>
          <MediaQuery minWidth={700}>
            <div className={styles.buttons_container}>
              <div className={styles.buttons_nav}>
                <IconButton className={styles.iconButton}>
                  <HomeIcon color={"#ff3651"} width={25} height={25} />
                </IconButton>

                <Button
                  className={
                    selectedButton === "Normal"
                      ? styles.follow
                      : styles.followSelected
                  }
                  onClick={() => {
                    setSelectedButton(
                      selectedButton === "Selected" ? "Normal" : "Selected"
                    );
                  }}
                  startIcon={
                    <FollowIcon color={"#ff3651"} width={25} height={25} />
                  }
                >
                  Seguidos
                </Button>

                <IconButton className={styles.iconButton} onClick={handleSave}>
                  <SaveIcon color={"#ff3651"} width={25} height={25} />
                </IconButton>
              </div>
              <div className={styles.buttons_auth}>
                <Button className={styles.login} href="/login">
                  Iniciar Sesión
                </Button>

                <Button className={styles.signin} href="/signup">
                  Registrarme
                </Button>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={699}>
            <Button
              href="/login"
              startIcon={
                <FollowIcon color={"#ff3651"} width={25} height={25} />
              }
            ></Button>
          </MediaQuery>
        </>
      ) : (
        <>
          {/*Mobile*/}
          <MediaQuery maxWidth={699}>
            {!showMyMemes ? (
              <IconButton
                onClick={() => {
                  !showMyMemes && setShowMyMemes();
                }}
              >
                <CircularPicure
                  className={styles.picture}
                  path={user.picture}
                />
              </IconButton>
            ) : (
              <Link
                to="/profile"
                className={styles.usernameMobile}
                onClick={() => {
                  setShowMyMemes(false);
                }}
              >
                <CircularPicure path={user.picture} />
                <span className={styles.text}>Bienvenido</span>
              </Link>
            )}
          </MediaQuery>
          {/*User logged*/}
          <MediaQuery minWidth={700}>
            <div className={styles.buttons_container}>
              <div className={styles.buttons_nav}>
                <IconButton className={styles.iconButton}>
                  <HomeIcon color={"#ff3651"} width={25} height={25} />
                </IconButton>

                <Button
                  className={
                    selectedButton === "Normal"
                      ? styles.follow
                      : styles.followSelected
                  }
                  onClick={() => {
                    setSelectedButton(
                      selectedButton === "Selected" ? "Normal" : "Selected"
                    );
                  }}
                  startIcon={
                    <FollowIcon color={"#ff3651"} width={25} height={25} />
                  }
                >
                  Seguidos
                </Button>

                <IconButton className={styles.iconButton} onClick={handleSave}>
                  <SaveIcon color={"#ff3651"} width={25} height={25} />
                </IconButton>
              </div>
              <div className={styles.buttons_auth}>
                <Link to="/profile" className={styles.username}>
                  <CircularPicure path={user.picture} />
                  <span className={styles.text}>Bienvenido</span>
                </Link>

                <GoogleLogout
                clientId={clientId}
                onLogoutSuccess={handleClickLogout}
                type="submit"
                render={(renderProps) => (
                  <Button 
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={styles.btnLogoff}>Cerrar Sesión</Button>
                )}
              />
              </div>
            </div>
          </MediaQuery>
        </>
      )}
    </>
  );
};

export default UserOptions;
