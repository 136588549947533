const FunnyIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M49.99,99.59C22.85,99.82,0.08,77.1,0.69,49.12C1.28,22.51,23.55,0.3,51.23,0.94
	c26.47,0.6,48.21,22.47,48.14,49.24C99.29,77.67,77.45,99.59,49.99,99.59z M82.22,50.33c0.01-17.85-14.36-32.36-32.19-32.32
	c-18.02,0.05-32.27,14.36-32.3,32.22c-0.03,17.82,14.14,32.2,32.2,32.32C67.73,82.66,82.21,68.14,82.22,50.33z"
      fill={color}
    />
    <path
      d="M82.22,50.33c-0.01,17.81-14.49,32.33-32.28,32.22c-18.06-0.11-32.23-14.5-32.2-32.32
	c0.03-17.86,14.28-32.17,32.3-32.22C67.85,17.97,82.23,32.48,82.22,50.33z M49.96,78.33c15.39,0,27.94-12.49,28.11-27.67
	c0.17-15.48-12.49-28.46-27.95-28.44c-15.6,0.02-28.24,12.54-28.24,28.03C21.88,65.72,34.49,78.33,49.96,78.33z"
      fill={color2}
    />
    <path
      d="M49.96,78.33c-15.48,0-28.08-12.6-28.08-28.07c0-15.5,12.64-28.01,28.24-28.03
	c15.46-0.02,28.12,12.95,27.95,28.44C77.9,65.83,65.35,78.33,49.96,78.33z M68.46,53c0-0.64,0.02-1.28-0.01-1.91
	c-0.02-0.36-0.1-0.71-0.17-1.06c-0.31-1.56-1.02-2.01-2.57-1.53c-0.87,0.27-1.73,0.61-2.57,0.96c-6.44,2.65-13.09,3.46-19.89,1.94
	c-2.97-0.67-5.82-1.87-8.73-2.82c-1.84-0.6-2.67-0.13-2.9,1.77c-0.51,4.14,0.12,8.1,2.1,11.8C37.7,69.57,45.53,72.32,53.49,71
	C62.74,69.47,68.51,62.42,68.46,53z M39.93,39.56c-1.91,0.5-3.55,0.91-5.19,1.35c-1.39,0.38-2.06,1.43-1.71,2.66
	c0.32,1.14,1.4,1.67,2.78,1.32c2.89-0.73,5.78-1.49,8.67-2.21c0.93-0.23,1.6-0.67,1.82-1.65c0.22-0.99-0.23-1.67-0.95-2.28
	c-2.21-1.89-4.39-3.81-6.6-5.7c-1.18-1.02-2.44-1.04-3.26-0.09c-0.86,0.99-0.68,2.12,0.55,3.21C37.25,37.25,38.49,38.31,39.93,39.56
	z M60.04,39.58c1.45-1.29,2.71-2.43,3.99-3.55c0.72-0.63,1.09-1.38,0.81-2.33c-0.43-1.5-2.17-1.95-3.47-0.84
	c-2.24,1.92-4.42,3.91-6.65,5.85c-0.73,0.64-1.29,1.33-1.07,2.36c0.23,1.09,1.07,1.5,2.05,1.74c2.86,0.68,5.72,1.39,8.59,2.08
	c1.34,0.32,2.41-0.26,2.71-1.45c0.3-1.16-0.37-2.18-1.73-2.54C63.63,40.46,61.97,40.06,60.04,39.58z"
      fill={color}
    />
    <path
      d="M68.46,53c0.06,9.42-5.72,16.47-14.97,17.99c-7.96,1.31-15.79-1.43-19.77-8.85c-1.98-3.7-2.61-7.66-2.1-11.8
	c0.23-1.9,1.06-2.37,2.9-1.77c2.91,0.95,5.76,2.15,8.73,2.82c6.8,1.52,13.46,0.72,19.89-1.94c0.85-0.35,1.7-0.69,2.57-0.96
	c1.54-0.48,2.26-0.03,2.57,1.53c0.07,0.35,0.15,0.71,0.17,1.06C68.48,51.73,68.46,52.36,68.46,53z M35.71,53.54
	c0,0.48-0.02,0.79,0,1.1c0.35,5.88,4.84,11.15,10.66,12.06c2.4,0.38,4.95,0.38,7.35-0.01c6.14-1,10.9-7.08,10.55-13.18
	C54.8,57.24,45.3,57.21,35.71,53.54z"
      fill={color2}
    />
    <path
      d="M39.93,39.56c-1.44-1.25-2.67-2.31-3.89-3.39c-1.23-1.09-1.41-2.22-0.55-3.21c0.83-0.95,2.08-0.93,3.26,0.09
	c2.2,1.9,4.39,3.81,6.6,5.7c0.71,0.61,1.16,1.3,0.95,2.28c-0.22,0.99-0.89,1.42-1.82,1.65c-2.89,0.72-5.78,1.48-8.67,2.21
	c-1.38,0.35-2.46-0.18-2.78-1.32c-0.35-1.23,0.32-2.28,1.71-2.66C36.38,40.47,38.02,40.06,39.93,39.56z"
      fill={color2}
    />
    <path
      d="M60.04,39.58c1.93,0.48,3.59,0.88,5.25,1.32c1.35,0.36,2.03,1.38,1.73,2.54c-0.3,1.18-1.38,1.77-2.71,1.45
	c-2.86-0.69-5.72-1.39-8.59-2.08c-0.99-0.24-1.82-0.65-2.05-1.74c-0.22-1.03,0.34-1.73,1.07-2.36c2.22-1.94,4.41-3.92,6.65-5.85
	c1.3-1.11,3.04-0.67,3.47,0.84c0.27,0.95-0.09,1.7-0.81,2.33C62.75,37.15,61.49,38.29,60.04,39.58z"
      fill={color2}
    />
    <path
      d="M35.71,53.54c9.6,3.67,19.09,3.69,28.56-0.03c0.35,6.1-4.41,12.18-10.55,13.18c-2.39,0.39-4.95,0.39-7.35,0.01
	c-5.82-0.91-10.31-6.18-10.66-12.06C35.69,54.33,35.71,54.02,35.71,53.54z"
      fill={color}
    />
  </svg>
);

export default FunnyIcon;
