const TVAndMoviesIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M99.39,50.25c0.01,27.32-21.91,48.59-47.79,49.37c-27.84,0.83-50.13-21.29-50.9-47.81
	C-0.1,23.93,22.08,1.71,48.55,0.94C76.22,0.13,99.41,22.34,99.39,50.25z M29.93,32.51c-0.06,0.05-0.12,0.12-0.2,0.13
	c-5.01,1.1-6.16,6.47-3.77,9.98c0.24,0.35,0.4,0.78,0.47,1.2c0.77,4.47,1.52,8.95,2.26,13.43c1.23,7.34,2.44,14.69,3.68,22.03
	c0.29,1.71,0.89,2.22,2.55,2.22c10.13,0.01,20.26,0.01,30.39,0c1.52,0,2.2-0.58,2.44-2.06c1.78-10.64,3.54-21.28,5.33-31.92
	c0.35-2.07,0.74-4.08,1.67-6.04c1.72-3.64,0.89-7.5-1.81-9.98c-1.9-1.75-4.19-2.36-6.83-2.19c0.15-3.45-0.97-6.24-4.09-7.91
	c-3.06-1.64-6.06-1.25-8.94,0.6c-1.91-2.87-4.55-4.1-7.85-3.48c-3.33,0.63-5.16,2.89-5.88,6.11C34.18,23.09,29.37,26.55,29.93,32.51
	z"
      fill={color}
    />
    <path
      d="M29.93,32.51c-0.56-5.96,4.25-9.42,9.42-7.89c0.72-3.21,2.56-5.48,5.88-6.11c3.3-0.62,5.94,0.61,7.85,3.48
	c2.88-1.86,5.88-2.24,8.94-0.6c3.12,1.67,4.25,4.47,4.09,7.91c2.64-0.17,4.93,0.44,6.83,2.19c2.7,2.48,3.53,6.34,1.81,9.98
	c-0.93,1.96-1.32,3.98-1.67,6.04c-1.78,10.64-3.55,21.28-5.33,31.92c-0.25,1.48-0.92,2.06-2.44,2.06c-10.13,0.01-20.26,0.01-30.39,0
	c-1.65,0-2.26-0.51-2.55-2.22c-1.24-7.34-2.45-14.68-3.68-22.03c-0.75-4.48-1.49-8.95-2.26-13.43c-0.07-0.42-0.23-0.85-0.47-1.2
	c-2.4-3.51-1.25-8.88,3.77-9.98C29.8,32.63,29.86,32.56,29.93,32.51z M50.18,41.14c6.34,0,12.69,0.01,19.03-0.01
	c0.43,0,1.09-0.07,1.27-0.35c0.97-1.5,1.39-3.14,0.62-4.87c-0.72-1.62-2.08-2.52-3.78-2.45c-1.09,0.04-2.21,0.56-3.23,1.05
	c-0.96,0.46-1.83,0.61-2.66-0.1c-0.84-0.72-0.84-1.63-0.45-2.62c0.36-0.92,0.74-1.86,0.92-2.83c0.39-2.09-0.78-3.81-2.84-4.3
	c-2.16-0.51-3.69,0.52-4.94,2.14c-0.59,0.76-1.32,1.17-2.28,0.94c-0.9-0.22-1.31-0.9-1.6-1.76c-0.29-0.85-0.69-1.7-1.22-2.42
	c-0.8-1.09-2.03-1.26-3.25-0.93c-1.2,0.32-2.09,1.16-2.17,2.43c-0.07,1.08,0.07,2.21,0.33,3.27c0.27,1.11,0.13,1.98-0.83,2.62
	c-0.85,0.57-1.9,0.28-2.8-0.53c-0.73-0.66-1.52-1.3-2.38-1.77c-1-0.54-2.04-0.33-2.89,0.41c-0.82,0.7-1.15,1.64-0.89,2.69
	c0.2,0.81,0.5,1.6,0.83,2.36c0.41,0.96,0.5,1.87-0.29,2.65c-0.78,0.78-1.68,0.77-2.64,0.27c-0.88-0.46-1.77-0.5-2.55,0.23
	c-0.75,0.7-0.71,1.61-0.4,2.44c0.35,0.95,0.99,1.48,2.17,1.47C37.57,41.11,43.88,41.14,50.18,41.14z M47.17,77.37
	c1.7,0,3.25-0.04,4.8,0.02c0.85,0.03,1.07-0.34,1.14-1.11c0.85-9.05,1.72-18.09,2.59-27.14c0.12-1.25,0.2-2.5,0.3-3.79
	c-4.05,0-7.95,0-11.9,0C45.13,56.01,46.14,66.6,47.17,77.37z M69.26,45.38c-3.13,0-6.09,0-9.06,0c-1.02,10.68-2.03,21.25-3.05,31.9
	c2.37,0,4.54,0,6.76,0C65.7,66.61,67.47,56.02,69.26,45.38z M30.88,45.31c1.8,10.73,3.58,21.32,5.36,31.95c2.28,0,4.48,0,6.76,0
	c-1.03-10.72-2.05-21.33-3.07-31.95C36.87,45.31,33.97,45.31,30.88,45.31z"
      fill={color2}
    />
    <path
      d="M50.18,41.14c-6.3,0-12.61-0.03-18.91,0.03c-1.18,0.01-1.82-0.52-2.17-1.47c-0.31-0.84-0.36-1.74,0.4-2.44
	c0.78-0.73,1.67-0.69,2.55-0.23c0.95,0.5,1.85,0.51,2.64-0.27c0.79-0.79,0.7-1.69,0.29-2.65c-0.33-0.76-0.63-1.55-0.83-2.36
	c-0.26-1.05,0.07-1.99,0.89-2.69c0.86-0.74,1.9-0.95,2.89-0.41c0.86,0.47,1.65,1.11,2.38,1.77c0.9,0.82,1.94,1.1,2.8,0.53
	c0.96-0.64,1.1-1.51,0.83-2.62c-0.26-1.06-0.39-2.19-0.33-3.27c0.08-1.27,0.97-2.11,2.17-2.43c1.22-0.32,2.45-0.16,3.25,0.93
	c0.53,0.72,0.93,1.57,1.22,2.42c0.3,0.86,0.7,1.54,1.6,1.76c0.96,0.24,1.7-0.18,2.28-0.94c1.25-1.62,2.77-2.65,4.94-2.14
	c2.06,0.49,3.24,2.22,2.84,4.3c-0.18,0.97-0.56,1.9-0.92,2.83c-0.39,0.99-0.39,1.89,0.45,2.62c0.84,0.72,1.7,0.56,2.66,0.1
	c1.02-0.49,2.14-1.01,3.23-1.05c1.7-0.06,3.06,0.83,3.78,2.45c0.77,1.73,0.35,3.37-0.62,4.87c-0.18,0.28-0.83,0.35-1.27,0.35
	C62.87,41.15,56.53,41.14,50.18,41.14z"
      fill={color}
    />
    <path
      d="M47.17,77.37c-1.03-10.77-2.05-21.36-3.07-32.02c3.96,0,7.85,0,11.9,0c-0.1,1.29-0.18,2.54-0.3,3.79
	c-0.86,9.05-1.74,18.09-2.59,27.14c-0.07,0.77-0.29,1.14-1.14,1.11C50.43,77.33,48.88,77.37,47.17,77.37z"
      fill={color}
    />
    <path
      d="M69.26,45.38c-1.78,10.64-3.56,21.23-5.35,31.9c-2.22,0-4.39,0-6.76,0c1.02-10.65,2.03-21.22,3.05-31.9
	C63.17,45.38,66.13,45.38,69.26,45.38z"
      fill={color}
    />
    <path
      d="M30.88,45.31c3.08,0,5.98,0,9.06,0c1.02,10.62,2.04,21.23,3.07,31.95c-2.28,0-4.48,0-6.76,0
	C34.46,66.63,32.69,56.04,30.88,45.31z"
      fill={color}
    />
  </svg>
);

export default TVAndMoviesIcon;
