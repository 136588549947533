const AnimalsIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M49.91,0.97C76.4,0.7,99.65,22.53,99.26,51.02c-0.38,27.63-23.4,49.27-50.6,48.61
	C23.29,99.01,0.59,78.47,0.57,50.31C0.54,21.77,23.93,0.7,49.91,0.97z M79.05,52.47c0-0.76,0-1.31,0-1.86
	c0.02-5.89-0.61-11.71-2.22-17.37c-0.74-2.62-1.79-5.2-3.09-7.59c-1.41-2.59-3.6-3.05-6.22-1.71c-0.95,0.49-1.89,1.06-2.68,1.77
	c-2.35,2.1-4.63,4.27-6.91,6.44c-0.51,0.49-0.96,0.63-1.69,0.46c-4.22-0.95-8.45-0.89-12.69-0.03c-0.43,0.09-1.08-0.12-1.41-0.42
	c-1.32-1.22-2.46-2.64-3.83-3.78c-1.92-1.6-3.86-3.23-6.02-4.46c-2.51-1.43-4.59-0.72-6.15,1.71c-0.7,1.1-1.3,2.29-1.77,3.51
	c-2.06,5.37-2.93,10.99-3.32,16.69c-0.15,2.17-0.02,4.35-0.02,6.66c-1.43-0.16-2.49-0.27-3.55-0.39c-1.4-0.16-2.34,0.49-2.52,1.73
	c-0.17,1.15,0.55,2.08,1.87,2.31c1.14,0.2,2.3,0.24,3.43,0.47c0.41,0.08,1.02,0.45,1.09,0.78c0.69,3.34,1.85,6.49,3.64,9.57
	c-1.76,0.56-3.43,1.07-5.08,1.61c-1.5,0.49-2.12,1.52-1.71,2.78c0.37,1.15,1.52,1.61,2.97,1.17c1.83-0.56,3.66-1.11,5.46-1.73
	c0.71-0.25,1.12-0.06,1.67,0.43c1.62,1.46,3.23,2.99,5.05,4.18c5.49,3.59,11.61,4.89,18.11,4.61c7.85-0.34,14.68-3.06,20.16-8.81
	c0.53-0.55,0.96-0.65,1.66-0.41c1.96,0.66,3.95,1.24,5.93,1.84c0.91,0.27,1.67-0.01,2.22-0.76c0.55-0.75,0.63-1.63,0.03-2.32
	c-0.41-0.48-1.06-0.83-1.67-1.04c-1.59-0.57-3.21-1.03-4.9-1.56c1.83-3.07,2.94-6.2,3.65-9.49c0.07-0.34,0.63-0.75,1.02-0.83
	c1.21-0.25,2.45-0.3,3.67-0.52c1.22-0.22,1.93-1.23,1.76-2.3c-0.19-1.17-1.16-1.86-2.45-1.72C81.42,52.2,80.32,52.33,79.05,52.47z"
      fill={color}
    />
    <path
      d="M79.05,52.47c1.27-0.14,2.37-0.27,3.48-0.39c1.29-0.14,2.26,0.55,2.45,1.72c0.18,1.08-0.54,2.08-1.76,2.3
	c-1.21,0.22-2.46,0.27-3.67,0.52c-0.39,0.08-0.95,0.48-1.02,0.83c-0.72,3.29-1.83,6.43-3.65,9.49c1.69,0.53,3.31,0.99,4.9,1.56
	c0.61,0.22,1.26,0.57,1.67,1.04c0.6,0.7,0.52,1.58-0.03,2.32c-0.55,0.74-1.31,1.03-2.22,0.76c-1.98-0.6-3.97-1.18-5.93-1.84
	c-0.7-0.23-1.13-0.14-1.66,0.41c-5.48,5.75-12.31,8.47-20.16,8.81c-6.5,0.28-12.62-1.02-18.11-4.61c-1.82-1.19-3.43-2.72-5.05-4.18
	c-0.55-0.5-0.95-0.68-1.67-0.43c-1.81,0.62-3.64,1.17-5.46,1.73c-1.45,0.44-2.6-0.02-2.97-1.17c-0.41-1.27,0.21-2.3,1.71-2.78
	c1.65-0.54,3.32-1.05,5.08-1.61c-1.79-3.07-2.95-6.23-3.64-9.57c-0.07-0.33-0.68-0.7-1.09-0.78c-1.13-0.23-2.29-0.28-3.43-0.47
	c-1.33-0.23-2.04-1.16-1.87-2.31c0.18-1.24,1.12-1.89,2.52-1.73c1.06,0.12,2.12,0.23,3.55,0.39c0-2.31-0.12-4.49,0.02-6.66
	c0.38-5.7,1.26-11.32,3.32-16.69c0.47-1.22,1.06-2.41,1.77-3.51c1.56-2.43,3.64-3.14,6.15-1.71c2.16,1.22,4.1,2.86,6.02,4.46
	c1.37,1.14,2.51,2.56,3.83,3.78c0.33,0.3,0.98,0.51,1.41,0.42c4.24-0.86,8.47-0.92,12.69,0.03c0.73,0.16,1.18,0.02,1.69-0.46
	c2.28-2.17,4.57-4.34,6.91-6.44c0.79-0.71,1.73-1.29,2.68-1.77c2.62-1.34,4.82-0.88,6.22,1.71c1.3,2.39,2.35,4.97,3.09,7.59
	c1.61,5.66,2.24,11.48,2.22,17.37C79.05,51.16,79.05,51.71,79.05,52.47z M32.17,69.07c8.99,9.34,27.56,8.79,35.54-0.01
	c-2.15-0.68-4.27-1.33-6.38-2.01c-1.35-0.44-1.98-1.48-1.62-2.62c0.38-1.22,1.47-1.73,2.88-1.3c2.36,0.72,4.73,1.43,7.06,2.22
	c0.84,0.28,1.36,0.19,1.7-0.64c0.9-2.2,1.81-4.4,2.7-6.61c0.09-0.22,0.04-0.5,0.06-0.79c-3.19,0.39-6.27,0.8-9.36,1.14
	c-1.6,0.18-2.58-0.54-2.7-1.82c-0.11-1.25,0.78-2.1,2.37-2.3c3.12-0.39,6.24-0.84,9.37-1.16c0.92-0.1,1.13-0.47,1.1-1.28
	c-0.1-2.23-0.07-4.47-0.26-6.69c-0.42-5.1-1.22-10.13-3.12-14.91c-1.32-3.33-1.66-3.45-4.32-1.11c-2.78,2.45-5.46,5.02-8.16,7.56
	c-0.52,0.49-0.97,0.66-1.69,0.44c-4.99-1.48-9.99-1.43-14.97,0.03c-0.68,0.2-1.11,0.08-1.58-0.41c-1.49-1.56-2.96-3.13-4.54-4.59
	c-1.61-1.48-3.33-2.85-5.02-4.23c-0.9-0.74-1.6-0.5-2.03,0.56c-0.56,1.36-1.21,2.69-1.64,4.09c-1.87,6.11-2.52,12.4-2.57,18.76
	c0,0.54,0.12,1.08,0.18,1.67c3.53,0.44,6.97,0.86,10.4,1.29c1.48,0.18,2.34,1.06,2.25,2.28c-0.1,1.26-1.05,1.96-2.57,1.83
	c-1.11-0.09-2.22-0.26-3.32-0.39c-2.07-0.25-4.14-0.51-6.3-0.78c0.59,2.8,1.59,5.25,2.97,7.56c0.37,0.62,0.71,0.79,1.44,0.54
	c2.38-0.8,4.78-1.52,7.18-2.25c1.44-0.44,2.56,0.02,2.94,1.19c0.4,1.2-0.28,2.28-1.75,2.75C36.38,67.75,34.33,68.39,32.17,69.07z"
      fill={color2}
    />
    <path
      d="M32.17,69.07c2.16-0.68,4.21-1.32,6.25-1.98c1.47-0.47,2.15-1.55,1.75-2.75c-0.39-1.17-1.51-1.63-2.94-1.19
	c-2.4,0.74-4.8,1.45-7.18,2.25c-0.72,0.24-1.07,0.07-1.44-0.54c-1.38-2.31-2.38-4.76-2.97-7.56c2.15,0.27,4.23,0.52,6.3,0.78
	c1.11,0.14,2.21,0.3,3.32,0.39c1.52,0.13,2.47-0.57,2.57-1.83c0.09-1.22-0.77-2.1-2.25-2.28c-3.44-0.43-6.87-0.85-10.4-1.29
	c-0.07-0.58-0.19-1.12-0.18-1.67c0.05-6.36,0.7-12.65,2.57-18.76c0.43-1.4,1.08-2.73,1.64-4.09c0.43-1.05,1.13-1.3,2.03-0.56
	c1.69,1.39,3.41,2.75,5.02,4.23c1.58,1.46,3.05,3.04,4.54,4.59c0.47,0.49,0.9,0.61,1.58,0.41c4.98-1.47,9.98-1.51,14.97-0.03
	c0.72,0.21,1.17,0.04,1.69-0.44c2.7-2.54,5.38-5.11,8.16-7.56c2.66-2.34,3-2.22,4.32,1.11c1.9,4.79,2.7,9.82,3.12,14.91
	c0.18,2.22,0.16,4.46,0.26,6.69c0.04,0.81-0.17,1.18-1.1,1.28c-3.13,0.32-6.25,0.77-9.37,1.16c-1.59,0.2-2.48,1.05-2.37,2.3
	c0.11,1.28,1.09,2,2.7,1.82c3.09-0.34,6.16-0.75,9.36-1.14c-0.02,0.29,0.03,0.57-0.06,0.79c-0.89,2.21-1.8,4.41-2.7,6.61
	c-0.34,0.84-0.87,0.93-1.7,0.64c-2.34-0.79-4.7-1.5-7.06-2.22c-1.41-0.43-2.5,0.08-2.88,1.3c-0.35,1.14,0.27,2.19,1.62,2.62
	c2.11,0.68,4.22,1.33,6.38,2.01C59.73,77.86,41.16,78.41,32.17,69.07z M49.15,63.53c3.11,0.45,5.41-0.64,6.78-3.49
	c0.22-0.47,0.49-0.91,0.72-1.38c1.24-2.49,0.59-4.36-2.13-5.03c-2.05-0.51-4.23-0.6-6.36-0.57c-1.26,0.02-2.59,0.45-3.76,0.98
	c-1.74,0.79-2.19,2.22-1.44,3.98c0.37,0.88,0.86,1.7,1.33,2.53C45.37,62.45,46.86,63.7,49.15,63.53z"
      fill={color}
    />
    <path
      d="M49.15,63.53c-2.3,0.17-3.78-1.08-4.86-2.97c-0.47-0.83-0.96-1.66-1.33-2.53c-0.74-1.76-0.29-3.19,1.44-3.98
	c1.17-0.53,2.5-0.97,3.76-0.98c2.12-0.03,4.31,0.06,6.36,0.57c2.72,0.67,3.37,2.54,2.13,5.03c-0.23,0.46-0.49,0.91-0.72,1.38
	C54.56,62.9,52.27,63.98,49.15,63.53z"
      fill={color2}
    />
  </svg>
);

export default AnimalsIcon;
