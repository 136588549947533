const FilledLikeIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M95.39,50.66c0.01,24.82-20.3,45.25-45.67,44.82C25.41,95.06,5.66,75.29,5.65,50.61C5.65,25.2,26.61,5.13,51.63,5.74
	C75.64,6.32,95.44,25.97,95.39,50.66z M44.64,64.39c-0.29-0.3-0.63-0.68-0.99-1.03c-2.8-2.78-5.57-5.59-8.43-8.32
	c-2.04-1.95-4.69-1.88-6.36,0.01c-1.65,1.86-1.37,4.38,0.65,6.25c4.63,4.28,9.1,8.71,12.93,13.76c0.85,1.12,2.02,1.65,3.44,1.61
	c2.12-0.05,3.27-1.33,4.11-3.12C56.2,60.21,62.83,47.1,71.03,34.84c1.46-2.19,1.06-4.75-0.94-6.13c-2.04-1.41-4.64-0.86-6.1,1.44
	c-2.47,3.86-5.02,7.68-7.24,11.69C52.62,49.24,48.71,56.77,44.64,64.39z"
      fill={color}
    />
    <path
      d="M44.64,64.39c4.07-7.62,7.98-15.15,12.1-22.56c2.22-4,4.77-7.83,7.24-11.69c1.47-2.29,4.06-2.84,6.1-1.44
	c2,1.38,2.4,3.94,0.94,6.13C62.83,47.1,56.2,60.21,49.99,73.55c-0.84,1.79-1.99,3.07-4.11,3.12c-1.42,0.04-2.59-0.5-3.44-1.61
	c-3.82-5.04-8.3-9.47-12.93-13.76c-2.02-1.87-2.3-4.39-0.65-6.25c1.67-1.89,4.32-1.95,6.36-0.01c2.86,2.72,5.62,5.54,8.43,8.32
	C44.01,63.71,44.36,64.09,44.64,64.39z"
      fill={color2}
    />
  </svg>
);

export default FilledLikeIcon;
