import React from "react";
import styles from "./banner.module.css";
import { Button } from "@material-ui/core";
import MediaQuery from "react-responsive";

const Banner: React.FC<{}> = () => {
  return (
    <>
      <MediaQuery minWidth={700}>
        <div className={styles.signUpContainer}>
          <div className={styles.messageContainer}>
            <p className={styles.messageA}>Únete a nuestra comunidad</p>
            <p className={styles.messageB}>Vota, publica, comenta ¡y más!</p>
          </div>
          <div className={styles.signUpButtonCenter}>
            <Button className={styles.signupButton} href="/signup">
              Registrarme
            </Button>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={699}>
        <div className={styles.mobileContainer}>
        <div id='banner1'></div>
          <div className={styles.message}>
            <p className={styles.p1}>Únete a nuestra comunidad</p>
            <p className={styles.p2}>Vota, publica, comenta ¡y más!</p>
          </div>
        <div id='banner2'></div>
        </div>
      </MediaQuery>
    </>
  );
};

export default Banner;
