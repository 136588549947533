const ArrowExpandIcon: React.FC<{
  width: number;
  height: number;
  color: string;
}> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 400 400"}
  >
    <path
      d="M25.000 114.740 C 25.000 132.582,182.627 291.667,200.305 291.667 C 217.738 291.667,375.000 132.120,375.000 114.434 C 375.000 84.452,343.919 102.234,273.082 172.743 L 200.000 245.486 126.918 172.743 C 55.808 101.962,25.000 84.429,25.000 114.740 "
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);

export default ArrowExpandIcon;
