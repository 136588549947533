import React from "react";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        Copyright ©
        <Link color="inherit" href="https://rincondelvago.com">
        {' Rincón del Vago '}
          
        </Link>
        {new Date().getFullYear()}
        {'. Desarrollado por '}
        <Link color="inherit" href="https://www.tiimsrevolution.com">
          Tiims Revolution
        </Link>
      </Typography>
    );
}

export default Copyright;