const AwesomeIcon: React.FC<{
  width: number;
  height: number;
  color: string;
  color2: string;
}> = ({ width, height, color, color2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={"0 0 100 100"}
  >
    <path
      d="M50.05,0.96c26.7-0.23,49.86,21.92,49.3,50.39c-0.54,27.24-23.28,48.96-50.57,48.32
	C23.24,99.07,0.6,78.32,0.63,50.26C0.67,21.53,24.26,0.64,50.05,0.96z M57.04,68.54c0-3.05-0.02-5.88,0.03-8.71
	c0-0.29,0.31-0.73,0.58-0.83c2.45-0.94,4.93-1.8,7.39-2.72c1.83-0.68,3.68-1.32,5.45-2.14c7.62-3.52,11.53-10.18,10.99-18.59
	c-0.24-3.69-2.25-6.12-5.88-6.84c-1.74-0.35-3.53-0.43-5.38-0.64c0-0.74,0.01-1.49,0-2.24c-0.02-1.66-0.75-2.39-2.43-2.39
	c-11.85-0.01-23.7-0.01-35.56,0c-1.65,0-2.39,0.72-2.48,2.39c-0.04,0.78-0.01,1.56-0.01,2.31c-1.56,0.12-2.91,0.19-4.25,0.34
	c-4.11,0.47-6.56,2.72-6.87,6.83c-0.6,7.77,2.5,14.79,10.09,18.35c4.31,2.02,8.87,3.53,13.34,5.2c0.73,0.27,1.01,0.56,0.99,1.33
	c-0.05,1.95-0.02,3.91-0.02,5.87c0,0.79,0,1.57,0,2.48c-1.24,0-2.31-0.01-3.38,0c-4.41,0.02-7.81,3.25-7.88,7.48
	c-0.07,4.48,3.09,7.94,7.59,8c7.1,0.09,14.21,0.09,21.31,0c4.47-0.06,7.74-3.6,7.64-7.99c-0.09-4.22-3.51-7.45-7.91-7.49
	C59.32,68.53,58.25,68.54,57.04,68.54z"
      fill={color}
    />
    <path
      d="M57.04,68.54c1.21,0,2.28-0.01,3.35,0c4.39,0.03,7.81,3.27,7.91,7.49c0.1,4.39-3.17,7.93-7.64,7.99
	c-7.1,0.1-14.21,0.1-21.31,0c-4.49-0.06-7.66-3.52-7.59-8c0.07-4.23,3.46-7.45,7.88-7.48c1.07-0.01,2.14,0,3.38,0
	c0-0.91,0-1.7,0-2.48c0-1.96-0.03-3.91,0.02-5.87c0.02-0.77-0.26-1.06-0.99-1.33c-4.47-1.67-9.03-3.17-13.34-5.2
	c-7.59-3.56-10.69-10.58-10.09-18.35c0.32-4.11,2.77-6.36,6.87-6.83c1.34-0.15,2.69-0.22,4.25-0.34c0-0.75-0.03-1.53,0.01-2.31
	c0.09-1.67,0.83-2.39,2.48-2.39c11.85-0.01,23.7-0.01,35.56,0c1.69,0,2.41,0.73,2.43,2.39c0.01,0.75,0,1.5,0,2.24
	c1.86,0.21,3.65,0.29,5.38,0.64c3.63,0.73,5.64,3.15,5.88,6.84c0.54,8.41-3.36,15.07-10.99,18.59c-1.77,0.82-3.62,1.46-5.45,2.14
	c-2.46,0.92-4.94,1.78-7.39,2.72c-0.27,0.1-0.58,0.54-0.58,0.83C57.02,62.65,57.04,65.48,57.04,68.54z M33.98,27.59
	c0,2.79-0.05,5.46,0.01,8.12c0.09,3.84,0.45,7.66,1.75,11.3c1.45,4.06,4.04,7.14,8.39,8.09c3.84,0.85,7.81,0.86,11.64,0.02
	c4.84-1.06,7.53-4.57,8.83-9.15c1.63-5.71,1.59-11.58,1.48-17.45c-0.01-0.3-0.07-0.6-0.11-0.93C55.28,27.59,44.67,27.59,33.98,27.59
	z M49.95,79.79c3.51,0,7.02,0.02,10.52-0.01c2.04-0.01,3.55-1.42,3.66-3.34c0.11-1.82-1.23-3.37-3.2-3.68
	c-0.47-0.07-0.95-0.09-1.43-0.09c-5.82-0.01-11.64-0.01-17.46,0c-0.88,0-1.76-0.05-2.63,0.04c-2.24,0.23-3.61,1.72-3.51,3.75
	c0.09,2.03,1.59,3.33,3.88,3.34C43.17,79.8,46.56,79.79,49.95,79.79z M67.16,50.95c6.67-1.82,11.38-9.22,10.13-15.75
	c-0.21-1.1-0.75-2.03-1.87-2.26c-1.64-0.35-3.33-0.5-5.14-0.76C70.13,38.75,69.87,45,67.16,50.95z M29.67,32.2
	c-1.7,0.22-3.27,0.36-4.81,0.66c-1.27,0.25-1.98,1.16-2.1,2.45c-0.37,3.82,0.37,7.4,2.66,10.53c1.83,2.51,4.42,3.97,7.5,5.26
	C30.11,45,29.95,38.77,29.67,32.2z M47.16,59.75c0,2.76-0.01,5.45,0.02,8.14c0,0.21,0.33,0.6,0.51,0.6c1.7,0.05,3.39,0.03,5.13,0.03
	c0-3.02,0-5.89,0-8.77C50.92,59.75,49.1,59.75,47.16,59.75z"
      fill={color2}
    />
    <path
      d="M33.98,27.59c10.69,0,21.3,0,32,0c0.04,0.32,0.11,0.62,0.11,0.93c0.1,5.87,0.15,11.74-1.48,17.45
	c-1.31,4.58-3.99,8.09-8.83,9.15c-3.83,0.84-7.8,0.82-11.64-0.02c-4.35-0.96-6.95-4.04-8.39-8.09c-1.3-3.64-1.65-7.46-1.75-11.3
	C33.92,33.05,33.98,30.38,33.98,27.59z"
      fill={color}
    />
    <path
      d="M49.95,79.79c-3.39,0-6.78,0.01-10.16,0c-2.29-0.01-3.79-1.31-3.88-3.34c-0.09-2.03,1.28-3.52,3.51-3.75
	c0.87-0.09,1.75-0.04,2.63-0.04c5.82,0,11.64,0,17.46,0c0.48,0,0.96,0.02,1.43,0.09c1.97,0.31,3.31,1.86,3.2,3.68
	c-0.11,1.92-1.63,3.33-3.66,3.34C56.97,79.81,53.46,79.79,49.95,79.79z"
      fill={color}
    />
    <path
      d="M67.16,50.95c2.71-5.95,2.96-12.2,3.12-18.77c1.81,0.26,3.5,0.41,5.14,0.76c1.12,0.24,1.66,1.17,1.87,2.26
	C78.54,41.73,73.83,49.13,67.16,50.95z"
      fill={color}
    />
    <path
      d="M29.67,32.2c0.27,6.57,0.43,12.8,3.25,18.9c-3.08-1.28-5.67-2.75-7.5-5.26c-2.29-3.13-3.03-6.71-2.66-10.53
	c0.12-1.29,0.83-2.2,2.1-2.45C26.4,32.56,27.98,32.43,29.67,32.2z"
      fill={color}
    />
    <path
      d="M47.16,59.75c1.95,0,3.76,0,5.66,0c0,2.88,0,5.76,0,8.77c-1.74,0-3.43,0.02-5.13-0.03
	c-0.18-0.01-0.51-0.39-0.51-0.6C47.14,65.2,47.16,62.5,47.16,59.75z"
      fill={color}
    />
  </svg>
);

export default AwesomeIcon;
